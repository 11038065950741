import React from 'react';
import ReactLoading from "react-loading";
import quoteStatuses from "./quoteStatuses";

class QuoteList extends React.Component {
    
    render(){
        const { 
            quotes,
            onEdit,
            deleteQuote,
            loader
        } = this.props;
        return(
            <React.Fragment>
            <div className="col-md-12 table">
                <div className="row head_row">
                    <div className="col">Created On</div>
                    <div className="col">Number</div>
                    <div className="col">Status</div>
                    <div className="col col-sm-3">Customer / Company</div>
                    <div className="col col-md-1">Units</div>
                    <div className="col">Amount</div>
                    <div className="col">Action</div>
                </div>
                {loader && <ReactLoading className="m-auto" type="bubbles" color="#ca3c3c" />}
                {quotes.length > 0 ?
                    [quotes.map((quote)=>    
                        <div className="show_added_list" key={quote.id}>
                            
                            <div className="row" id={'row_data_'+quote.id} key={quote.id}>
                                <div className="col"><span>{new Date(quote.created_at).toLocaleDateString('default', { month: 'long' })+' '+new Date(quote.created_at).getDate()+', '+new Date(quote.created_at).getFullYear()}</span></div>
                                <div className="col"><span className="text-red">{quote.quote_number}</span></div>
                                <div className="col">
                                    <span>
                                        {quote.status === quoteStatuses.Pending ?	
											'Pending'
										:
										quote.status === quoteStatuses.Paid ?
											'Paid'
										:
										quote.status === quoteStatuses.Cancelled ?	
											'Cancelled'
										:
                                        quote.status === quoteStatuses.Shipback ?
											'Shipback'
										:
                                        quote.status === quoteStatuses.Decommissioned ?
											'Decommissioned'
										: ''}
                                    </span>
                                </div>
                                <div className="col col-sm-3"><h4>{quote.quoteEquipments.length > 0 && quote.quoteEquipments[0].equipment.customer ? quote.quoteEquipments[0].equipment.customer.first_name+' '+quote.quoteEquipments[0].equipment.customer.last_name : '-'}</h4>
                                            <span>{quote.quoteEquipments.length > 0 && quote.quoteEquipments[0].equipment.customer && quote.quoteEquipments[0].equipment.customer.company}</span>
                                </div>
                                <div className="col col-md-1"><span>{quote.quoteEquipments.length}</span></div>
                                
                                <div className="col"><span>$ {quote.total.toFixed(2)}</span></div>
                                
                                <div className="col">
                                    <button className="btn-main link-btn" onClick={() => onEdit(quote)}><i className="fas fa-eye"></i></button>
                                    <button className="btn-main link-btn text-red" onClick={() => window.confirm('Are you sure you want to delete #'+quote.quote_number+' quote ?') ? deleteQuote(quote) : ''}><i className="fas fa-trash"></i></button>
                                </div>
                            </div>
                            
                        </div>
                    )]
                :
                    <div className="show_added_list">
                        <div className="row"><div className="col col-sm-12">No Quote Found</div></div>
                    </div>    
                }
            </div>
            
            </React.Fragment>
        );
    }
}

export default QuoteList;