import React from 'react';

const PaymentFooter = () => {
    return (
        <div className="opti-footer">
                <p>©OptiManufacturing 2020 </p>
                <a href="https://optimanufacturing.com/terms-and-conditions/">See Terms and Conditions</a>
        </div>
    );
}

export default PaymentFooter;