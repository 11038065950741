import React from "react";
import {Switch, Route , Redirect, HashRouter as Router} from "react-router-dom";

// Import Header, Footer and other common components
import Header from "./components/layout/Header";
import Sidenav from "./components/layout/Sidenav";
import PaymentHeader from "./components/layout/PaymentHeader";
import PaymentFooter from "./components/layout/PaymentFooter";

// Import all pages here
import Home from "./pages/home/Home";
import Log from "./pages/home/Log";
import TensionmeterForm from "./pages/home/TensionmeterForm";
import Orders from "./pages/orders/Orders";
import OrderView from "./pages/orders/OrderView";
import Staff from "./pages/staff/Staff";
import Parts from "./pages/parts/Parts";
import Clients from "./pages/clients/Clients";
import Quotes from "./pages/quotes/Quotes";
import QuoteView from "./pages/quotes/QuoteView";
import Servicerequest from "./pages/service-request/Servicerequest";
import ServiceRequestForm from "./pages/service-request/ServiceRequestForm";
import Login from "./pages/login/Login";
import Passwordresetrequest from "./pages/password-reset-request/Passwordresetrequest";
import Resetpassword from "./pages/reset-password/Resetpassword";
import Invoice from "./pages/invoice/Invoice";
import InvoiceSingle from "./pages/invoice/InvoiceSingle";
import Faq from "./pages/faq/Faq";
import Calibration from "./pages/faq/Calibration";
import ServiceRequestFaq from "./pages/faq/ServiceRequestFaq";
import PartsManagement from "./pages/faq/PartsManagement";
import StaffManagement from "./pages/faq/StaffManagement";
import PayQuote from "./pages/paypal/PayQuote";
import PayShipping  from "./pages/paypal/PayShipping";
import SuccessReturn  from "./pages/paypal/SuccessReturn";
import ShippingCarriers from "./pages/shipping-carrier/ShippingCarriers";
import {getUser} from "./services/accountService";
// Import global styles
import "./scss/index.scss";
if(localStorage.getItem('token') === null){
  localStorage.setItem('token', '');
}
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    localStorage.getItem('token') !== ''
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
)

 

class App extends React.Component {

	constructor(props){
        super(props);
 
        this.state = {
			curUser:{},
			urlArray: window.location.hash.split('/')
        }
	}
	
	componentDidMount(){
		if(localStorage.getItem('user_id') !== ''){
			getUser({ params:{filter :{"where": {"id": localStorage.getItem('user_id') }} } }).then(user => {
				this.setState({
					curUser: user
				}); 
			});
		}
	}

	render(){
		const {curUser, urlArray} = this.state;
		var clsName = '';
		if(urlArray.includes('payquote')) {
			clsName = 'payment_container';
		}
		return (
		<Router basename="/" forceRefresh="true">
			<div>
			{localStorage.getItem('token') !== '' && !urlArray.includes('payquote') &&
			<Header></Header>}
			{urlArray.includes('payquote') &&
			<PaymentHeader></PaymentHeader>}
				<div className={clsName+' main_container'}>
				{localStorage.getItem('token') !== '' && !urlArray.includes('payquote') &&
				<Sidenav curUser={curUser}></Sidenav>}
				<Switch>
					<Route exact path="/login" component={Login} />
					<Route exact path="/payquote/:quote_id/:token" component={PayQuote} />
					<Route exact path="/payshipping" component={PayShipping} />
					<Route exact path="/successreturn" component={SuccessReturn} />
					<PrivateRoute exact path="/" component={Home} />
					<PrivateRoute path="/add-tensionmeter" component={TensionmeterForm} />
					<PrivateRoute exact path="/log/:id" component={Log} />
					{curUser.role === 'Admin' &&
					<PrivateRoute path="/staff" component={Staff} />}
					<PrivateRoute path="/parts" component={Parts} />
					<PrivateRoute path="/clients" component={Clients} />
					<PrivateRoute path="/quotes" component={Quotes} />
					<PrivateRoute path="/quote/:id/:token" component={QuoteView} />
					<PrivateRoute path="/orders" component={Orders} /> 
					<PrivateRoute path="/order/:id" component={OrderView} /> 
					<PrivateRoute path="/service-requests" component={Servicerequest} /> 
					<PrivateRoute path="/service-request/add" component={ServiceRequestForm} />
					<PrivateRoute path="/service-request/:id" component={ServiceRequestForm} />  
					<Route path="/password-reset-request" component={Passwordresetrequest} />  
					<Route path="/reset-password/:token" component={Resetpassword} />  
					<PrivateRoute path="/invoices" component={Invoice} />
					<PrivateRoute path="/invoice/:id" component={InvoiceSingle} />
					<PrivateRoute path="/shipping-carrier" component={ShippingCarriers} />
					
					<PrivateRoute path="/invoice-single" component={InvoiceSingle} />   
					<PrivateRoute path="/faq" component={Faq} /> 
					<PrivateRoute path="/calibration" component={Calibration} />
					<PrivateRoute path="/service-request-faq" component={ServiceRequestFaq} /> 
					<PrivateRoute path="/parts-management" component={PartsManagement} /> 
					<PrivateRoute path="/staff-management" component={StaffManagement} />  
				</Switch>
				</div> 
				{urlArray.includes('payquote') &&
				<PaymentFooter></PaymentFooter>}
			</div>
			   
		</Router>
		);
	
  	}
}

export default App;

