import React from 'react';
import { Card, Accordion } from 'react-bootstrap';
import { sendUpdateEmail } from '../../services/updateService';
import ReactLoading from "react-loading";

class MyCard extends React.Component {
    
    constructor(props){
        super(props);

        this.state = {
            sendNotification: false,
            sending: false,
            updateID: null,
            open: false,
            class: "card_close",
            contentClass: "d-none"
        }
        
        this.sendEmail = this.sendEmail.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(){
        
        if(this.state.open) {
            this.setState({
              open: false,
              class: "card_close",
              contentClass: "d-none"
            });
        }else{
            this.setState({
              open: true,
              class: "card_open",
              contentClass: ""
            });
        }
    }

    sendEmail(update,customer){
        this.setState({
            sending: true,
            updateID: update.id
        });
        const {serialNumber, itemName} = this.props;
        var data = {"update":update,"customer":customer, "serialNumber":serialNumber, "itemName":itemName};
        
        sendUpdateEmail(data)
        .then((response) => {
            this.setState({
                sending: false
            });
        })
        .catch((error) => {
            var err_str = [];
            
            if(typeof error.response.data.error.details !== 'undefined'){
                var errors = Object.values(error.response.data.error.details.messages);
                errors.map((err) => 
                    err_str.push(err[0])
                );
            }
            else{
                err_str.push(error.response.data.error.message);
            }
            
        });
    }

    render() {
        const {equipLog, handleEdit, customer, deleteLog} = this.props;
        const {sending, updateID} = this.state;
        var logDate = '';
        if(equipLog.shipped && equipLog.shipped.date){
            logDate = equipLog.shipped.date;
        }
        if(equipLog.unitRequested && equipLog.unitRequested.date){
            logDate = equipLog.unitRequested.date;
        }
        if(equipLog.received && equipLog.received.date){
            logDate = equipLog.received.date;
        }
        if(equipLog.servicing && equipLog.servicing.date){
            logDate = equipLog.servicing.date;
        }
        if(equipLog.decommission && equipLog.decommission.date){
            logDate = equipLog.decommission.date;
        }
        if(equipLog.sold && equipLog.sold.date){
            logDate = equipLog.sold.date;
        }
        return (
            <Card key={equipLog.id}>
                
                <Accordion.Toggle as={Card.Header} variant="link" className={this.state.class+" d-flex w-100"} eventKey={equipLog.id} onClick={this.handleClick}>
                    <p className="my-auto text-red">{new Date(logDate).toLocaleDateString('default', { month: 'long' })} {new Date(logDate).getDate()}, {new Date(logDate).getFullYear()}</p>                                                    
                    <h2 className="mb-0 ml-auto">
                        <button className="btn btn-link collapsed" type="button" >
                            {equipLog.updateType && equipLog.updateType.title} <img src="./img/plus-icon.svg" alt="" height="14" className="plus"/><img src="./img/chev-down-icon.svg" alt="" height="7" className="minus"/>
                        </button>
                    </h2>
                </Accordion.Toggle>                               
                <Card.Body className={this.state.contentClass}>
                    {equipLog.shipped ?
                    <div class="form-group">
                        <div class="row">
                            <div class="col-6">
                                <label>Details</label>
                                <textarea class="form-control" value={equipLog.shipped && equipLog.shipped.staff_note} readOnly></textarea>
                            </div>
                            <div class="col-5 pl-4">
                                <label>Shipping Carrier</label>
                                <select name="" id="" class="form-control mb-3" readOnly>
                                    <option value={equipLog.shipped && equipLog.shipped.shippingCarrier && equipLog.shipped.shippingCarrier.carrier_name}>{equipLog.shipped && equipLog.shipped.shippingCarrier && equipLog.shipped.shippingCarrier.carrier_name+' ('+equipLog.shipped.shippingCarrier.speed+')'}</option>
                                </select>
                                <label>Tracking Number</label>
                                <div class="tracking_number">
                                    <input type="text" class="form-control text-red" value={equipLog.shipped && equipLog.shipped.tracking_number} readOnly/>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="form-group">
                        <label>Staff Notes (Optional): </label>
                        <p className="input_text">
                            {equipLog.unitRequested && equipLog.unitRequested.staff_note}
                            {equipLog.received && equipLog.received.staff_note}
                            {equipLog.servicing && equipLog.servicing.staff_note}
                            {equipLog.decommission && equipLog.decommission.staff_note}
                            {equipLog.sold && equipLog.sold.staff_note}
                        </p>
                    </div>}
                    <div className="form_button">
                        <button className="btn-main btn-sm" onClick={() => handleEdit(equipLog)}>Edit</button>
                        <button className="btn-main link-btn text-red" onClick={() => window.confirm('Are you sure you want to delete log ?') ? deleteLog(equipLog) : ''}><i className="fas fa-trash"></i></button>
                        {sending ? 
                            <ReactLoading className="m-auto" type="bubbles" color="#ca3c3c" />
                            :
                            [ updateID === equipLog.id ?
                                <button className="btn-main btn-sm btn-gray ml-auto">Sent!!</button>    
                                :
                                <button className="btn-main btn-sm btn-green ml-auto" onClick={() => this.sendEmail(equipLog,customer)}>Send Notification</button>
                            ]
                            
                        }
                        
                    </div>
                </Card.Body>                
            </Card>
        )
    }
}

export default MyCard;