import React from 'react';
import Select from 'react-select';

export class DropdownList extends React.Component {

    render(){
        const {label, options, onChangeFunc, selectedValue, placeholder } = this.props;
        return (
            <div className="form-group">
                <label>{label}</label>
                <Select
                value={selectedValue}
                onChange={onChangeFunc}
                options={options}
                defaultValue={selectedValue}
                placeholder={placeholder}
                isClearable={true}
                />
            </div>
        );
    }

}

export default DropdownList;