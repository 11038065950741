import React from 'react';
import DropdownList from '../lists/DropdownList';
import ClientList from '../lists/ClientList';
import CompanyList from '../lists/CompanyList';
import { getEquipmentSelect, searchEquipment } from '../../services/equipmentService';
import { getClient } from '../../services/clientService';
import ErrorsList from "../../components/common/ErrorsList";
import ReactLoading from "react-loading";

export class Customer extends React.Component {

	constructor(props){
        super(props);
        this.state = {
			equipOptions:{},
			selectedClient: null,
			selectedCompany: null,
			selectedEquip: null,
			hasError: '',
			equipmentList:{},
			equipIDs:[],
			loader: false
        }
		this.handleSearch = this.handleSearch.bind(this);
		this.handleSelection = this.handleSelection.bind(this);
		this.handleUnselection = this.handleUnselection.bind(this);
	}

	componentDidMount() {
        
		getEquipmentSelect().then(equip => {
            this.setState({
                equipOptions: equip,
			}); 
			equip.map((e) => {
				if(this.props.fields.serial_number === e.value){
					this.setState({
						selectedEquip: e,
					}); 
					return true;
				}
				return e;
			});
		});
    }
	
	handleClient = selectedClient => {
		const {afterSelect} = this.props;
		this.setState({ selectedClient });

		if(selectedClient === null){
			afterSelect({label:"client",value:""});
			afterSelect({label:"client_name",value:""});
		}else{
			getClient({ params:{filter :{"where": {"id": selectedClient.value} } }}).then(client => {
				this.setIDs('account_number',client.house_account);
				afterSelect({label:"account_number",value:client.house_account});
			});
			afterSelect({label:"client",value:selectedClient.value});
			afterSelect({label:"client_name",value:selectedClient.label});
		}
	};

	handleCompany = selectedCompany => {
		const {afterSelect} = this.props;
		this.setState({ selectedCompany });
		
		if(selectedCompany === null){
			afterSelect({label:"company",value:""});
		}else{
			afterSelect({label:"company",value:selectedCompany.value});
		}
		
	};

	handleEquip = selectedEquip => {
		const {afterSelect} = this.props;
		this.setState({ selectedEquip });
		if(selectedEquip === null)
			afterSelect({label:"serial_number",value:""});
		else
			afterSelect({label:"serial_number",value:selectedEquip.value});
	};

	handleSearch(event){
		event.preventDefault();
		const { client, company, serial_number } = this.props.fields;
		
		let payload = {
			"client":client,
			"company":company,
			"serial_number":serial_number
		}
		if(client === ''){
			var errs = [];
			errs.push('Please Choose Client');
			this.setState({ hasError: errs});
		}else{
			this.setState({
                loader: true,
			});
			searchEquipment(payload)
			.then((response) => {
				
				this.setState({
					loader: false,
					equipmentList: response,
					equipIDs:[]
				}); 
				this.setIDs();
			})
			.catch((error) => {
				var err_str = [];
				var errors = Object.values(error.response.data.error.details.messages);
				errors.map((err) => 
					err_str.push(err[0])
				);
				this.setState({
					hasError: err_str,
					loader: true
				});
			});
		}
	}

	handleSelection(id){
		
		this.setState(prevState => {
			return {
				equipIDs: [id, ...prevState.equipIDs],
			}
		});
		
		this.setIDs();
	}

	handleUnselection(id){
		
		this.setState(prevState => {
			let equipIDs = prevState.equipIDs.filter((post) => {
				return id !== post;
			});
			return {
				equipIDs: equipIDs,
			}
		});
		
		this.setIDs();
	}

	setIDs(){
		setTimeout(() => { 
			const {afterSelect} = this.props;
			if(this.state.equipIDs.length === 0){
				afterSelect({label:"showNext",value:false});
			}
			else{
				afterSelect({label:"showNext",value:true});
			}
			afterSelect({label:"equipIDs",value:this.state.equipIDs});
		}, 500);
	}

	render () {
		
		const { selectedClient, selectedCompany, selectedEquip, equipOptions, equipmentList, equipIDs, loader } = this.state;
		
		return (
			<div className="opti_wrapper">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12">
							<div className="add_new_quote">
								<div className="section_title">
									<h2>New Quote</h2>
								</div>	
								{this.state.hasError !== '' &&
									<ErrorsList errors={this.state.hasError}></ErrorsList>}
								<div className="quote_card">
									<div className="card_title">Client Information</div>
									<div className="client_info_filter">
										<div className="row align-items-center">
											<div className="col">
												<CompanyList
												onChangeFunc={this.handleCompany}
												selectedValue={selectedCompany}>
												</CompanyList>
											</div>
											<div className="col">
												{selectedCompany === null ?
												<ClientList
												company={selectedCompany}
												onChangeFunc={this.handleClient}>
												</ClientList>
												:
												<ClientList
												company={selectedCompany.value}
												onChangeFunc={this.handleClient}
												selectedValue={selectedClient}>
												</ClientList>
												}
												
											</div>
											<div className="col">
												<DropdownList
												label="Serial Number(s)"
												options={equipOptions}
												selectedValue={selectedEquip}
												onChangeFunc={this.handleEquip}
												placeholder={<div>Type to search</div>}>
												</DropdownList>
											</div>
											<div className="col-2 text-right">
												<label></label>
												<button className="btn-main btn-sm mt-4" onClick={this.handleSearch}>Search</button>
											</div>
										</div>																			
									</div> 
								</div>
								<div className="crm_table">
									<div className="table">
									{loader && <ReactLoading className="m-auto" type="bubbles" color="#ca3c3c" />}
									{equipmentList.length > 0 &&<h4 className="titleSmall">Matched Units</h4>}
									{equipmentList.length > 0 ?
										[equipmentList.map((list)=>
											<React.Fragment>											
											<div className="show_added_list" key={list.id}>
												<div className="row">
													<div className="col col-sm-1 pl-4">
													{list.image !== '' ?
														<img src={list.image} alt=""/> :
														<img src="./img/log-img.png" alt=""/>
													}																
													</div>
													<div className="col">{list.serial_number && list.serial_number}</div>
													<div className="col"><span className="texdivred ">{list.item_name}</span></div>
													<div className="col"><span className="">{list.client_name}</span></div>
													<div className="col">{list.company}</div>
													{equipIDs.indexOf(list.id) >= 0 ?
														<div className="col"><button type="button" onClick={() => this.handleUnselection(list.id)} className="btn-main" >Selected!</button></div>	
														:
														<div className="col"><button type="button" onClick={() => this.handleSelection(list.id)} className="btn-main secondary" >Select</button></div>
													}	
													
												</div>
											</div>
											</React.Fragment>
										)]
										:
										<div className="show_added_list">
											<div className="row">
												<div className="col">
													Unit Not Found
												</div>
											</div>
										</div>
									}
									</div>
								</div>
							</div>	
							
						</div>
					</div>  
				</div>
			</div> 
		)
  	}
}

export default Customer;