import React from 'react';

export class partsManagement extends React.Component {
    render(){
        return(
        <div className="opti_wrapper faq_page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <h1 className="page_title">FAQ</h1>
                        <div className="section_break">
                            <h3>Parts Management</h3>
                            <p>This section of the Opti: CRM is where all repair parts will be added so they may be added within the Quoted Update.  All parts must have the following data:</p>
                            <ul>
                                <li>Product Name (Verbose)</li>
                                <li>Product ID or SKU</li>
                                <li>Unit Cost</li>
                            </ul>
                        </div> 
                    </div>
                </div>                
            </div>
        </div>
        );
    }
}
export default partsManagement;