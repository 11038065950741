import React from 'react';

export class QuoteDetail extends React.Component {

    render() {
        const { quote } = this.props;
        return(
            <React.Fragment>
            <div className="items_detail" id="quoteDetails">
                <h4 className="titleSmall border_under">Quoted Unit(s) </h4>
                <div className="crm_table unit_quoted">
                    <div className="table">
                    {quote.quoteEquipments.length > 0 && 
                        [quote.quoteEquipments.map((eq)=> 
                        <div className="show_added_list" key={eq.id}>
                            <div className="row align-items-center">
                                <div className="col col-sm-1">
                                    <img src={eq.equipment.item.image} alt=""/>
                                </div>	
                                <div className="product_series col-2">
                                    {eq.equipment.serial_number}
                                </div>
                                <div className="col-2">
                                    {eq.equipment.item.item_name}
                                </div>
                                <div className="col-2">
                                {eq.equipment.customer && eq.equipment.customer.first_name+' '+eq.equipment.customer.last_name}
                                </div>
                                <div className="col-2">
                                {eq.equipment.customer && eq.equipment.customer.company}
                                </div>
                            </div>
                        </div>)]}
                    </div>
                </div>

                <h4 className="titleSmall border_under">Items required for service</h4>
                <div className="add_new_quote">
                    <div className="quote_card">
                        <div className="QuoteFrm">                                        
                            <div className="form-group border-0">
                                <div className="row">
                                    <div className="col-6">
                                        <label>Items</label>
                                    </div>
                                    <div className="col-2 text-center">
                                        <label className="text-center w-100">Units</label>
                                    </div>
                                    <div className="col-2 text-center">
                                        <label className="text-center w-100">Unit Cost</label>
                                    </div>
                                    <div className="col-2 text-center">
                                        <label className="text-center w-100">Total</label>
                                    </div> 
                                </div>
                            </div>
                            {quote.quoteParts.length > 0 && 
                            [quote.quoteParts.map((part)=> 
                            <div className="form-group" key={part.id}>
                                <div className="row">
                                    <div className="col-6">
                                        <p>{part.part.part_name}</p>														
                                    </div>
                                    <div className="col-2 text-center">
                                        <p>{part.quantity}</p>              
                                    </div>
                                    <div className="col-2 text-center">
                                        <div className="part_relative">
                                            <img src="./img/dollar-icon.svg" alt="" height="14"/>
                                            <p>{part.unit_cost.toFixed(2)}</p>
                                        </div>
                                    </div>
                                    <div className="col-2 text-center">
                                        <div className="part_relative">
                                            <img src="./img/dollar-icon.svg" alt="" height="14"/>
                                            <p>{part.sub_total.toFixed(2)}</p>	
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            )]}
                        </div>
                    </div>
                </div>

                <h4 className="titleSmall border_under">Shipping</h4>
                <div className="crm_table unit_quoted">
                    <div className="table">
                        <div className="show_added_list h-40">
                            <div className="row align-items-center">													
                                <div className="col-3">
                                {quote.shipping_carrier} 
                                </div>		
                                <div className="col-3">
                                7 Business Days
                                </div>										
                                <div className="col-3 ml-auto text-right">
                                $ {quote.shipping_cost.toFixed(2)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            </React.Fragment>
        );
    }
}

export default QuoteDetail;