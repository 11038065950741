import React from 'react';

export class serviceRequestFaq extends React.Component {
    render(){
        return(
        <div className="opti_wrapper faq_page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <h1 className="page_title">FAQ</h1>
                        <div className="section_break">
                            <h3>Service Requests</h3>
                            <p>Service requests are submissions of the Service Form on the Opti:Manufacturing website. These requests must be reviewed often as they are the most likely source of initial client interactions and in the customer relation process.</p>
                        </div> 

                        <div className="section_break">
                            <h5 className="mb-5">Service Request Statuses</h5>
                            <h4>Request Unit</h4>
                            <p>Choosing this option sends the Request Unit email to the client’s provided email. The email is a fairly simple response that details the process of a product return. </p>
                            <p>If the serial number provided by client in the Service Form <strong>matches</strong> a Tension Meter in our Log, a Request Unit Update will be posted to that Tension Meter’s profile.</p>
                            <p>If the serial number provided by client in the Service Form <strong>does not match</strong> a Tension Meter within the Log, an alert will be shown. The Request Unit email will be sent, but it will be up to the staff to receive and determine if that Tension Meter should be added to the Log. If added, you may add a Request Unit to its profile.</p>
                        </div> 

                        <div className="section_break">
                            <h4>Respond</h4>
                            <p>Choosing this option will allow you to simply post a response to the client’s query, for cases where Requesting the Unit is not appropriate. A branded email will be sent to the client with the Response that is typed into the system</p>
                        </div>

                        <div className="section_break">
                            <h4>Archive</h4>
                            <p>Once a Service Request has been resolved, it can be archived so it doesn’t interfere with any new or on-going request. You may see Archived Service Request by clicking on the Archived link on the Service Request page.</p>
                        </div> 
                        
                        <div className="section_break pt-5">
                            <h5 className="mb-5">Service Request Types</h5>
                            <h4>Calibration</h4>
                            <p>Users will select this option when the wish to send the unit in for calibration.</p>
                        </div>
                        <div className="section_break">
                            <h4>Repairs</h4>
                            <p>User will select this option when repairs are needed for their unit or it may require diagnostics or servicing of any kind</p>
                        </div>
                        <div className="section_break">
                            <h4>Other</h4>
                            <p>User will select this option when no other request type seems appropriate for their needs.</p>
                        </div>
                        
                    </div>
                </div>                
            </div>
        </div>
        );
    }
}
export default serviceRequestFaq;