import httpService from './httpService';

// This is an example of movie service, we should create a service like this for each Model defined on loopback API and use is in all components / pages as needed.

export const getSeries = () => {
    return httpService.get("/Items/series")
            .then((response) => {
               return response && response.data;
            });
}

export const getSeriesSelect = () => {
   return httpService.get("/Items/seriesSelect")
           .then((response) => {
              return response && response.data;
           });
}

export const addItem = (data) => {
   return httpService.get("/Items/",data)
           .then((response) => {
              return response && response.data;
           });
}

export const getItems = (data) => {
   return httpService.get("/Items/",data)
           .then((response) => {
              return response && response.data;
           });
}

export const updateItem = (data) => {
   return httpService.patch("/Items/",data)
           .then((response) => {
              return response && response.data;
           });
}

// more such methods can be defined here, for different endpoints of a model