import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

class SideBar extends React.Component {

    render() {
        const {quote, customer} = this.props;
        var todayDate = new Date();
        var calibrationDate = '';
        return(
            <AwesomeSlider className="equipment_slider" buttons={false}>
            {quote && quote.quoteEquipments.map((qe)=> 
                <div key={qe.id} className="w-100">
                <ul>
                    <li className="row d-flex align-items-center">
                        <div className="product_series col">
                            <h3>{qe.equipment.serial_number}</h3>
                            <p>{qe.equipment.item.item_name}</p>
                        </div>
                        <div className="product_img col text-right">
                            <img width="100" src={qe.equipment.item.image} alt=""/>
                        </div>
                    </li>
                    <li className="row">
                        <div className="col text-offtext">Purchase Date</div>
                        <div className="col text-right">December 12,2017</div>
                    </li>
                    <li className="row">
                        <div className="col text-offtext">Client</div>
                        <div className="col text-right">{customer.first_name} {customer.last_name}</div>
                    </li>
                    <li className="row">
                        <div className="col text-offtext">Company</div>
                        <div className="col text-right">{customer.company}</div>
                    </li>
                    <li className="row">
                        <div className="col text-offtext">Email</div>
                        <div className="col text-right">{customer.email}</div>
                    </li>
                    <li className="row">
                        <div className="col text-offtext">Telephone</div>
                        <div className="col text-right">{customer.phone}</div>
                    </li>
                    <li className="row">
                        <div className="col text-offtext">Calibration Due:</div>
                        <div className="col text-right">
                        {qe.equipment.reminders.length > 0 && qe.equipment.reminders.map((rem)=> {
                            calibrationDate = [rem.reminderType.before_days === 0 ? new Date(rem.sending_date) : '']
                            return (<React.Fragment key={rem.id}>{rem.reminderType.before_days === 0 && new Date(rem.sending_date).toLocaleDateString('default', { month: 'long' })+' '+new Date(rem.sending_date).getDate()+', '+new Date(rem.sending_date).getFullYear()}</React.Fragment>)
                            }
                        )}
                        </div>
                    </li>
                    <li className="row">
                        <div className="col text-offtext">Warranty Status</div>
                        <div className="col text-right text-red">
                        {todayDate > calibrationDate ? 'Expired' : 'Active'}
                        </div>
                    </li>
                </ul>
                </div>
            )}
                </AwesomeSlider>
            )
    }
}

export default SideBar;