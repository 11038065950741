import React from 'react';
import NewQuote from './NewQuote';
import QuoteList from './QuoteList';
import QuoteView from './QuoteView';
import { getQuoteList, getQuoteTotal, updateQuote, deleteQuote, getQuoteIds } from '../../services/quoteService';
import ErrorsList from "../../components/common/ErrorsList";
import "./quotes.scss";

class Quotes extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            quotes: [],
            addQuote: false,
            hasError: '',
            hasSuccess: '',
            loader: true,
            quotesTotal: 0,
            currentPage: 1,
            perPage: 5,
            skip: 0,
            search: '',
            editQuote: false,
            editQuoteData: [],
            history: props.history,
        }

        this.handleShow = this.handleShow.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.backToQuoteList = this.backToQuoteList.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        
        var countParam = {};
        
        getQuoteTotal(countParam).then(total=> {
            this.setState({
                quotesTotal: total.count
            }); 
        })
        this.getQuotes();
    }

    handleDelete(data){
        deleteQuote(data.id)
        .then((response) => {
            this.setState(prevState => {
                let quotes = prevState.quotes.filter((post) => {
                    return data.id !== post.id;
                });
                return {
                    quotes: quotes,
                }
            });
        })
        .catch(function (error) {
            var err_str = [];
            var errors = Object.values(error.response.data.error.details.messages);
            errors.map((err) => 
                err_str.push(err[0])
            );
            this.setState({ hasError: err_str});
        });
    }

    getQuotes(){
        this.setState({
            loader: true
        });
        const {perPage, skip, search} = this.state;
        const searchParam = { params:{filter :{"search":search}}};
        getQuoteIds(searchParam).then( ids => {
            this.setState({
                quotesTotal: ids.length
            }); 
            getQuoteList(ids,perPage,skip).then(quote => {
                this.setState({
                    quotes: quote,
                    loader: false
                }); 
            });
        });
    }

    onChange(e){
        this.setState({ search: e.target.value });
    }

    handleSearch(event){
        event.preventDefault();
        this.setState({
            loader: true
        }); 
        this.componentDidMount();
    }

    next() {
        const {currentPage, perPage} = this.state;
        this.setState({
            loader: true,
            skip: perPage*currentPage,
            currentPage: currentPage+1
        },() => {this.getQuotes();}); 
        // setTimeout(() => { this.componentDidMount(); }, 500);
    }

    prev() {
        const {currentPage, perPage, skip} = this.state;
        this.setState({
            loader: true,
            skip: skip-perPage,
            currentPage: currentPage-1
        },() => {this.getQuotes();}); 
        // setTimeout(() => { this.componentDidMount(); }, 500);
    }

    getTotalPagesCount(){
        const {quotesTotal, perPage} = this.state;
        return Math.ceil(quotesTotal / perPage);
    }

    handleShow() {
        this.setState(function () {
            return {
                addQuote: true
            }
        })
    }

    handleHide(msg) {
        
        this.setState(function () {
            return {
                loader: true,
                addQuote: false,
                hasSuccess: msg
            }
        })
        setTimeout(() => { this.componentDidMount(); this.setState({ hasSuccess: ""}); }, 3000);
    }

    handleEdit(quote) {
        
        this.setState(function () {
            return {
                editQuote: true,
                editQuoteData: quote
            }
        })
        this.state.history.push('/quote/'+quote.id+'/'+quote.token);
        
    }

    backToQuoteList(){
        this.setState(function () {
            return {
                editQuote: false,
                editQuoteData: []
            }
        })
        
    }

    updateStatus(data){
        updateQuote(data)
            .then((response) => {
                // update the user in list
                if(response.id > 0){
                    this.setState({ hasSuccess: "Quote Status Updated",editQuote: false,loader: true});
                    setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                    this.getQuotes();
                }
                
            })
            .catch((error) => {
                var err_str = [];
                
                if(typeof error.response.data.error.details !== 'undefined'){
                    var errors = Object.values(error.response.data.error.details.messages);
                    errors.map((err) => 
                        err_str.push(err[0])
                    );
                }
                else{
                    err_str.push(error.response.data.error.message);
                }
                
                this.setState({ hasError: err_str});
                setTimeout(() => { this.setState({ hasError: ""}); }, 3000);
            });
    }

    render() {
        const { addQuote, editQuote, quotes, loader, currentPage, quotesTotal, perPage, search, editQuoteData } = this.state;
        var TotalPages = this.getTotalPagesCount();
        return (
            <React.Fragment>
            {addQuote ? 
                <NewQuote handleHide={this.handleHide} quotesTotal={quotesTotal}></NewQuote>    
                :
                <div className="opti_wrapper clients_page">
                    { editQuote ? 
                        <QuoteView 
                        quote={editQuoteData}
                        submit={this.updateStatus}
                        back={this.backToQuoteList}>
                        </QuoteView>
                        :
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="page_title">Quotes</h1>
                                <div className="btn-and-search">
                                    <button onClick={this.handleShow} className="btn-main">Add New Quote <img src="./img/plus-icon-white.svg" alt="" height="14"/></button>
                                    <div className="opti_search ml-auto">
                                        <form onSubmit={this.handleSearch}>
                                        <img src="./img/search-icon.svg" alt="" height="16"/>
                                        <input type="text" onChange={this.onChange} className="form-control" value={search} placeholder="Quote Number"/>
                                        <input type="submit" className="search_btn" value="Search"/>
                                        </form>
                                    </div>
                                </div>
                                {this.state.hasError !== '' &&
                                    <ErrorsList errors={this.state.hasError}></ErrorsList>}
                                {this.state.hasSuccess !== '' &&
                                    <div className="alert alert-success alert-dismissible fade show mt-5" role="alert">
                                        <strong>Success!</strong> {this.state.hasSuccess}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    </div>}
                                <div className="row crm_table">
                                    <QuoteList 
                                    quotes={quotes}
                                    hideForm={this.handleHide}
                                    onSubmit={this.handleSubmit}
                                    editId={this.state.clientIdToEdit}
                                    editClient={this.state.editClient}
                                    onEdit={this.handleEdit}
                                    onEditCancel={this.handleEditCancel}
                                    onEditSubmit={this.handleUpdate}
                                    deleteQuote={this.handleDelete}
                                    loader={loader}>
                                    </QuoteList>
                                </div>
                                {quotesTotal > perPage && 
                                [currentPage !== 1 &&
                                    <button type="button" onClick={this.prev} class="btn-main btn-sm">Prev</button>]
                                }
                                {quotesTotal > perPage && 
                                [TotalPages !== currentPage &&
                                    <button type="button" onClick={this.next} class="btn-main btn-sm">Next</button>]
                                }
                            </div>
                        </div>                
                    </div>}
                </div>}
            </React.Fragment>
        );
    }
}

export default Quotes;