import React, { Component } from 'react';
import OrderList from './OrderList';
import OrderView from './OrderView';
import {getOrders, updateOrder, getOrderIds, deleteOrder} from '../../services/orderService';
import ErrorsList from "../../components/common/ErrorsList";
import { Form } from 'react-bootstrap';

class OrdersPage extends Component {

    constructor(props){
        super(props);
        
        this.state = {
            orders: [],
            hasError: '',
            hasSuccess: '',
            loader: true,
            showOrder: false,
            dotBtn: false,
            curID: null,
            orderDetail: [],
            archivedOrders: [],
            showArchived: false,
            search: '',
            status: '',
            history: props.history,
        }
        this.handleDot = this.handleDot.bind(this);
        this.handleView = this.handleView.bind(this);
        this.doArchived = this.doArchived.bind(this);
        this.doUnArchived = this.doUnArchived.bind(this);
        this.showOrderByAct = this.showOrderByAct.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    handleDot(id){
        
        if(this.state.dotBtn) {
            this.setState({
                dotBtn: false,
                curID: null
            });
        }else{
            this.setState({
                dotBtn: true,
                curID: id
            });
        }
	}

    componentDidMount() {
        this.getOrderList();
        this.getArchivedOrders();
    }

    getOrderList(){
        this.setState({
            loader: true
        });
        const {search, status} = this.state;
        const searchParam = { params:{filter :{"is_archive":0,"status":status,"search":search}}};
        getOrderIds(searchParam).then( ids => {
            getOrders(ids).then(order => {
                this.setState({
                    orders: order,
                    loader: false,
                    dotBtn: false,
                    curID: null
                });
            });
        });
    }

    getArchivedOrders(){
        this.setState({
            loader: true
        });
        const {search, status} = this.state;
        const searchParam = { params:{filter :{"is_archive":1,"status":status,"search":search}}};
        getOrderIds(searchParam).then( ids => {
            getOrders(ids).then(order => {
                this.setState({
                    archivedOrders: order,
                    loader: false,
                    dotBtn: false,
                    curID: null
                });
            });
        });
    }

    handleView(order){
        if (order && order.id){
            this.setState({
                orderDetail: order,
                showOrder: true
            });
            this.state.history.push('/order/'+order.id);
        }
    }

    showOrderByAct(isArchive){
        if(isArchive === 1)
        {
            this.setState({
                showArchived: true,
                dotBtn: false,
                curID: null
            }); 
        }
        else
        {
            this.setState({
                showArchived: false,
                dotBtn: false,
                curID: null
            }); 
        }
    }

    doArchived(id){
        var serviceRequestData = {
            is_archived: 1,
            id: id
        }
        updateOrder(serviceRequestData)
        .then(sRequest => {
            
            this.setState(prevState => {
                let orders = prevState.orders.filter((post) => {
                    return id !== post.id;
                });
                return {
                    orders: orders,
                }
            });
            
            this.getArchivedOrders();
            this.setState({ hasSuccess: "Order Archived"});
            setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
        })
        .catch((error) => {
            this.handleError(error);
        });
    }

    doUnArchived(id){
        var serviceRequestData = {
            is_archived: 0,
            id: id
        }
        updateOrder(serviceRequestData)
        .then(sRequest => {
            
            this.setState(prevState => {
                let archivedOrders = prevState.archivedOrders.filter((post) => {
                    return id !== post.id;
                });
                return {
                    archivedOrders: archivedOrders,
                }
            });
            this.getOrderList();
            
            this.setState({ hasSuccess: "Order Unarchived"});
            setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
        })
        .catch((error) => {
            this.handleError(error);
        });
    }

    handleSearch(event){
        event.preventDefault();
        if(this.state.showArchived)
            this.getArchivedOrders();
        else
            this.getOrderList();
    }

    onChange(e){
        if (e.target.id === 'search') {
            this.setState({ search: e.target.value });
        } else if (e.target.id === 'status') {
            this.setState({
                status: e.target.value
            }, () => {
                if(this.state.showArchived)
                    this.getArchivedOrders();
                else
                    this.getOrderList();
            });
            
        }
    }

    handleDelete(id,is_archived){
        deleteOrder(id)
        .then((response) => {
            if(is_archived === true){
                this.setState(prevState => {
                    let archivedOrders = prevState.archivedOrders.filter((post) => {
                        return id !== post.id;
                    });
                    return {
                        archivedOrders: archivedOrders,
                    }
                });
            }else{
                this.setState(prevState => {
                    let orders = prevState.orders.filter((post) => {
                        return id !== post.id;
                    });
                    return {
                        orders: orders,
                    }
                });
            }
        })
        .catch(function (error) {
            var err_str = [];
            var errors = Object.values(error.response.data.error.details.messages);
            errors.map((err) => 
                err_str.push(err[0])
            );
            this.setState({ hasError: err_str});
        });
    }

    render(){
        const {orders, loader, dotBtn, curID, showOrder, showArchived, archivedOrders, search, status, orderDetail} = this.state;
        return (
            <div className="opti_wrapper part_page order_page">
                {showOrder ?
                    <OrderView 
                    order={orderDetail}>
                    </OrderView>
                    :
                    <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="log_page_header">
                                <div className="log_page_title">
                                    <h1 className="page_title">Orders</h1>
                                </div>
                                <div className="btn-and-search">
                                    <div className="opti_search">
                                        <form onSubmit={this.handleSearch}>
                                        <img src="./img/search-icon.svg" alt="" height="16"/>
                                        <input type="text" id="search" name="search" onChange={this.onChange} value={search} className="form-control" placeholder="Serial Number, Client, Model..."/>
                                        <input type="submit" className="search_btn" value="Search"/>
                                        </form>
                                    </div>
                                    <div className="dropdown">
                                        <label>Filter by Status:</label>
                                        <Form.Control as="select" className="dropdown-menu-right" id="status" name="status" onChange={this.onChange} value={status}>
                                        <option value="">Choose Status</option>
                                        <option value="Pending">Pending</option>
                                        <option value="Fully Shipped">Fully Shipped</option>
                                        <option value="Partially Shipped">Partially Shipped</option>
                                        </Form.Control>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="request_filter">
                                {showArchived ?
                                    <React.Fragment>
                                        <span className="filter_text" onClick={() => this.showOrderByAct(0)}>Active [{orders.length}]</span>
                                        <span className="filter_text ml-auto active" onClick={() => this.showOrderByAct(1)}>Archived [{archivedOrders.length}]</span>
                                    </React.Fragment>
                                :
                                <React.Fragment>
                                <span className="filter_text active" onClick={() => this.showOrderByAct(0)}>Active [{orders.length}]</span>
                                <span className="filter_text ml-auto" onClick={() => this.showOrderByAct(1)}>Archived [{archivedOrders.length}]</span>
                                </React.Fragment>}
                            </div>
                            {this.state.hasError !== '' &&
                                <ErrorsList errors={this.state.hasError}></ErrorsList>}
                            {this.state.hasSuccess !== '' &&
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    <strong>Success!</strong> {this.state.hasSuccess}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                </div>}
                            <div className="row crm_table">
                                <OrderList 
                                orders={showArchived ? archivedOrders : orders}
                                doAction={showArchived ? this.doUnArchived : this.doArchived}
                                onView={this.handleView}
                                deleteOrder={this.handleDelete}
                                handleDot={this.handleDot}
                                curID={curID}
                                dotBtn={dotBtn}
                                loader={loader}
                                showArchived={showArchived}>
                                </OrderList>
                            </div>
                        </div>
                    </div>                
                </div>
                }
            </div>
        )
    }
}

export default OrdersPage;