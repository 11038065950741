import React from 'react';
import OrderItems from './OrderItems';
import { Card, Accordion } from 'react-bootstrap';
import { updateEquipment, getEquipmentByOrderId } from '../../services/equipmentService';
import { getOrderById, updateOrder } from '../../services/orderService';
import { addNewUpdate, addNewUpdateTable, CalibrationStart, getUpdateById, sendUpdateEmail, getShippedTotal} from '../../services/updateService';
import { getCarrierList } from '../../services/shippingCarrier';
import ErrorsList from "../../components/common/ErrorsList";

export class OrderView extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            sendNotification: false,
            sending: false,
            updateID: null,
            open: false,
            class: "card_close",
            contentClass: "d-none",
            order: '',
            customer: '',
            hasError: [],
            hasSuccess: '',
            user_id: localStorage.getItem('user_id'),
            updates: [],
            equipments: [],
            equipArray: [],
            carriers: [],
            shipping_carrier_id: '',
            tracking_number: '',
            staff_note: '',
            sent_shipping: false,
            itemCount:0,
            processing: false,
            history: props.history,
            shipCount: null,
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleShippingSubmit = this.handleShippingSubmit.bind(this);
    }

    componentDidMount(){
        this.getSingleOrderById(this.props.match.params.id);
        
        this.getEquipmentsByOrderId();
        getCarrierList({ params:{filter :{order: 'id asc'} } }).then(carrier => {
            this.setState({
                carriers: carrier
            }); 
        });
    }

    shippedCountByEquipmentID(id){
        getShippedTotal(id).then((response) => {
            this.setState({
                shipCount: Object.keys(response).length
            });
        });
    }

    getEquipmentsByOrderId(){
        getEquipmentByOrderId(this.props.match.params.id)
        .then( equipments => {
            var eArray = [];
            var shippedCount = 0;
            equipments.forEach((equipment) => {
                var data = {"equipment_id":equipment.id,"serial_number":equipment.serial_number,"option": "No", "item_name":equipment.item.item_name, "shipped_id": null};
                if(equipment.updates){
                    equipment.updates.forEach((update) => {
                        if(update.shipped){
                            shippedCount+=1;
                            this.setState({
                                sent_shipping: true
                            }); 
                            data = {"equipment_id":equipment.id,"serial_number":equipment.serial_number,"option": "Yes", "item_name":equipment.item.item_name, "shipped_id": update.shipped.id};
                        }
                    });
                }
                eArray.push(data);
            })
            if(this.state.itemCount === shippedCount){
                this.updateStatus('Fully Shipped');
            }
            else if(shippedCount > 0 && this.state.itemCount > shippedCount){
                this.updateStatus('Partially Shipped');
            }
            
            this.setState({
                equipments: equipments,
                equipArray: eArray
              });
        })
    }

    updateStatus(status){
        var orderData = {
            "status": status,
            "id": this.props.match.params.id
        }
        updateOrder(orderData)
        .then((response) => {
            
        })
    }

    handleClick(){        
        if(this.state.open) {
            this.setState({
              open: false,
              class: "card_close",
            });
        }else{
            this.setState({
              open: true,
              class: "card_open",
            });
        }
    }

    getSingleOrderById(id){
        getOrderById(id).then(order => {
            this.setState({
                order: order
            });

            var itemCount = 0;
            order.OrderItems.forEach((item) => {
                itemCount+=item.item_unit;
            });
            this.setState({
                itemCount: itemCount
            }); 
        });
    }

    prepareEquipmentPayload(equipmentData) {
        
        const { customer_id, item_id, order_id, serial_number } = equipmentData;
        
        let payloadData = {
            "customer_id": customer_id,
            "item_id": item_id,
            "order_id": order_id,
            "serial_number": serial_number
        };
        
        if(equipmentData.id != null) {
            payloadData['id'] = equipmentData.id;
        }
        
        return payloadData;
    }

    handleSubmit(data){
        data.forEach((d) => {
            
            const payload = this.prepareEquipmentPayload(d);
            if(payload.serial_number !== ''){
                updateEquipment(payload)
                .then((response) => {
                    if(response.id > 0){
                        this.getSingleOrderById(response.order_id);
                        if(d.id !== null){
                            this.setState({ hasSuccess: "Serial Number Updated"});
                            setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                        }
                        if(d.id === null){
                            let soldpayload = {
                                "equipment_id": response.id,
                                "update_type_id": 7,
                                "user_id": this.state.user_id
                            };
                            addNewUpdate(soldpayload)
                            .then((sold) => {
                                var updates = [];
                                updates.push(sold);
                                this.setState({ updates: [...this.state.updates, response.id]});
                                let table = '/Solds';
                                var purchase_date = new Date(this.state.order.created_at);
                                let tableData = {
                                    "update_id": sold.id,
                                    "staff_note": 'Sold',
                                    "date": purchase_date.getFullYear()+'-'+(purchase_date.getMonth()+1)+'-'+purchase_date.getDate()
                                };
                                addNewUpdateTable(table,tableData)
                                .then((res)=>{
                                    if(res.id > 0){
                                        this.getEquipmentsByOrderId();
                                        // var today = new Date();
                                        // let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                                        // let calibrationData = {
                                        //     "serial_number": response.serial_number,
                                        //     "equipment_id": response.id,
                                        //     "shipping_date": date
                                        // };
                                        // CalibrationStart(calibrationData)
                                        // .then((calibrantion) => {
                                            
                                        // });
                                    }
                                })
                            })
                        }
                        
                    }
                })
                .catch((error) => {
                    var err_str = [];
                    
                    if(typeof error.response.data.error.details !== 'undefined'){
                        var errors = Object.values(error.response.data.error.details.messages);
                        errors.map((err) => 
                            err_str.push(err[0])
                        );
                    }
                    else{
                        err_str.push(error.response.data.error.message);
                    }
                    this.setState({ hasError: [...this.state.hasError, err_str]});
                    setTimeout(() => { this.setState({ hasError: ""}); }, 5000);
                });
            }
        })
        
    }

    onChange(e){
        
        if (e.target.id === 'staff_note') {
            this.setState({ staff_note: e.target.value });
        }
        else if(e.target.id === 'shipping_carrier_id'){
            this.setState({shipping_carrier_id: e.target.value });
        }
        else if(e.target.id === 'tracking_number'){
            this.setState({tracking_number: e.target.value });
        }
    }

    handleShippingSubmit(event){
        this.setState({processing: true });
        event.preventDefault();
        const {equipArray, staff_note, tracking_number, shipping_carrier_id, customer} = this.state;
        equipArray.forEach((equipment)=> {
            if(equipment.option === "Yes" && equipment.shipped_id === null){
                let shippingPayload = {
                    "equipment_id": equipment.equipment_id,
                    "update_type_id": 1,
                    "user_id": this.state.user_id
                };
                addNewUpdate(shippingPayload)
                .then((update) => {
                    let table = '/Shippeds';
                    let tableData = {
                        "update_id": update.id,
                        "staff_note": staff_note,
                        "shipping_carrier_id": shipping_carrier_id,
                        "tracking_number": tracking_number,
                        "date": new Date()
                    };
                    addNewUpdateTable(table,tableData)
                    .then((res)=>{
                        if(res.id > 0){
                            this.getEquipmentsByOrderId();

                            var today = new Date();
                            let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                            let calibrationData = {
                                "serial_number": equipment.serial_number,
                                "equipment_id": equipment.equipment_id,
                                "shipping_date": date
                            };
                            CalibrationStart(calibrationData)
                            .then((calibrantion) => {
                                
                            });

                            getUpdateById(update.id)
                            .then((latestUpdate)=>{

                                var emailData = {"update":latestUpdate,"customer":customer, "serialNumber":equipment.serial_number, "itemName":equipment.item_name};
                                sendUpdateEmail(emailData)
                                .then((response) => {
                                    this.setState({
                                        tracking_number: '',
                                        shipping_carrier_id: '',
                                        staff_note: '',
                                        processing: false
                                      });
                                })
                            })
                        }
                    })
                })
            }
            else{
                this.setState({processing: false });
            }
        })
    }

    handleEquipmentRadio = idx => evt => {
        const newArray = this.state.equipArray.map((equipment, sidx) => {
            if (idx !== sidx) return equipment;
            if(evt.target.id === 'serial_number'){
                return { ...equipment,  "equipment_id":equipment.equipment_id,"serial_number":equipment.serial_number,"option": evt.target.value,"item_name":equipment.item_name,"shipped_id": equipment.shipped_id};
            }
            return equipment;  
        });
    
        this.setState({ equipArray: newArray });
    }

    render(){
        const { order, updates, equipArray, carriers, tracking_number, staff_note, sent_shipping, equipments, processing } = this.state;
        const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        return(
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="text-red breadcrumb-item active" onClick={() => this.state.history.push('/orders')} aria-current="page"><button className="text-red">Orders</button></li>
                                <li className="breadcrumb-item">{order.woo_order_id}</li>                                    
                            </ol>
                        </nav>
                    </div>
                    <div className="col-md-8"> 
                        <div className="section_title">
                            <h2>Order Number #{order.woo_order_id}</h2>
                            <p>{new Date(order.created_at).toLocaleDateString('en-En', dateOptions)} <span className="status_paid">Paid</span></p>
                        </div> 
                        <div className="show_log_updates">
                            <div className="accordion" id="accordionExample">
                                <Accordion> 
                                    <Card>
                                        <Card.Header>
                                        <Accordion.Toggle variant="link" eventKey="1" className={this.state.class+" d-flex w-100"} onClick={this.handleClick}>
                                        <p className="my-auto">Order Details</p>
                                        <h2 className="mb-0 ml-auto">
                                                <button className="btn btn-link collapsed" type="button" >
                                                $ {order.total && parseFloat(order.total).toFixed(2)} <img src="./img/plus-icon.svg" alt="" height="14" className="plus"/><img src="./img/chev-down-icon.svg" alt="" height="7" className="minus"/>
                                                </button>
                                            </h2>
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            <div className="invoice_single">
                                                <div className="customer_info mb-0">
                                                    <div className="row">
                                                        <div className="col-10">
                                                            <div className="ci_area d-none">
                                                                <p>Payment via PayPal (5VF96882AK0877329). Paid on June 11, 2019 @ 2:56 pm. 
                                                                    Customer IP: 64.237.236.14</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <div className="ci_area">
                                                                <h5>Billing:</h5>
                                                                <p>{order.customer && order.customer.first_name+' '+order.customer.last_name}</p>
                                                                <p>{order.customer && order.customer.address+' '+order.customer.address1+', '+order.customer.city}</p>
                                                                <p>{order.customer && order.customer.state+', '+order.customer.country}</p>
                                                                <p>{order.customer && order.customer.zipcode}</p>
                                                            </div>
                                                            <div className="ci_area">
                                                                <h5>Email address:</h5>
                                                                <p>{order.customer && order.customer.email}</p>                                          
                                                            </div>
                                                            <div className="ci_area">
                                                                <h5>Phone:</h5>
                                                                <p>{order.customer && order.customer.phone}</p>                                          
                                                            </div>
                                                            {order.client_note !== null &&
                                                            <div className="ci_area">
                                                                <h5>Client Note</h5>
                                                                <p>{order.client_note}</p>                                      
                                                            </div>}
                                                        </div>
                                                        <div className="col-5">
                                                            <div className="ci_area">
                                                                <h5>Shipping:</h5>
                                                                <p>{order.customer && order.customer.first_name+' '+order.customer.last_name}</p>
                                                                <p>{order.customer && order.customer.address+' '+order.customer.address1+', '+order.customer.city}</p>
                                                                <p>{order.customer && order.customer.state+', '+order.customer.country}</p>
                                                                <p>{order.customer && order.customer.zipcode}</p>
                                                            </div>  
                                                            <div className="ci_area">
                                                                <h5>Terms</h5>
                                                                <p>Accepted</p>                                          
                                                            </div>                                      
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                            
                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                            {this.state.hasError !== '' &&
                            <ErrorsList errors={this.state.hasError}></ErrorsList>}
                            {this.state.hasSuccess !== '' &&
                                    <div className="alert alert-success alert-dismissible fade show mt-5" role="alert">
                                        <strong>Success!</strong> {this.state.hasSuccess}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    </div>}
                            <div className="crm_table">
                                <div className="table">  
                                {order.OrderItems && order.OrderItems.map((orderItem)=>     
                                    <OrderItems
                                    orderItem={orderItem}
                                    units={orderItem.item_unit}
                                    onSubmit={this.handleSubmit}
                                    customerId={order.customer && order.customer.id}
                                    orderId={order.id}
                                    updates={updates}
                                    >
                                    </OrderItems>
                                )}
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div className="col-md-4">
                        <div className="order_shipping_info">
                            <h4 className="titleSmall border_under">Send Shipping Information</h4>
                            <div className="shipping_info_card">
                                <p className="no_shipping">Add at least one tensiometer to the log from this order.</p>
                            </div>
                            {equipArray.length > 0 &&
                            <form onSubmit={this.handleShippingSubmit}>
                            <div className="shipping_info_card">
                                <h5>Units in the Shipment</h5>
                                {equipArray.map((equipment, idx)=>
                                <div className="unit_of_ship">
                                    <label className="my-auto">{equipment.serial_number}</label>
                                    <div className="custome_radio ml-auto">
                                        <label className="opti_radio">
                                        {equipment.shipped_id !== null ?
                                            <input type="radio" id="serial_number" name={equipment.serial_number} disabled value="No" className="warranty" onChange={this.handleEquipmentRadio(idx)}/> 
                                            :
                                            <input type="radio" id="serial_number" name={equipment.serial_number} value="No" className="warranty" onChange={this.handleEquipmentRadio(idx)}/>}
                                            <span className="checkmark"></span>
                                            No
                                        </label>
                                        <label className="opti_radio"> 
                                        {equipment.shipped_id !== null ?
                                            <input type="radio" id="serial_number" name={equipment.serial_number} checked disabled value="Yes" className="warranty" onChange={this.handleEquipmentRadio(idx)}/> 
                                            :
                                            <input type="radio" id="serial_number" name={equipment.serial_number} value="Yes" className="warranty" onChange={this.handleEquipmentRadio(idx)}/>}
                                            <span className="checkmark"></span>
                                            Yes
                                        </label>
                                    </div>														
                                </div>
                                )}
                                <div className="shipping_carrier_order">
                                    <h5>Shipping Carrier</h5>
                                    <select id="shipping_carrier_id" ref="shipping_carrier_id" className="form-control mb-3" onChange={this.onChange} value={this.state.shipping_carrier_id}>
                                        <option value="hidden">Choose Carrier</option>
                                        {carriers.map((carrier)=>
                                            <option key={carrier.id} value={carrier.id}>{carrier.carrier_name} ({carrier.speed})</option>
                                        )}
                                    </select>
                                    <h5>Tracking Number</h5>
                                    <input type="text" id="tracking_number" onChange={this.onChange} value={tracking_number} className="form-control"></input>
                                    <h5>Details</h5>
                                    <textarea name="staff_note" id="staff_note" onChange={this.onChange} value={staff_note} className="form-control"></textarea>
                                    {processing ?
                                        <button type="button" className="btn-main btn-block secondary mt-4">Processing ...</button>
                                        :
                                        <button type="submit" className="btn-main btn-block secondary mt-4">Send Shipped Notification</button>}
                                </div>
                            </div>
                            </form>}
                        </div>
                        {sent_shipping &&
                        <div className="sent_shipping_info order_shipping_info">
                            <h4 className="titleSmall border_under">Sent Shipping Notifications</h4>

                            <div className="accordion" id="accordionExample">
                                <Accordion> 
                                {equipments.map((equipment) => 
                                    [equipment.updates && 
                                        [equipment.updates.map((update) => 
                                            [update.shipped &&
                                                <Card key={update.shipped.id}>
                                                    <Card.Header>
                                                    <Accordion.Toggle variant="link" eventKey="1" className={this.state.class+" d-flex w-100"} onClick={this.handleClick}>
                                                    <p className="my-auto">{new Date(update.shipped.date).toLocaleDateString('en-En', dateOptions)}</p>
                                                    <h2 className="mb-0 ml-auto">
                                                        <button className="btn btn-link collapsed" type="button" >
                                                        <img src="./img/plus-icon.svg" alt="" height="14" className="plus"/><img src="./img/chev-down-icon.svg" alt="" height="7" className="minus"/>
                                                        </button>
                                                    </h2>
                                                    </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="1">
                                                    <Card.Body>
                                                    <h5>Units in the Shipment</h5>
                                                    <div className="unit_of_ship">
                                                        <label className="my-auto">{equipment.serial_number}</label>                                            														
                                                    </div>    

                                                    <div className="shipping_carrier_order">
                                                        <h5>Shipping Carrier</h5>
                                                        <p className="text-red">{update.shipped.shippingCarrier && update.shipped.shippingCarrier.carrier_name+' ('+update.shipped.shippingCarrier.speed+')'}</p>
                                                        <h5>Tracking Number</h5>
                                                        <p>{update.shipped.tracking_number}</p>
                                                        <h5>Details</h5>
                                                        <p>{update.shipped.staff_note}</p>
                                                    </div>                                 
                                                    </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            ]
                                        )]
                                            
                                    ]
                                    
                                )}
                                    
                                </Accordion>
                            </div>
                        </div>}
                    </div>
                </div>                
            </div>
        )
    }
}

export default OrderView;