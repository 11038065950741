import React from 'react';

export class StaffManagement extends React.Component {
    render(){
        return(
        <div className="opti_wrapper faq_page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <h1 className="page_title">FAQ</h1>
                        <div className="section_break">
                            <h3>Staff Management</h3>
                            <p>All Opti personnel that will interact with this tool, must be added to the Staff Management section in order to log into the system. All user must have:</p>
                            <ul>
                                <li>Name</li>
                                <li>Email</li>
                                <li>Password</li>
                            </ul>
                        </div>  
                        <div className="section_break">
                            <h5 className="mb-4">User Roles</h5>
                            <h4>Admin</h4>
                            <p>This type of user will be able to interact with all sections of the Opti: CRM including:</p>
                            <ul>
                                <li>User Creation and Edit</li>
                                <li>Tensiomenter Log Management</li>
                                <li>Parts Management</li>
                                <li>Service Request Access</li>
                                <li>Statistics Access</li>
                            </ul>
                        </div>

                        <div className="section_break">
                            <h4>Customer Service</h4>
                            <p>This type of user will be able to interact with all sections of the Opti: CRM including:</p>
                            <ul>
                                <li>Tensiomenter Log Management</li>
                                <li>Parts Management</li>
                                <li>Service Request Access</li>
                            </ul>
                        </div>
                        
                    </div>
                </div>                
            </div>
        </div>
        );
    }
}
export default StaffManagement;