import React from 'react';
import ReactLoading from "react-loading";
import invoiceStatuses from "./invoiceStatuses";

export class InvoiceList extends React.Component {

    render(){
        const {invoices, loader, onEdit} = this.props;
        return(
            <div className="row crm_table">
                <div className="col-md-12 table">
                    <div className="row head_row">
                        <div className="col col-sm-3">Customer / Company</div>
                        <div className="col col-sm-3">Invoice Number</div>
                        <div className="col col-sm-4">Status</div>
                        <div className="col col-sm-2">Action</div>
                    </div>
                    {loader && <ReactLoading className="m-auto" type="bubbles" color="#ca3c3c" />}
                    {invoices.length > 0 ?
                        [invoices.map((invoice)=>  
                            [invoice.customer &&
                            <div className="show_added_list">                                            
                                <div className="row">
                                    <div className="col col-sm-3"><h4>{invoice.customer.first_name} {invoice.customer.last_name}</h4><span className="client_company">{invoice.customer.company}</span></div>
                                    <div className="col col-sm-3"><p className="client_email">{invoice.id}</p></div>
                                    <div className="col col-sm-4">
                                        <p className="client_email text-red">
                                        {parseInt(invoice.status) === invoiceStatuses.Pending ?	
											'Pending'
										:
										parseInt(invoice.status) === invoiceStatuses.Paid ?
											'Paid'
										:
										parseInt(invoice.status) === invoiceStatuses.Cancelled ?	
											'Cancelled'
										:
                                        parseInt(invoice.status) === invoiceStatuses.Shipback ?
											'Shipback'
										:
                                        parseInt(invoice.status) === invoiceStatuses.Decommissioned ?
											'Decommissioned'
										: ''}
                                        </p>
                                    </div>
                                    <div className="col col-sm-2">
                                        <button className="btn-main secondary" onClick={() => onEdit(invoice.id)}>View Invoice</button>
                                    </div>
                                </div>                                    
                            </div>]
                        )]
                        :
                        <div className="show_added_list">
                            <div className="row"><div className="col col-sm-12">No Invoice Found</div></div>
                        </div>   
                    }               
                </div>
            </div>
        );
    }
}

export default InvoiceList;