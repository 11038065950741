import React from 'react';

export class calibrationCycle extends React.Component {
    render(){
        return(
        <div className="opti_wrapper faq_page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <h1 className="page_title">FAQ</h1>
                        <div className="section_break">
                            <h3>Calibration Cycle (Flag)</h3>
                            <p>All Tensiometers must be calibrated every year(365 days). The status called Calibration Cycle means to identify Tensiometer that are eligible for calibration. Archived or Decommissioned units are ineligible to be part of the Calibration Cycle.</p>
                            <p>The Calibration Cycle also encompasses the 5 main notifications that will be sent to a client with an eligible unit.  </p>
                        </div>                  

                        <div className="section_break">
                            <div className="row mb-4">
                                <div className="col-3">
                                    <h5 className="text-red">60 Day Reminder</h5>
                                </div>
                                <div className="col-9">
                                    <p>This reminder will be sent 305 days after a unit is initially shipped or its Calibration Cycle restarted after a servicing status is applied that contained Tensiometer Calibration. The goal is to let the client know their unit will need to be re-calibrated in 60 days. </p>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-3">
                                    <h5 className="text-red">30 Day Reminder</h5>
                                </div>
                                <div className="col-9">
                                    <p>This reminder will be sent 305 days after a unit is initially shipped or its Calibration Cycle restarted after a servicing status is applied that contained Tensiometer Calibration. The goal is to let the client know their unit will need to be re-calibrated in 30 days. </p>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-3">
                                    <h5 className="text-red">7 Day Reminder</h5>
                                </div>
                                <div className="col-9">
                                    <p>This reminder will be sent 358 days after a unit is initially shipped or its Calibration Cycle restarted after a servicing status is applied that contained Tensiometer Calibration. The goal is to let the client know their unit will need to be re-calibrated in 7 days. </p>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-3">
                                    <h5 className="text-red">1 Day Reminder</h5>
                                </div>
                                <div className="col-9">
                                    <p>This reminder will be sent 364 days after a unit is initially shipped or its Calibration Cycle restarted after a servicing status is applied that contained Tensiometer Calibration. The goal is to let the client know their unit will need to be re-calibrated in 1 day. </p>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-3">
                                    <h5 className="text-red">Calibration Expired</h5>
                                </div>
                                <div className="col-9">
                                    <p>This notification will let the client know that their unit is no longer calibrated and as such its measuring capabilities may be comprised.</p>
                                </div>
                            </div>
                        </div>

                        <div className="section_break">
                            <h5>What happens if a Tensiometer’s Calibration Expires?</h5>
                            <p>The Tensiometer will be set to the status: Calibration Expired. From here on out it will be up to the staff to communicate with the client to urge them to return their unit for calibration.</p>
                            <h5>How is the Calibration Cycle Started or Reset?</h5>
                            <p>Within the Shipped Status Update, the question below will be presented. If the Staff selects Yes, the cycle will begin again starting as soon as the notification is saved. If the Staff selects No, the cycle will continue based on its last state. </p>
                        </div>

                        <div className="section_break">
                            <h5>Calibration Cycle Actions and Resolutions</h5>
                            <div className="row mb-4 mt-4">
                                <div className="col-3">
                                    <h5 className="text-red">Unit’s Calibration Cycle Has never been started</h5>
                                </div>
                                <div className="col-9">
                                    <p>If the Staff has never marked a unit to Start the Calibration Cycle, it will not take take place and the Client will not receive notifications regarding it. By default it will considered Expired.</p>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-3">
                                    <h5 className="text-red">Unit’s Calibration Cycle has expired</h5>
                                </div>
                                <div className="col-9">
                                    <p>If the the Cycle for a unit has not been reset within the allotted timeframe (365 days), said unit will be marked with a status of Calibration Expired. </p>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-3">
                                    <h5 className="text-red">Unit’s Calibration Cycle is Reset</h5>
                                </div>
                                <div className="col-9">
                                    <p>If the the Cycle for a unit is reset within the Shipping Status Update, the 365 Day Calibration Cycle will begin again and all notifications will be queued with their respective delays.   </p>
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>                
            </div>
        </div>
        );
    }
}
export default calibrationCycle;