import React from 'react';
import { NavLink } from "react-router-dom";
import SideBar from "./SideBar";
import { updateInvoice, getInvoiceDetail } from '../../services/invoiceService';

export class InvoiceSingle extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            status: 0,
			invoiceId: this.props.match.params.id,
            editInvoice: true,
            invoice: [],
            history: props.history,
        }
		
		this.handleChange = this.handleChange.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        console.log('id from URL = ', this.props.match.params.id);
        getInvoiceDetail(this.props.match.params.id).then(data => {
            console.log('data = ',data);
            this.setState(function () {
                return {
                    invoice: data,
                    status: data.status
                }
            })
        });
    }
    
    handleChange(e) {
		this.setState({ status: e.target.value });
	}

	handleUpdate(){
		this.updateStatus({"id":this.state.invoiceId,"status": parseInt(this.state.status),"user_id": localStorage.getItem('user_id'),"updated_at": new Date()});
	}

    updateStatus(data){
        updateInvoice(data)
            .then((response) => {
                if(response.id > 0){
                    // this.setState({ hasSuccess: "Invoice Status Updated",editInvoice: false,loader: true});
                    // setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                    // this.getInvoices();
                    this.state.history.push('/invoices');
                }
                
            })
            .catch((error) => {
                var err_str = [];
                
                if(typeof error.response.data.error.details !== 'undefined'){
                    var errors = Object.values(error.response.data.error.details.messages);
                    errors.map((err) => 
                        err_str.push(err[0])
                    );
                }
                else{
                    err_str.push(error.response.data.error.message);
                }
                
                this.setState({ hasError: err_str});
                setTimeout(() => { this.setState({ hasError: ""}); }, 3000);
            });
    }

    handleCancel() {
        this.state.history.push('/invoices');
    }
    
    render(){
        const {invoice} = this.state;
        console.log('invoice by ID =', invoice);
        var invoiceCreated = invoice.quote ? new Date (invoice.quote.created_at) : '';
        var invoiceUpdated = invoice.quote ? new Date (invoice.quote.updated_at) : '';
        var serial_number = [];
        if(invoice.quote && invoice.quote.quoteEquipments.length > 0){
            invoice.quote.quoteEquipments.map((equipment)=>  
                serial_number.push(equipment.equipment.serial_number)
            )
        } 
        const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }; 
        return(
            <React.Fragment>
            <div className="opti_wrapper log_update_page invoice_single">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item" ><button><NavLink className="" to="/invoice">All Invoices</NavLink></button></li>
                                    <li className="breadcrumb-item active" aria-current="page">{invoice.id}</li>                                    
                                </ol>
                            </nav>
                            <div className="section_title">
                                <h2>Invoice</h2>
                                <p>Unit’s Serial Numbers: <span className="text-red">                                
                                        {serial_number.join(', ')}
                                </span></p>
                            </div>
                            <div className="invoice_header">
                                <div className="row align-items-center">
                                    <div className="col-7">  
                                    {invoice.transaction_id !== null && 
                                    <React.Fragment>                              
                                        <p><strong>Payment via PayPal ({invoice.transaction_id}).</strong></p>
                                        <p>Paid on {new Date(invoice.created_at).toLocaleDateString('en-En', dateOptions)} @ {new Date(invoice.created_at).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}.</p>
                                    </React.Fragment>}
                                    </div>
                                    <div className="col-5 text-right">
                                        <p><strong>Amount Paid:</strong> ${parseFloat(invoice.total).toFixed(2)}</p>
                                    </div>
                                </div>                                
                            </div>
                            <div className="invoice_update_form">
                                <div className="row">
                                    <div className="col-4">
                                        <label>Invoice Number</label>
                                        <input type="text" className="form-control" value={"INVOPTI-"+invoice.id} readOnly></input>
                                    </div>
                                    <div className="col-4">
                                        <label>Status</label>
                                        <select onChange={this.handleChange} className="form-control" placeholder="Status" id="status" value={this.state.status}>
                                            <option value="">Choose Status</option>
                                            <option value="0">Pending</option>
                                            <option value="1">Paid</option>
                                            <option value="2">Cancelled</option>
                                            <option value="3">Return</option>
                                            <option value="4">Decommissioned</option>
                                        </select>
                                    </div>
                                    <div className="col-4 mt-4 pt-1">                                        
                                        <button className="btn-main" onClick={this.handleUpdate}>Update</button>
                                        <button className="btn-main link-btn" onClick={this.handleCancel}>Cancel</button>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-12">
                                        {invoice.user_id > 0 &&
                                        <p><strong>Updated by:</strong> {invoice.user.first_name} {invoice.user.last_name} {invoiceUpdated && invoiceUpdated.toLocaleDateString('default', { month: 'long' })} {invoiceUpdated && invoiceUpdated.getDate()}, {invoiceUpdated && invoiceUpdated.getFullYear()} @ {invoiceUpdated && invoiceUpdated.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="customer_info">
                                <h4>Customer Information</h4>
                                <div className="row">
                                    <div className="col-5">
                                        <div className="ci_area">
                                            <h5>Billing:</h5>
                                            <p>{invoice.customer && invoice.customer.first_name} {invoice.customer && invoice.customer.last_name}</p>
                                            <p>{invoice.customer && invoice.customer.address}</p>
                                            <p>{invoice.customer && invoice.customer.address1}</p>
                                            <p>{invoice.customer && invoice.customer.country}</p>
                                            <p>{invoice.customer && invoice.customer.zipcode}</p>
                                        </div>
                                        <div className="ci_area">
                                            <h5>Email address:</h5>
                                            <p>{invoice.customer && invoice.customer.email}</p>                                          
                                        </div>
                                        <div className="ci_area">
                                            <h5>Phone:</h5>
                                            <p>{invoice.customer && invoice.customer.phone}</p>                                          
                                        </div>
                                        <div className="ci_area">
                                            <h5>Terms and Conditions:</h5>
                                            <p>Accepted</p>                                          
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <div className="ci_area">
                                            <h5>Shipping:</h5>
                                            <p>{invoice.customer && invoice.customer.first_name} {invoice.customer && invoice.customer.last_name}</p>
                                            <p>{invoice.customer && invoice.customer.address}</p>
                                            <p>{invoice.customer && invoice.customer.address1}</p>
                                            <p>{invoice.customer && invoice.customer.country}</p>
                                            <p>{invoice.customer && invoice.customer.zipcode}</p>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>

                            <div className="add_new_quote">
								<div className="quote_card">
									<div className="card_title text-red">Quoted <span>{invoiceCreated !== '' && invoiceCreated.toLocaleDateString('default', { month: 'long' })} {invoiceCreated !== '' && invoiceCreated.getDate()}, {invoiceCreated !== '' && invoiceCreated.getFullYear()} @ {invoiceCreated !== '' && invoiceCreated.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</span></div>
                                    <div className="QuoteFrm">	
                                    {invoice.quote && invoice.quote.quoteParts.length > 0 &&
                                        [invoice.quote.quoteParts.map((part)=>  
										<div className="form-group">
											<div className="row">
												<div className="col-6">
                                                    <label>Parts Required for Service</label>
                                                    <p>{part.part.part_name}</p>														
												</div>
                                                <div className="col-2 text-center">
                                                    <label className="text-center w-100">Units</label>
                                                    <p>{part.quantity}</p>              
                                                </div>
                                                <div className="col-2 text-center">
                                                    <label className="text-center w-100">Unit Cost</label>
                                                    <div className="part_relative">
                                                        <img src="./img/dollar-icon.svg" alt="" height="14"/>
                                                        <p>{parseFloat(part.unit_cost).toFixed(2)}</p>
                                                    </div>
                                                </div>
                                                <div className="col-2 text-center">
                                                    <label className="text-center w-100">Total</label>
                                                    <div className="part_relative">
                                                        <img src="./img/dollar-icon.svg" alt="" height="14"/>
                                                        <p>{parseFloat(part.sub_total).toFixed(2)}</p>	
                                                    </div>
                                                </div> 
											</div>
										</div>)]
                                    }    
										<div className="form-group">
											<div className="row">												
												<div className="col-5">
													<label>Details</label>
                                                    <p>{invoice.quote && invoice.quote.details}</p>
												</div>
												<div className="col-6 offset-1">
                                                    <div className="shipping_carrier mb-3">
                                                        <label>Shipping Carrier</label>
                                                        <p>{invoice.quote && invoice.quote.shipping_carrier}</p>
                                                    </div>                                                    
													<label>Shipping Cost</label>
													<div className="shipping_cost">
														<img src="./img/dollar-icon.svg" alt="" height="14"/>
                                                        <p className="pl-4">{invoice.quote && parseFloat(invoice.quote.shipping_cost).toFixed(2)}</p>
													</div>
												</div>
												<div className="col-12 d-flex">
													<label className="my-auto">Is the shipping cost covered by the warranty?</label>
                                                    <div className="custome_radio ml-auto">
                                                        {invoice.quote && invoice.quote.shipping_cost_covered ? 'Yes' : 'No'}
                                                    </div>														
												</div>
											</div>
										</div>
										<div className="form-group">
											<div className="row">						
												<div className="col-12 d-flex">
													<label className="my-auto">Are the parts required for service covered by the warranty?</label>
                                                    <div className="custome_radio ml-auto">
                                                        {invoice.quote && invoice.quote.service_covered ? 'Yes' : 'No'}
                                                    </div>
												</div>
											</div>
										</div>
										
										<div className="form-group">
                                            <div className="row">
                                                {invoice.quote && invoice.quote.pdf !== null &&
												<div className="col-6 d-flex">                 
													<label className="my-auto">Inspection PDF</label>
													<div className="upload_file">
														<span className="text-red"><a href={process.env.API_URL+"Containers/quotes/download/"+invoice.quote.pdf}>{invoice.quote.pdf}</a></span>
													</div>
												</div>}
												<div className="col-6 my-auto text-right">
													<label className="mb-0">Grand Total : <span className="text-red"><img src="./img/dollar-icon.svg" alt="" height="14" className="mr-2 ml-3"/>
													{invoice.quote && parseFloat(invoice.total).toFixed(2)}
													</span></label>
												</div>
											</div>
										</div>
										<div className="form-group border-0">
                                            <button className="btn-main">Go to Tensiometer Profile</button>
										</div>
									</div>										
								</div>
							</div>
						</div>

                    </div>
                </div>
            </div>
            <div className="log_right_sidebar">               
                <SideBar
                quote={invoice.quote}
                customer={invoice.customer}
                >
                </SideBar>
            </div>
            </React.Fragment>
        );
    }
}
export default InvoiceSingle;