import httpService from './httpService';

// This is an example of movie service, we should create a service like this for each Model defined on loopback API and use is in all components / pages as needed.


export const getLogList = (ids) => {
   
   var LoglistParam= { 
      params: {
                  filter : {
                              "where": {"id": {"inq":[ids]} },
                              "order": "id desc",
                              "include": [
                                 {
                                    "relation": "updateType"
                                 },
                                 {
                                    "relation": "shipped"
                                 },
                                 {
                                    "relation": "equipment",
                                    "scope": {
                                    "include": [
                                          {
                                             "relation": "customer",
                                             "scope": {
                                                "fields": ["id", "first_name", "last_name"]
                                             }
                                          },
                                          {
                                             "relation": "item",
                                             "scope": {
                                                "fields": ["id", "item_name", "image"]
                                             }
                                          },
                                          {
                                             "relation": "reminders",
                                             "scope": {
                                                "fields": ["id", "reminder_type_id","serial_number","sending_date"],
                                                "include": [{"relation": "reminderType"}]
                                             }
                                          }
                                    ]
                                    }
                                 }
                              ]
                        } 
            } 
   }
   
   return httpService.get("/Updates",LoglistParam)
            .then((response) => {
               return response && response.data;
            });
}

export const getLogbySerialNo = (serialNumber) => {
   var params = { 
               params: 
               {
                  filter : 
                  {
                     "include": 
                     [
                           {
                              "relation": "item",
                              "scope": {
                                 "fields": ["item_name","image","series"]
                                 }
                           },
                           {"relation": "customer"},
                           {"relation": "order"},
                           {"relation": "updates",
                              "scope": {
                                 "order": "id desc",
                                 "include": [
                                                   {"relation": "updateType"},
                                                   {"relation": "shipped",
                                                      "scope": {
                                                         "include": [ {"relation": "shippingCarrier"}]
                                                      }
                                                   },
                                                   {"relation": "sold"},
                                                   {"relation": "unitRequested"},
                                                   {"relation": "received"},
                                                   {"relation": "servicing"},
                                                   {"relation": "decommission"},
                                                   {
                                                      "relation": "quote",
                                                      "scope": {
                                                         "include": [
                                                               {
                                                                  "relation": "quoteParts",
                                                                  "scope": {
                                                                     "include": [{"relation": "part"}]
                                                                     }
                                                               },
                                                               {"relation": "invoice"}]
                                                         }
                                                   }
                                             ]
                              }
                           },
                           {
                              "relation": "reminders",
                              "scope": {
                                 "fields": ["id", "reminder_type_id","serial_number","sending_date"],
                                 "include": [{"relation": "reminderType"}]
                              }
                           }
                     ],
                     "where": { 
                        "serial_number": serialNumber
                     }
                  }
               } 
         }
   return httpService.get("/Equipment",params)
           .then((response) => {
              return response && response.data;
           });
}

export const addNewUpdate = (data) => {
   return httpService.post("/Updates",data)
           .then((response) => {
              return response && response.data;
           });
}

export const logUpdate = (data) => {
   return httpService.patch("/Updates",data)
           .then((response) => {
              return response && response.data;
           });
}

export const deleteUpdate = (id) => {
   return httpService.delete("/Updates/"+id)
           .then((response) => {
              return response && response.data;
           });
}

export const addNewUpdateTable = (table,data) => {
   return httpService.post(table,data)
           .then((response) => {
              return response && response.data;
           });
}

export const logUpdateTable = (table,data) => {
   return httpService.patch(table,data)
           .then((response) => {
              return response && response.data;
           });
}

export const logDeleteTable = (table,id) => {
   return httpService.delete(table+"/"+id)
           .then((response) => {
              return response && response.data;
           });
}

export const uploadPDF = (data) => {
   return httpService.post("/Containers/quotes/upload",data)
           .then((response) => {
              return response && response.data;
           });
}

export const addQuoteParts = (data) => {
   return httpService.post("/QuoteParts",data)
           .then((response) => {
              return response && response.data;
           });
}

export const updateQuoteParts = (data) => {
   return httpService.patch("/QuoteParts",data)
           .then((response) => {
              return response && response.data;
           });
}

export const addInvoice = (data) => {
   return httpService.post("/Invoices",data)
           .then((response) => {
              return response && response.data;
           });
}

export const updateInvoice = (data) => {
   return httpService.patch("/Invoices",data)
           .then((response) => {
              return response && response.data;
           });
}

export const sendUpdateEmail = (data) => {
   return httpService.post("/Updates/sendEmail",data)
           .then((response) => {
              return response && response.data;
           });
}

export const getUniqueUpdateId = (data) => {
   return httpService.get("/Updates/getUniqueUpdateMaxId",data)
           .then((response) => {
              return response && response.data;
           });
}

export const addQuoteEquipments = (data) => {
   return httpService.post("/QuoteEquipments",data)
           .then((response) => {
              return response && response.data;
           });
}

export const CalibrationStart = (data) => {
   return httpService.post("/Reminders/startCalibrationCycle",data)
           .then((response) => {
              return response && response.data;
           });
}

export const getUpdateById = (id) => {
   
   var updateParam= { 
      params: {
                  filter : {
                              "where": {"id": id},
                              "include": [
                                 {
                                    "relation": "updateType"
                                 },
                                 {"relation": "shipped",
                                    "scope": {
                                       "include": [ {"relation": "shippingCarrier"}]
                                    }
                                 }
                              ]
                        } 
            } 
      }
   
   return httpService.get("/Updates/findOne",updateParam)
            .then((response) => {
               return response && response.data;
            });
}

export const getShippedTotal = (equipment_id) => {
   
      var shipCount = {params: {
         filter : {
                  "where": {
                     "equipment_id": equipment_id, 
                     "update_type_id": 1
                  }}}
               };
   
   return httpService.get("/Updates",shipCount)
           .then((response) => {
              return response && response.data;
           });
}
// more such methods can be defined here, for different endpoints of a model

