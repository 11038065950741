import React from 'react';
import quoteStatuses from "./quoteStatuses";
import { getQuotebyTokenAndID } from '../../services/quoteService';
import ReactLoading from "react-loading";

export class QuoteView extends React.Component {

	constructor(props){
        super(props);

        this.state = {
            editStatus: false,
			status: 0,
			quoteId: 0,
			editBtn: false,
			quote: {},
			history: props.history,
        }
		this.handleClick = this.handleClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onClick = this.onClick.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleBack = this.handleBack.bind(this);
	}

	componentDidMount() {
		console.log('did mount quoteview');
		getQuotebyTokenAndID(this.props.match.params.id,this.props.match.params.token).then(quote => {
			this.setState({
				quoteId: quote.id,
				status: quote.status,
				quote: quote
			}); 
		});
	}

	handleBack(){
		this.state.history.push('/quotes');
	}

	handleClick(){
        
        if(this.state.editBtn) {
            this.setState({
				editBtn: false
            });
        }else{
            this.setState({
				editBtn: true
            });
        }
	}
	
	onClick(){
		this.setState({
			editStatus: true,
		});
	}
	
	handleChange(e) {
		this.setState({ status: e.target.value });
	}

	handleUpdate(){
		this.props.submit({"id":this.state.quoteId,"status": parseInt(this.state.status)});
	}

	handleCancel(){
		this.setState({
			editStatus: false,
			editBtn: false
		});
	}
	
    render(){
		
		const {quoteId, editStatus, editBtn, quote} = this.state;
		console.log('quote=',quote);
		var partTotal = 0;
		var createdDate = new Date(quote.created_at);
		var validUntil = new Date(quote.valid_until);
		const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }; 
        return(
			<React.Fragment>
			{quoteId > 0 ?
            <div className="quote_success_flow">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12">
							<div className="quote_success_title">
								<div className="row w-100">
									<div className="col-4">
										<button type="button" onClick={this.handleBack} className="btn-main link-btn"><i className="fa fa-arrow-left"></i> Back</button>
										<h3 className=""><span>#{quote.quote_number}</span></h3>
										{/* <div className="form-group">
											<label>Invoice Number</label>
											<input type="text" className="form-control"></input>
										</div> */}
									</div>
									<div className="col-6 text-right">
									{quote.invoice &&
									<React.Fragment>
										<p class="">Payment via PayPal ({quote.invoice.transaction_id}). Paid on {new Date(quote.invoice.created_at).toLocaleDateString('en-En', dateOptions)} @ {new Date(quote.invoice.created_at).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}.</p> 
									</React.Fragment>}
									</div>
									<div className="col-2 Status_dd">
										{editStatus ?
											<React.Fragment>
											<select onChange={this.handleChange} className="form-control" placeholder="Status" id="status" value={this.state.status}>
												<option value="">Choose Status</option>
												<option value="0">Pending</option>
												<option value="1">Paid</option>
												<option value="2">Cancelled</option>
												<option value="3">Return</option>
												<option value="4">Decommissioned</option>
											</select>
											<div className="editBtns">
												<button type="button" onClick={this.handleUpdate} className="btn-main btn-green">Save</button>
												<button type="button" onClick={this.handleCancel} className="btn-main btn-black">Cancel</button>
											</div>
											</React.Fragment>
											:
											[quote.status === quoteStatuses.Pending ?	
											<button className="btn-main pending btn-block">Pending </button>
											:
											quote.status === quoteStatuses.Paid ?
											<button className="btn-main paid btn-block btn-green">Paid </button>
											:
											quote.status === quoteStatuses.Cancelled ?	
											<button className="btn-main cancelled btn-block">Cancelled </button>
											: 
											quote.status === quoteStatuses.Shipback ?	
											<button className="btn-main shipback btn-block">Shipback </button>
											: 
											quote.status === quoteStatuses.Decommissioned ?	
											<button className="btn-main decommissioned btn-block btn-black">Decommissioned </button>
											:'',
											<img src="./img/verticle-dots.svg" onClick={this.handleClick} alt="" height="16" className="toggle_dot"/>
											,[editBtn &&
											<ul> 
											<li onClick={this.onClick}>Edit</li>
											<li className="text-red">Delete</li>
											</ul>]
											]
										}
										
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="quote_date_info">
								<div className="row">
									<div className="col-1"><span>Date:</span></div>
									<div className="col"><p>{createdDate.toLocaleDateString('default', { month: 'long' })} {createdDate.getDate()}, {createdDate.getFullYear()} {createdDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</p></div>
								</div>
								<div className="row">
									<div className="col-1"><span>To:</span></div>
									<div className="col">
										<p className="text-red">{quote.quoteEquipments.length > 0 && quote.quoteEquipments[0].equipment.customer ? quote.quoteEquipments[0].equipment.customer.first_name+' '+quote.quoteEquipments[0].equipment.customer.last_name : '-'}</p>
										<p className="text-red">{quote.quoteEquipments.length > 0 && quote.quoteEquipments[0].equipment.customer && quote.quoteEquipments[0].equipment.customer.company}</p>
										<p className="text-red">{quote.quoteEquipments.length > 0 && quote.quoteEquipments[0].equipment.customer && quote.quoteEquipments[0].equipment.customer.email}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="quote_date_info text-right">
								<h3>${quote.total.toFixed(2)}</h3>
								<p><strong>Valid Until:</strong> {validUntil.toLocaleDateString('default', { month: 'long' })} {validUntil.getDate()}, {validUntil.getFullYear()}</p>
								<p><strong>Will this be billed to a house account?</strong> {quote.is_house_account ? 'Yes' : 'No'}</p>
							</div>
						</div>

						<div className="col-md-12 mt-5 pt-5">
							<h4 className="titleSmall border_under">Customer</h4>
							<div className="crm_table unit_quoted">
								<div className="table">
									<div className="show_added_list">
										<div className="row align-items-center">													
											<div className="product_series col-2">
											{quote.quoteEquipments.length > 0 && quote.quoteEquipments[0].equipment.customer ? quote.quoteEquipments[0].equipment.customer.first_name+' '+quote.quoteEquipments[0].equipment.customer.last_name : '-'}
											</div>
											<div className="col-2">
											{quote.quoteEquipments.length > 0 && quote.quoteEquipments[0].equipment.customer && quote.quoteEquipments[0].equipment.customer.company}
											</div>
											<div className="col-2">
											{quote.quoteEquipments.length > 0 && quote.quoteEquipments[0].equipment.customer && quote.quoteEquipments[0].equipment.customer.email}
											</div>
											<div className="col-2">
											{quote.quoteEquipments.length > 0 && quote.quoteEquipments[0].equipment.customer && quote.quoteEquipments[0].equipment.customer.phone}
											</div>
											<div className="col-3 ml-auto d-flex align-items-center">
											{quote.is_house_account && 'House'} Account
											<input type="text" value={quote.account_number} className="form-control w-50 ml-2" readOnly />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-md-12 mt-5">
							<h4 className="titleSmall border_under">Units Quoted</h4>
							<div className="crm_table unit_quoted">
								<div className="table">
								{quote.quoteEquipments.length > 0 && 
									[quote.quoteEquipments.map((eq)=> 
									<div className="show_added_list">
										<div className="row align-items-center">
											<div className="col col-sm-1">
												<img src={eq.equipment.item.image} alt=""/>
											</div>	
											<div className="product_series col-2">
												{eq.equipment.serial_number}
											</div>
											<div className="col-2">
												{eq.equipment.item.item_name}
											</div>
											<div className="col-2">
											{eq.equipment.customer && eq.equipment.customer.first_name+' '+eq.equipment.customer.last_name}
											</div>
											<div className="col-2">
											{eq.equipment.customer && eq.equipment.customer.company}
											</div>
										</div>
									</div>)]}
								</div>
							</div>
						</div>

						<div className="col-md-12 mt-5">
							<div className="section_title_small">
								<h4>Parts</h4>
								<p>$
								{quote.shipping_cost_covered ?
									quote.total.toFixed(2)
									:
									quote.quoteParts.reduce(function (accumulator, part) {
										return accumulator + part.sub_total
									}, partTotal).toFixed(2)
								}
								</p>
							</div>
							<div className="crm_table unit_quoted">
								<div className="table">
									{quote.quoteParts.length > 0 && 
									[quote.quoteParts.map((part)=> 
									<div className="show_added_list h-40" key={part.id}>
										<div className="row align-items-center">													
											<div className="col-3">
											{part.part.part_name}
											</div>												
											<div className="col-3 ml-auto text-right">
												<span className="part_qty_quote"><b>Q.</b> {part.quantity}</span>
												<span className="part_price_quote">${part.sub_total.toFixed(2)}</span>
											</div>
										</div>
									</div>)]
									}
									
									<div className="form-group">
										<div className="row">						
											<div className="col-12 d-flex">
												<label className="my-auto">Are the parts required for service covered by the warranty?</label>
												<div className="custome_radio ml-auto">
													<label className="opti_radio">
														<input type="radio" name="service_covered" value="0" className="warranty" checked={!quote.service_covered ? true : false} /> 
														<span className="checkmark"></span>
														No
													</label>
													<label className="opti_radio"> 
														<input type="radio" name="service_covered" value="1" className="warranty" checked={quote.service_covered ? true : false}/> 
														<span className="checkmark"></span>
														Yes
													</label>
												</div>														
											</div>
										</div>
									</div>
									{quote.pdf !== '' &&	
									<div className="form-group">
										<div className="row">						
											<div className="col-12 d-flex">
												<label className="my-auto">Inspection PDF</label>
												<div className="ml-auto">	
																								
													<span className="text-red"><a target="blank" href={process.env.API_URL+"Containers/quotes/download/"+quote.pdf}>{quote.pdf}</a></span>
												</div>														
											</div>
										</div>
									</div>}

									<div className="form-group border-0">
										<div className="row">						
											<div className="col-12">
												<label className="my-auto">Details</label>
								<p className="pl-3 mt-3">{quote.details}</p>														
											</div>
										</div>
									</div>										
								</div>
							</div>

							<div className="section_title_small">
								<h4>Shipping</h4>
								<p>${quote.shipping_cost.toFixed(2)}</p>
							</div>
							<div className="crm_table unit_quoted">
								<div className="table">
									<div className="show_added_list h-40">
										<div className="row align-items-center">													
											<div className="col-3">
											{quote.shipping_carrier}
											</div>
											<div className="col-2">
											{quote.shipping_speed}
											</div>												
											<div className="col-3 ml-auto text-right">													
												<span className="part_price_quote">${quote.shipping_cost_covered ? '00.00' : quote.shipping_cost.toFixed(2)}</span>
											</div>
										</div>
									</div>

									<div className="form-group border-0">
										<div className="row">						
											<div className="col-12 d-flex">
												<label className="my-auto">Is the shipping cost covered by the warranty?</label>
												<div className="custome_radio ml-auto">
													<label className="opti_radio">
														<input type="radio" name="shipping_covered" value="0" className="warranty" checked={!quote.shipping_cost_covered ? true : false}/> 
														<span className="checkmark"></span>
														No
													</label>
													<label className="opti_radio"> 
														<input type="radio" name="shipping_covered" value="1" className="warranty" checked={quote.shipping_cost_covered ? true : false}/> 
														<span className="checkmark"></span>
														Yes
													</label>
												</div>														
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			:
			<ReactLoading className="m-auto" type="bubbles" color="#ca3c3c" />					
			}
			</React.Fragment>
        );
    }
}

export default QuoteView;