import React from 'react';
import "../quotes/quotes.scss";
import NewPaypalButton from './NewPaypalButton';
import SuccessReturn from './SuccessReturn';
import quoteStatuses from "../quotes/quoteStatuses";
import invoiceStatuses from "../invoice/invoiceStatuses";
import { updateQuote } from '../../services/quoteService';
import { addNewInvoice } from '../../services/invoiceService';

export class PayShipping extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            successReturn: false,
            invoice: props.invoice || null,
            shipBack: true,
            sendMeBack: true,
            decommission: props.isDecommission || false,
            decommissionState: true,
            isActive: true
        }
        this.handleCheck = this.handleCheck.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.onSuccess= this.onSuccess.bind(this);
        this.onConfirmDecommission = this.onConfirmDecommission.bind(this);
    }

    onConfirmDecommission(){
        const {quote} = this.props;
        let quoteData = {
            "id": quote.id,
            "status": quoteStatuses.Decommissioned
        }
        updateQuote(quoteData).then((quote)=> {
            if(quote.id > 0){
                
            }
        });

        const InvoiceData = {
            "quote_id": quote.id,
            "customer_id": quote.quoteEquipments.length > 0 ? quote.quoteEquipments[0].equipment.customer.id : 0,
            "total": 0,
            "status": invoiceStatuses.Decommissioned,
            "payment_date": new Date()
        };
        addNewInvoice(InvoiceData)
        .then((inv)=>{
            if(inv.id > 0){
                this.setState({
                    successReturn: true,
                    invoice: inv
                }); 
            }
        });
    }

    onConfirm(){
        const {quote} = this.props;
        let quoteData = {
            "id": quote.id,
            "status": quoteStatuses.Shipback
        }
        updateQuote(quoteData).then((quote)=> {
            if(quote.id > 0){
                
            }
        });

        const InvoiceData = {
            "quote_id": quote.id,
            "customer_id": quote.quoteEquipments.length > 0 ? quote.quoteEquipments[0].equipment.customer.id : 0,
            "total": 0,
            "status": invoiceStatuses.Shipback,
            "payment_date": new Date()
        };
        addNewInvoice(InvoiceData)
        .then((inv)=>{
            if(inv.id > 0){
                this.setState({
                    successReturn: true,
                    invoice: inv
                }); 
            }
        });
    }

    onSuccess(payment){
        
        const {quote} = this.props;
        let quoteData = {
            "id": quote.id,
            "status": quoteStatuses.Shipback
        }
        updateQuote(quoteData).then((quote)=> {
            if(quote.id > 0){

            }
        });

        const InvoiceData = {
            "quote_id": quote.id,
            "customer_id": quote.quoteEquipments.length > 0 ? quote.quoteEquipments[0].equipment.customer.id : 0,
            "total": quote.shipping_cost.toFixed(2),
            "status": invoiceStatuses.Pending,
            "payment_date": new Date(),
            "token": payment.paymentToken
        };
        addNewInvoice(InvoiceData)
        .then((inv)=>{
            if(inv.id > 0){
                this.setState({
                    successReturn: true,
                    invoice: inv
                }); 
            }
        });
        
    }

    onError(error){
        
    }

    onCancel(data){
        
    }

    handleCheck(event){
        if (event.target.id === 'terms') {
            const shipBack = event.target.checked;
            this.setState({ shipBack: shipBack });
        }
        if (event.target.id === 'send_me') {
            const sendMeBack = event.target.checked;
            this.setState({ sendMeBack: sendMeBack });
        }
        if (event.target.id === 'dicommission') {
            const decommissionState = event.target.checked;
            this.setState({ decommissionState: decommissionState });
        }
        
    }

    render() {
        const {quote, requestReturnFalse, isDecommission, isDecommissionFalse} = this.props;
        const {shipBack, sendMeBack, successReturn, invoice, decommissionState} = this.state;
        var comClass = '';
        var disClass = '';
        if(shipBack && sendMeBack)
        {
            disClass = false;
        }
        else{
            disClass = true;
        }

        if(shipBack && decommissionState)
        {
            comClass = false;
        }
        else{
            comClass = true;
        }
        return(
            <React.Fragment>
            {successReturn ? 
                <SuccessReturn
                quote={quote}
                invoice={invoice}
                >
                </SuccessReturn>
                :
            <div className="opti_wrapper payment_pages">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8">
                        {isDecommission ? 
                            <React.Fragment>
                                <h1 className="page_title">Are you sure you wish to decommission the unit(s)?</h1>
                                <div className="custome_radio ml-auto">
                                    <label className="opti_radio opti_checkbox">
                                        <input type="checkbox" name="dicommission" id="dicommission" onChange={this.handleCheck} checked={decommissionState}/> 
                                        <span className="checkmark"></span>
                                        Yes, I wish to decommission the unit(s)
                                    </label>                                        
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                            <h1 className="page_title">Are you sure you with to request a return 
                            instead of servicing the unit(s)?</h1>
                            <div className="custome_radio ml-auto">
                                <label className="opti_radio opti_checkbox">
                                    <input type="checkbox" name="send_me" id="send_me" onChange={this.handleCheck} checked={sendMeBack}/> 
                                    <span className="checkmark"></span>
                                    Yes, please send me the unit(s) back.
                                </label>                                        
                            </div>
                            </React.Fragment>
                            }
                            <div className="custome_radio ml-auto mb-5">
                                <label className="opti_radio opti_checkbox">
                                    <input type="checkbox" name="terms" id="terms" onChange={this.handleCheck} checked={shipBack}/> 
                                    <span className="checkmark"></span>
                                    I have read and agree to with Opti Manufacturing’s <a href="https://optimanufacturing.com/terms-and-conditions/">Terms and Conditions</a>
                                </label>                                        
                            </div>
                            {isDecommission ? 
                                <React.Fragment>
                                <button disabled={comClass} onClick={this.onConfirmDecommission} type="button" className="btn-main ">Confirm Decommission Request</button>
                                <button type="button" onClick={isDecommissionFalse} className="btn-main btn-black">No, take me back to the servicing quote</button>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                {quote.shipping_cost_covered && 
                                    <button disabled={disClass} onClick={this.onConfirm} type="button" className="btn-main ">Confirm Return Request</button>
                                }
                                <button type="button" onClick={requestReturnFalse} className="btn-main btn-black">No, take me back to the servicing quote</button>
                                </React.Fragment>
                            }
                            <div className="items_detail">
                                <h4 className="titleSmall border_under">Quoted Unit(s) </h4>
                                <div className="crm_table unit_quoted">
                                    <div className="table">
                                    {quote.quoteEquipments.length > 0 && 
                                        [quote.quoteEquipments.map((eq)=> 
                                        <div className="show_added_list" key={eq.id}>
                                            <div className="row align-items-center">
                                                <div className="col col-sm-1">
                                                    <img src={eq.equipment.item.image} alt=""/>
                                                </div>	
                                                <div className="product_series col-2">
                                                    {eq.equipment.serial_number}
                                                </div>
                                                <div className="col-2">
                                                    {eq.equipment.item.item_name}
                                                </div>
                                                <div className="col-2">
                                                {eq.equipment.customer.first_name+' '+eq.equipment.customer.last_name}
                                                </div>
                                                <div className="col-2">
                                                {eq.equipment.customer.company}
                                                </div>
                                            </div>
                                        </div>)]}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!quote.shipping_cost_covered &&
                        <NewPaypalButton
                        total={quote.shipping_cost.toFixed(2)}
                        onError={this.onError}
                        onSuccess={this.onSuccess}
                        onCancel={this.onCancel}
                        handleCheck={this.handleCheck}
                        shipBack={shipBack}
                        sendMeBack={sendMeBack}
                        custom={quote.id}
                        isActive={shipBack}
                        isShipping={true}
                        isDecommission={isDecommission}
                        label='Shipping Total'>
                        </NewPaypalButton>
                        }
                        
                    </div>                      

                </div>
            </div>
            }
            </React.Fragment>
        );
    }
}

export default PayShipping;