import React from 'react';
import ClientList from './ClientList';
import ClientForm from './ClientForm';
import { getClientList, addNewClient, deleteClient, updateClient, getClientTotal } from '../../services/clientService';
import ErrorsList from "../../components/common/ErrorsList";
import "./client.scss";

class ClientContainer extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            clients: [],
            addClient: false,
            clientIdToEdit: null,
            hasError: '',
            hasSuccess: '',
            clientImage: null,
            loader: true,
            editClient: [],
            clientsTotal: 0,
            currentPage: 1,
            perPage: 50,
            skip: 0,
            search: ''
        }

        this.handleShow = this.handleShow.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleEditCancel = this.handleEditCancel.bind(this);
        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const {perPage, skip, search} = this.state;
        var countParam = {};
        var clientParam = {};
        if(search === ''){
            clientParam = { params:{filter :{"limit":perPage,"skip":skip, order: 'company,first_name,last_name asc'} } };
        }
        else
        {
            countParam =    {"params": {
                                "where":{  
                                    "or": [
                                        {
                                        "first_name": search
                                        },
                                        {
                                        "last_name": search
                                        },
                                        {
                                        "email": search
                                        },
                                        {
                                        "phone": search
                                        },
                                        {
                                        "company": search
                                        }
                                    ]
                                    }
                                }
                            };
            clientParam =   {
                                    "params": {
                                    "filter": {
                                        "where": {
                                        "or": [
                                            {
                                            "first_name": search
                                            },
                                            {
                                            "last_name": search
                                            },
                                            {
                                            "email": search
                                            },
                                            {
                                            "phone": search
                                            },
                                            {
                                            "company": search
                                            }
                                        ]
                                        },
                                        "limit": perPage,
                                        "skip": skip,
                                        "order": "company,first_name,last_name asc"
                                    }
                                    }
                                };
        }

        getClientTotal(countParam).then(total=> {
            this.setState({
                clientsTotal: total.count
            }); 
        })
        
        getClientList(clientParam).then(client => {
            this.setState({
                clients: client,
                loader: false
            }); 
        });
            
    }

    next() {
        const {currentPage, perPage} = this.state;
        this.setState({
            loader: true,
            skip: perPage*currentPage,
            currentPage: currentPage+1
        }); 
        setTimeout(() => { this.componentDidMount(); }, 500);
    }

    prev() {
        const {currentPage, perPage, skip} = this.state;
        this.setState({
            loader: true,
            skip: skip-perPage,
            currentPage: currentPage-1
        }); 
        setTimeout(() => { this.componentDidMount(); }, 500);
    }

    getTotalPagesCount(){
        const {clientsTotal, perPage} = this.state;
        return Math.ceil(clientsTotal / perPage);
    }

    onChange(e){
        this.setState({ search: e.target.value });
    }

    handleSearch(event){
        event.preventDefault();
        this.setState({
            loader: true
        }); 
        this.componentDidMount();
    }

    handleShow() {
        this.setState(function () {
            return {
                addClient: true,
                clientIdToEdit: null
            }
        })
    }

    handleHide() {
        this.setState(function () {
            return {
                addClient: false
            }
        })
    }

    prepareClientPayload(clientData) {
        
        const { first_name, last_name, email, phone, address, address1, city, state, country, zipcode, company, house_account } = clientData;
        
        let payloadData = {
            "first_name": first_name,
            "last_name": last_name,
            "email": email,
            "phone": phone,
            "company": company,
            "address": address,
            "address1": address1,
            "city": city,
            "state": state,
            "country": country,
            "zipcode": zipcode,
            "house_account": house_account
        };
        
        if(this.state.clientIdToEdit != null) {
            payloadData['id'] = this.state.clientIdToEdit;
        }
        
        return payloadData;
    }

    handleSubmit(newClientData) {
        
        const payload = this.prepareClientPayload(newClientData);
        addNewClient(payload)
            .then((response) => {
                if(response.id > 0){
                    this.setState({ hasSuccess: "Client Added"});
                    setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                }
                this.setState(prevState => {
                    return {
                        clients: [response, ...prevState.clients],
                    }
                });
                this.handleHide();
            })
            .catch((error) => {
                var err_str = [];
                
                if(typeof error.response.data.error.details !== 'undefined'){
                    var errors = Object.values(error.response.data.error.details.messages);
                    errors.map((err) => 
                        err_str.push(err[0])
                    );
                }
                else{
                    err_str.push(error.response.data.error.message);
                }
                
                this.setState({ hasError: err_str});
                setTimeout(() => { this.setState({ hasError: ""}); }, 3000);
            });
        
         
    }

    handleDelete(data){
        deleteClient(data.id)
        .then((response) => {
        
            this.setState(prevState => {
                let clients = prevState.clients.filter((post) => {
                    return data.id !== post.id;
                });
                return {
                    clients: clients,
                }
            });
        })
        .catch(function (error) {
            var err_str = [];
            var errors = Object.values(error.response.data.error.details.messages);
            errors.map((err) => 
                err_str.push(err[0])
            );
            this.setState({ hasError: err_str});
        });
    }

    handleEdit(client) {
        this.handleEditCancel();
        if (client && client.id){
            this.setState({
                clientIdToEdit: client.id,
                addClient: false,
                editClient: client
            });
        }
    }

    handleEditCancel() {
        this.setState({
            clientIdToEdit: null,
            editClient: []
        });
    }

    handleUpdate(updateClientData){
        
        const payload = this.prepareClientPayload(updateClientData);
        updateClient(payload)
            .then((response) => {
                // update the user in list
                if(response.id > 0){
                    localStorage.setItem('user_name', response.first_name+" "+response.last_name);
                    this.setState({ hasSuccess: "Client Updated"});
                    setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                }
                const clientList = [...this.state.clients];
                clientList.forEach(user => {
                    if(user.id === this.state.clientIdToEdit){
                        Object.assign(user, payload); // copy new values to user
                    }
                });
                
                this.setState({
                    clients: clientList
                });
                this.handleEditCancel();
            })
            .catch((error) => {
                var err_str = [];
                
                if(typeof error.response.data.error.details !== 'undefined'){
                    var errors = Object.values(error.response.data.error.details.messages);
                    errors.map((err) => 
                        err_str.push(err[0])
                    );
                }
                else{
                    err_str.push(error.response.data.error.message);
                }
                
                this.setState({ hasError: err_str});
                setTimeout(() => { this.setState({ hasError: ""}); }, 3000);
            });
        
        
    }

    render(){
        const { addClient, clients, loader, currentPage, clientsTotal, perPage, search } = this.state;
        var TotalPages = this.getTotalPagesCount();
        return (
        <React.Fragment>
        <div className="opti_wrapper clients_page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="page_title">Clients</h1>
                        <div className="btn-and-search">
                                <button onClick={this.handleShow} className="btn-main">Add New Client <img src="./img/plus-icon-white.svg" alt="" height="14"/></button>
                            <div className="opti_search ml-auto">
                                <form onSubmit={this.handleSearch}>
                                <img src="./img/search-icon.svg" alt="" height="16"/>
                                <input type="text" onChange={this.onChange} className="form-control" value={search} placeholder="Client Name, Company Name"/>
                                <input type="submit" className="search_btn" value="Search"/>
                                </form>
                            </div>
                        </div>
                        {this.state.hasError !== '' &&
                            <ErrorsList errors={this.state.hasError}></ErrorsList>}
                        {this.state.hasSuccess !== '' &&
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                <strong>Success!</strong> {this.state.hasSuccess}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>}
                        <div className="row crm_table">
                            <ClientList 
                            clients={clients}
                            addForm={addClient}
                            hideForm={this.handleHide}
                            onSubmit={this.handleSubmit}
                            editId={this.state.clientIdToEdit}
                            editClient={this.state.editClient}
                            onEdit={this.handleEdit}
                            onEditCancel={this.handleEditCancel}
                            onEditSubmit={this.handleUpdate}
                            deleteClient={this.handleDelete}
                            loader={loader}>
                            </ClientList>
                        </div>
                        {clientsTotal > perPage && 
                        [currentPage !== 1 &&
                            <button type="button" onClick={this.prev} class="btn-main btn-sm">Prev</button>]
                        }
                        {clientsTotal > perPage && 
                        [TotalPages !== currentPage &&
                            <button type="button" onClick={this.next} class="btn-main btn-sm">Next</button>]
                        }
                    </div>
                </div>                
            </div>
        </div>
        {addClient && <div className="log_right_sidebar">
                <h2>Add New Client</h2>
                <ClientForm
                onCancel={this.handleHide}
                onSubmit={this.handleSubmit}>
                </ClientForm>
            </div>}
            {this.state.clientIdToEdit != null && <div className="log_right_sidebar">
                <h2>Edit Client</h2>
                <ClientForm
                editId={this.state.clientIdToEdit}
                clientData={this.state.editClient}
                onSubmit={this.handleUpdate}
                onCancel={this.handleEditCancel}>
                </ClientForm>
            </div>}
            </React.Fragment>
        );
    }    
  }

  export default ClientContainer;