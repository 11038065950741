import React from 'react';
import Select from 'react-select';
import { getClientList } from '../../services/clientService';

export class ClientList extends React.Component {

    constructor(props){
        super(props);
        this.state = {
			clientOptions:[],
            selectedClient: null,
            company: props.company || null
		}
	}

	componentWillReceiveProps(props) {
        
            getClientList({ params: { filter:{"where":{ "company":props.company }, "fields":[ "first_name", "last_name", "id" ] }}}).then(client => {
                var clientOpt = [];
                client.map((c) => {
                    clientOpt.push({"value":c.id,"label": c.first_name+" "+c.last_name});
                    return clientOpt;
                });
                this.setState({
                    clientOptions: clientOpt,
                }); 
            });
        
    }

    render(){
        const {onChangeFunc, selectedValue} = this.props;
        const {clientOptions} = this.state;

        return (
            <div className="form-group">
                <label>Client</label>
                <Select
                value={selectedValue}
                onChange={onChangeFunc}
                options={clientOptions}
                defaultValue={selectedValue}
                placeholder={<div>Type to search</div>}
                isClearable={true}
                />
            </div>
        );
    }

}

export default ClientList;