import React from 'react';
import {getCountries} from '../../services/countryService';

class ClientForm extends React.Component {

    constructor(props){
        super(props);
        
        if(props.clientData){
            
            const { first_name, last_name, email, phone, address, address1, city, state, country, zipcode, company, house_account } = props.clientData
            
            this.state = {
                first_name: first_name || '',
                last_name: last_name || '',
                email: email || '',
                phone: phone || '',
                company: company || '',
                house_account: house_account || '',
                address: address || '',
                address1: address1 || '',
                city: city || '',
                state: state || '',
                country: country || '',
                zipcode: zipcode || '',
                countries: [],
                classes: []
            };
        } else {
            this.state = {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                company: '',
                house_account: '',
                address: '',
                address1: '',
                city: '',
                state: '',
                country: 'US',
                zipcode: '',
                errors: {},
                countries: [],
                classes: []
            };
        }
        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.handleAddClient = this.handleAddClient.bind(this);
    
    }

    componentDidMount() {
        getCountries({ params:{filter :{order: 'id asc'} } }).then(countries=> {
            this.setState({
                countries: countries
            }); 
        })
        let { first_name, last_name, email, phone, address, address1, company, city, state, zipcode, house_account } = this.state;
        let classes = this.state.classes;
        if(first_name !== ''){
            classes['first_name'] = "active";
        }
        if(last_name !== ''){
            classes['last_name'] = "active";
        }
        if(email !== ''){
            classes['email'] = "active";
        }
        if(phone !== ''){
            classes['phone'] = "active";
        }
        if(address !== ''){
            classes['address'] = "active";
        }
        if(address1 !== ''){
            classes['address1'] = "active";
        }
        if(company !== ''){
            classes['company'] = "active";
        }
        if(city !== ''){
            classes['city'] = "active";
        }
        if(state !== ''){
            classes['state'] = "active";
        }
        if(zipcode !== ''){
            classes['zipcode'] = "active";
        }
        if(house_account !== ''){
            classes['house_account'] = "active";
        }
        
        this.setState({classes: classes});
    }

    onFocus(e) {
        let classes = this.state.classes;
        classes[e.target.id] = "active";
        this.setState({classes: classes});
    }

    onBlur(e){
        let classes = this.state.classes;
        if(e.target.value === ''){
            classes[e.target.id] = "";
        }
        else{
            classes[e.target.id] = "active";
        }
        this.setState({classes: classes});
        
    }

    onChange(e) {
        const cls = this.state.classes;
        const value = e.target.value;
        this.setState({
            [e.target.id]: value
        });
        
        const field = e.target.id;
        if(e.target.value === ''){
            cls[field] = "";
            
        }
        else{
            cls[field] = "active";
        }
        this.setState({classes: cls});
        
    }

    handleValidation()
    {
        
        let { first_name, last_name, email, phone, address, company, city, state, zipcode } = this.state;
        let errors = {};
        let formIsValid = true;
        // const { editId } = this.props;
        //Name
        if(!first_name){
            formIsValid = false;
            errors["first_name"] = "Cannot be empty";
        }

        //Last
        if(!last_name){
            formIsValid = false;
            errors["last_name"] = "Cannot be empty";
        }

        //Email
        if(!email){
            formIsValid = false;
            errors["email"] = "Cannot be empty";
        }
        if(typeof email !== "undefined"){
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');
    
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "Email is not valid";
            }
        }

        //Phone
        if(!phone){
            formIsValid = false;
            errors["phone"] = "Cannot be empty";
        }

        //Company
        if(!company){
            formIsValid = false;
            errors["company"] = "Cannot be empty";
        }

        //Address
        if(!address){
            formIsValid = false;
            errors["address"] = "Cannot be empty";
        }

        //City
        if(!city){
            formIsValid = false;
            errors["city"] = "Cannot be empty";
        }

        //State
        if(!state){
            formIsValid = false;
            errors["state"] = "Cannot be empty";
        }

        //Zip
        if(!zipcode){
            formIsValid = false;
            errors["zipcode"] = "Cannot be empty";
        }
        
        this.setState({errors: errors});
        return formIsValid;
    }

    // this method collects form data and calls parent method with client data
    handleAddClient(event) {
        event.preventDefault();
        const { onSubmit } = this.props;
        const { first_name, last_name, email, phone, address, address1, city, state, country, zipcode, company, house_account } = this.state;
        const newClientData = {
            first_name,
            last_name,
            email,
            phone,
            company,
            house_account,
            address,
            address1,
            city,
            state,
            country,
            zipcode
        };
        if(this.handleValidation()){
            onSubmit(newClientData);
        }
    }

    render(){
        const { onCancel } = this.props;
        return(
            
            <form className='row add_client' onSubmit={this.handleAddClient}>
                <div className='col col-sm-12'>
                    <div className={"form-group "+ [this.state.classes["first_name"] && this.state.classes["first_name"]]}>
                        <input type="text" onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} className="form-control" id="first_name" name="first_name" value={this.state.first_name}/>
                        {this.state.errors && <span className="error">{this.state.errors["first_name"]}</span>}
                        <label for="fname" className="form-control-placeholder">First Name</label>
                    </div>
                </div>
                <div className='col col-sm-12'>
                    <div className={"form-group "+this.state.classes["last_name"]}>
                        <input type="text" onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} className="form-control" id="last_name" value={this.state.last_name}/>
                        {this.state.errors && <span className="error">{this.state.errors["last_name"]}</span>}
                        <label for="lname" className="form-control-placeholder">Last Name</label>
                    </div>
                </div>
                <div className='col col-sm-12'>
                    <div className={"form-group "+this.state.classes["email"]}>
                        <input id='email' onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} value={this.state.email} type="text" className="form-control"/>
                        {this.state.errors && <span className="error">{this.state.errors["email"]}</span>}
                        <label for="email" className="form-control-placeholder">Email</label>
                    </div>
                </div>
                <div className='col col-sm-12'>
                    <div className={"form-group "+this.state.classes["phone"]}>
                        <input type="text" onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} className="form-control" id="phone" value={this.state.phone}/>
                        {this.state.errors && <span className="error">{this.state.errors["phone"]}</span>}
                        <label for="Telephone" className="form-control-placeholder">Telephone</label>
                    </div>
                </div>
                <div className='col col-sm-12'>
                    <div className={"form-group "+this.state.classes["company"]}>
                        <input type="text" onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} className="form-control" id="company" value={this.state.company}/>
                        {this.state.errors && <span className="error">{this.state.errors["company"]}</span>}
                        <label for="Company" className="form-control-placeholder">Company</label>
                    </div>
                </div>
                <div className='col col-sm-12'>
                    <div className={"form-group "+this.state.classes["address"]}>
                        <input type="text" onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} className="form-control"  id="address" value={this.state.address}/>
                        {this.state.errors && <span className="error">{this.state.errors["address"]}</span>}
                        <label for="address" className="form-control-placeholder">Address</label>
                    </div>
                </div>
                <div className='col col-sm-12'>
                    <div className={"form-group "+this.state.classes["address1"]}>
                        <input type="text" onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} className="form-control"  id="address1" value={this.state.address1}/>
                        {this.state.errors && <span className="error">{this.state.errors["address1"]}</span>}
                        <label for="address1" className="form-control-placeholder">Address 1</label>
                    </div>
                </div>
                <div className='col col-sm-12'>
                    <div className={"form-group "+this.state.classes["city"]}>
                        <input type="text" onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} className="form-control" id="city" value={this.state.city}/>
                        {this.state.errors && <span className="error">{this.state.errors["city"]}</span>}
                        <label for="city" className="form-control-placeholder">City</label>
                    </div>
                </div>
                <div className='col col-sm-12'>
                    <div className={"form-group "+this.state.classes["state"]}>
                        <input type="text" onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} className="form-control" id="state" value={this.state.state}/>
                        {this.state.errors && <span className="error">{this.state.errors["state"]}</span>}
                        <label for="state" className="form-control-placeholder">State</label>
                    </div>
                </div>                
                <div className='col col-sm-12'>      
                    <div className="form-group">             
                        <select onChange={this.onChange} className="form-control" placeholder="Country" id="country" value={this.state.country}>
                        <option value="">Choose Countries</option>
                        {this.state.countries.map((c) => 
                            <option key={c.id} value={c.country_code}>{c.country_name}</option>
                        )}
                        </select>
                        {this.state.errors && <span className="error">{this.state.errors["country"]}</span>}
                    </div>
                </div>
                <div className='col col-sm-12'>
                    <div className={"form-group "+this.state.classes["zipcode"]}> 
                        <input type="text" onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} className="form-control" id="zipcode" value={this.state.zipcode}/>
                        {this.state.errors && <span className="error">{this.state.errors["zipcode"]}</span>}
                        <label for="zipcode" className="form-control-placeholder">Zipcode</label>
                    </div>
                </div>
                <div className='col col-sm-12'>
                    <div className={"form-group "+this.state.classes["house_account"]}> 
                        <input type="text" onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} className="form-control" id="house_account" value={this.state.house_account}/>
                        <label for="houseaccount" className="form-control-placeholder">House Account</label>
                    </div>
                </div>
                <div className='col col-sm-12'>
                    <button className="btn-main btn-green btn-sm">Save</button><button onClick={onCancel} className="btn-main link-btn">Cancel</button>
                </div>
            </form>
            
        );
    }
}

export default ClientForm;