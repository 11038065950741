import React, { Component } from 'react';
import { Accordion } from 'react-bootstrap';
import { getLogbySerialNo, addNewUpdate, addNewUpdateTable, logDeleteTable, logUpdate, logUpdateTable, CalibrationStart, getShippedTotal, deleteUpdate } from '../../services/updateService';
import { getUpdateTypes } from '../../services/updateTypeService';
import { getPartList } from '../../services/partService';
import { updateEquipment } from '../../services/equipmentService';
// import { updateCustomer } from '../../services/customerService';
import { getCalibrationById } from '../../services/reminderService';
import { updateOrder } from '../../services/orderService';
import ErrorsList from "../../components/common/ErrorsList";
import LogForm from "./LogForm";
import SidebarForm from "./SidebarForm";
import Sidebar from "./Sidebar";
import "./home.scss";
import MyCard from './MyCard';
import ReactLoading from "react-loading";

class Log extends Component {

    constructor(props){
        super(props);

        this.state = {
            equipLogs: [],
            updateTypes: [],
            allParts: [],
            logIdToEdit: null,
            equipment_id: null,
            customer:[],
            order:[],
            reminder:[],
            item:[],
            hasError: '',
            hasSuccess: '',
            quotedPDF: '',
            ShowSidebarForm: false,
            propSerialNumber: props.match.params.id || '',
            loader: true,
            equipmentDate: null,
            showUpdateForm: false,
            shipCount: null,
            firstShippedId: null,
            history: props.history,
        }
        
        this.onHandleSubmit = this.onHandleSubmit.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleEditCancel = this.handleEditCancel.bind(this);
        this.onHandleUpdate = this.onHandleUpdate.bind(this);
        this.sidebarEditCancel = this.sidebarEditCancel.bind(this);
        this.handleSidebarEdit = this.handleSidebarEdit.bind(this);
        this.onHandleUpdateSidebar = this.onHandleUpdateSidebar.bind(this);
        this.addUpdateForm = this.addUpdateForm.bind(this);
        this.hideUpdateForm = this.hideUpdateForm.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        
        const { propSerialNumber } = this.state;
        getLogbySerialNo(propSerialNumber).then(list => {
            this.setState({
                item: list[0].item,
                customer: list[0].customer,
                reminder: list[0].reminders,
                equipment_id: list[0].id,
                equipLogs: list[0].updates,
                order: list[0].order,
                loader: false,
                equipmentDate: list[0].created_at
            }); 
            this.shippedCountByEquipmentID(list[0].id);
        });

        getUpdateTypes({ params:{filter :{order: 'id asc'} } }).then(list => {
            this.setState({
                updateTypes: list
            }); 
        });

        getPartList({ params:{filter :{order: 'id asc'} } }).then(parts => {
            this.setState({
                allParts: parts
            }); 
        });
    }

    handleDelete(data){
        deleteUpdate(data.id)
        .then((response) => {
            this.setState({ hasSuccess: "Log Deleted"});
            setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
            this.componentDidMount();
        })
        .catch(function (error) {
            var err_str = [];
            var errors = Object.values(error.response.data.error.details.messages);
            errors.map((err) => 
                err_str.push(err[0])
            );
            this.setState({ hasError: err_str});
        });
    }

    addUpdateForm(){
        this.setState({
            showUpdateForm: true
        }); 
    }

    hideUpdateForm(){
        this.setState({
            showUpdateForm: false
        }); 
    }

    handleEdit(log) {
        if (log && log.id){
            this.setState({
                logIdToEdit: log.id
            });
        }
    }

    handleEditCancel() {
        this.setState({
            logIdToEdit: null
        });
    }

    sidebarEditCancel() {
        this.setState({
            ShowSidebarForm: false
        });
    }

    handleSidebarEdit() {
        this.setState({
            ShowSidebarForm: true
        });
    }

    preparePayload(Data,response_id) {
        
        const { update_type_id, staff_note, shipping_carrier_id, id, tracking_number, date } = Data;
        var payloadData = [];
        
        if(update_type_id === '1' || parseInt(update_type_id) === 1){
            payloadData = {
                "update_id": response_id,
                "staff_note": staff_note,
                "shipping_carrier_id": shipping_carrier_id,
                "tracking_number": tracking_number,
                "date": new Date(date)
            };
        }
        else{
            payloadData = {
                "update_id": response_id,
                "staff_note": staff_note,
                "date": new Date(date)
            };
        }
        
        if(this.state.quotedPDF !== '') {
            payloadData['pdf'] = this.state.quotedPDF;
        }
        if(id != null && id > 0) {
            payloadData['id'] = id;
        }
        
        return payloadData;
    }

    getRoutebyUpdateTypeId(update_type_id){
        var table = '';
        if(parseInt(update_type_id) === 1)
            table = '/Shippeds';
        else if(parseInt(update_type_id) === 2)
            table = '/UnitRequested';
        else if(parseInt(update_type_id) === 3)
            table = '/Receiveds';
        else if(parseInt(update_type_id) === 5)
            table = '/Decommissioneds';
        else if(parseInt(update_type_id) === 6)
            table = '/Servicings';
        else if(parseInt(update_type_id) === 7)
            table = '/Solds';

        return table;
    }

    onHandleUpdateSidebar(data){
        const {equipment_id, order} = this.state;
        const {serial_number, item_id, customer_id, calibration_id, calibration_date, purchase_date} = data;
        this.setState({
            propSerialNumber: serial_number
        }); 
        
        var equipmentData = {
            "serial_number": serial_number,
            "id": equipment_id,
            "customer_id": customer_id,
            "item_id": item_id
        }
        if(typeof order === 'undefined'){
            equipmentData['created_at'] = purchase_date;
        }
        updateEquipment(equipmentData)
            .then((response) => {
                
            }).catch((error) => {
                var err_str = [];
                
                if(typeof error.response.data.error.details !== 'undefined'){
                    var errors = Object.values(error.response.data.error.details.messages);
                    errors.map((err) => 
                        err_str.push(err[0])
                    );
                }
                else{
                    err_str.push(error.response.data.error.message);
                }
                
                this.setState({ hasError: err_str});
                setTimeout(() => { this.setState({ hasError: ""}); }, 3000);
            });
        
        // const fullname = customer_name.trim().split(" ");
        // const first_name = fullname[0];
        // const last_name = (fullname[1]) ? fullname[1] : '';
        // var customerData = {
        //     "first_name": first_name,
        //     "last_name": last_name,
        //     "company": company,
        //     "id": customer.id
        // }
        // updateCustomer(customerData)
        //     .then((response) => {
                
        //     }).catch((error) => {
        //         var err_str = [];
                
        //         if(typeof error.response.data.error.details !== 'undefined'){
        //             var errors = Object.values(error.response.data.error.details.messages);
        //             errors.map((err) => 
        //                 err_str.push(err[0])
        //             );
        //         }
        //         else{
        //             err_str.push(error.response.data.error.message);
        //         }
                
        //         this.setState({ hasError: err_str});
        //         setTimeout(() => { this.setState({ hasError: ""}); }, 3000);
        //     });
        if(typeof order !== 'undefined'){
            var orderData = {
                "created_at": purchase_date,
                "id": order.id
            }
            updateOrder(orderData)
                .then((response) => {
                    
                }).catch((error) => {
                    var err_str = [];
                    
                    if(typeof error.response.data.error.details !== 'undefined'){
                        var errors = Object.values(error.response.data.error.details.messages);
                        errors.map((err) => 
                            err_str.push(err[0])
                        );
                    }
                    else{
                        err_str.push(error.response.data.error.message);
                    }
                    
                    this.setState({ hasError: err_str});
                    setTimeout(() => { this.setState({ hasError: ""}); }, 3000);
                });
        }
        
        getCalibrationById(calibration_id)
            .then((calibrationOne) => {
                if(calibrationOne.id > 0){
                    if(new Date(calibrationOne.sending_date) !== new Date(calibration_date)){
                        var today = new Date(calibration_date);
                        var shipped_year = today.getFullYear()-1;
                        let date = shipped_year+'-'+(today.getMonth()+1)+'-'+today.getDate();
                        let calibrationData = {
                            "serial_number": serial_number,
                            "equipment_id": calibrationOne.equipment_id,
                            "shipping_date": date
                        };
                        CalibrationStart(calibrationData)
                            .then((response) => {
                                this.setState({ hasSuccess: "SideBar Updated"});
                                setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                                this.sidebarEditCancel();
                                this.componentDidMount();
                            }).catch((error) => {
                                var err_str = [];
                                
                                if(typeof error.response.data.error.details !== 'undefined'){
                                    var errors = Object.values(error.response.data.error.details.messages);
                                    errors.map((err) => 
                                        err_str.push(err[0])
                                    );
                                }
                                else{
                                    err_str.push(error.response.data.error.message);
                                }
                                
                                this.setState({ hasError: err_str});
                                setTimeout(() => { this.setState({ hasError: ""}); }, 3000);
                            });
                    }
                }
        });

    }

    shippedCountByEquipmentID(id){
        getShippedTotal(id).then((response) => {
            if(Object.keys(response).length > 0){
                this.setState({
                    shipCount: Object.keys(response).length,
                    firstShippedId: response[0].id
                });
            }
            else{
                this.setState({
                    shipCount: 0,
                    firstShippedId: null
                });
            }
        });
    }

    onHandleSubmit(submitData){
        
        const { update_type_id, user_id } = submitData;
        const {equipment_id, shipCount, propSerialNumber} = this.state;
        let payloadData = {
            "equipment_id": equipment_id,
            "update_type_id": update_type_id,
            "user_id": user_id
        };
        
        var table = this.getRoutebyUpdateTypeId(update_type_id);
        
        addNewUpdate(payloadData)
            .then((response) => {
                if(response.id > 0){
                    
                    const tableData = this.preparePayload(submitData,response.id);
                    addNewUpdateTable(table,tableData)
                        .then((res)=>{
                            if(res.id > 0){
                                
                                if(shipCount === 0){
                                    var today = new Date(submitData.date);
                                    let shipDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                                    let calibrationData = {
                                        "serial_number": propSerialNumber,
                                        "equipment_id": equipment_id,
                                        "shipping_date": shipDate
                                    };
                                    CalibrationStart(calibrationData)
                                        .then((response) => {
                                            this.setState({ hasSuccess: "Log Updated"});
                                            setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                                            this.componentDidMount();
                                            this.hideUpdateForm();
                                        }).catch((error) => {
                                            
                                            var err_str = [];
                                            
                                            if(typeof error.response.data.error.details !== 'undefined'){
                                                var errors = Object.values(error.response.data.error.details.messages);
                                                errors.map((err) => 
                                                    err_str.push(err[0])
                                                );
                                            }
                                            else{
                                                err_str.push(error.response.data.error.message);
                                            }
                                            
                                        });
                                }
                                else{
                                    this.setState({ hasSuccess: "Log Updated"});
                                    setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                                    this.componentDidMount();
                                    this.hideUpdateForm();
                                }
                            }
                        })
                        .catch((error) => {
                            this.handleError(error);
                        });
                    
                }
                
                
            })
            .catch((error) => {
                this.handleError(error);
            });

    }

    onHandleUpdate(updateData){
        const { id, update_type_id, staff_note, prev_update_type_id, update_id, equipment_id, user_id, date } = updateData;
        const { firstShippedId, propSerialNumber } = this.state;
        
        if(prev_update_type_id !== update_type_id)
        {
            
            var prevTable = this.getRoutebyUpdateTypeId(prev_update_type_id);
            
            logDeleteTable(prevTable,id)
            .then((response) => {
                
            })
            .catch(function (error) {
                var err_str = [];
                var errors = Object.values(error.response.data.error.details.messages);
                errors.map((err) => 
                    err_str.push(err[0])
                );
                this.setState({ hasError: err_str});
            });
        }

        let payloadData = {
            "equipment_id": equipment_id,
            "update_type_id": update_type_id,
            "user_id": user_id,
            "id": update_id
        };

        var table = this.getRoutebyUpdateTypeId(update_type_id);
        
        logUpdate(payloadData)
            .then((response) => {
                if(response.id > 0){
                       
                    if(prev_update_type_id !== update_type_id)
                    {
                        if(update_type_id === '1' || parseInt(update_type_id) === 1)
                        {
                            const tableData = this.preparePayload(updateData,response.id);
                            addNewUpdateTable(table,tableData)
                                .then((res)=>{
                                    if(res.id > 0){
                                        this.setState({ hasSuccess: "Log Updated"});
                                        setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                                        this.componentDidMount();
                                        this.handleEditCancel();
                                    }
                                })
                                .catch((error) => {
                                    this.handleError(error);
                                });
                        }
                        else
                        {
                            let tableData = {
                                "update_id": response.id,
                                "staff_note": staff_note,
                                "date": new Date(date)
                            };
                            addNewUpdateTable(table,tableData)
                                .then((res)=>{
                                    if(res.id > 0){
                                        this.setState({ hasSuccess: "Log Updated"});
                                        setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                                        this.componentDidMount();
                                        this.handleEditCancel();
                                    }
                                })
                                .catch((error) => {
                                    this.handleError(error);
                                });
                        }        
                    }
                    else
                    {
                        
                        if(update_type_id === '1' || parseInt(update_type_id) === 1)
                        {
                            const tableData = this.preparePayload(updateData,response.id);
                            logUpdateTable(table,tableData)
                                .then((res)=>{
                                    if(res.id > 0){
                                        if(response.id === firstShippedId){
                                            var today = new Date(updateData.date);
                                            let shipDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                                            let calibrationData = {
                                                "serial_number": propSerialNumber,
                                                "equipment_id": equipment_id,
                                                "shipping_date": shipDate
                                            };
                                            CalibrationStart(calibrationData)
                                                .then((response) => {
                                                    this.setState({ hasSuccess: "Log Updated"});
                                                    setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                                                    this.componentDidMount();
                                                    this.handleEditCancel();
                                                }).catch((error) => {
                                                    
                                                    var err_str = [];
                                                    
                                                    if(typeof error.response.data.error.details !== 'undefined'){
                                                        var errors = Object.values(error.response.data.error.details.messages);
                                                        errors.map((err) => 
                                                            err_str.push(err[0])
                                                        );
                                                    }
                                                    else{
                                                        err_str.push(error.response.data.error.message);
                                                    }
                                                    
                                                });
                                        }
                                        else{
                                            this.setState({ hasSuccess: "Log Updated"});
                                            setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                                            this.componentDidMount();
                                            this.handleEditCancel();
                                        }
                                        
                                    }
                                })
                                .catch((error) => {
                                    this.handleError(error);
                                });
                        }
                        else
                        {
                            let tableData = {
                                "update_id": response.id,
                                "staff_note": staff_note,
                                "id": id,
                                "date": new Date(date)
                            };
                            logUpdateTable(table,tableData)
                                .then((res)=>{
                                    if(res.id > 0){
                                        this.setState({ hasSuccess: "Log Updated"});
                                        setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                                        this.componentDidMount();
                                        this.handleEditCancel();
                                    }
                                })
                                .catch((error) => {
                                    this.handleError(error);
                                });
                        } 
                    }
                    
                }
                
            })
            .catch((error) => {
                this.handleError(error);
            });
    }

    handleError(error){
        var err_str = [];
                                               
        if(typeof error.response.data.error.details !== 'undefined'){
            var errors = Object.values(error.response.data.error.details.messages);
            errors.map((err) => 
                err_str.push(err[0])
            );
        }
        else{
            err_str.push(error.response.data.error.message);
        }
        
        this.setState({ hasError: err_str});
        setTimeout(() => { this.setState({ hasError: ""}); }, 3000);
    }

    render() {
        const { showUpdateForm, equipLogs, updateTypes, allParts, customer, reminder, item, logIdToEdit, equipment_id, order, propSerialNumber, loader, equipmentDate } = this.state;
        
        return (
            <React.Fragment>
                <div className="opti_wrapper log_update_page">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="text-red breadcrumb-item active" onClick={() => this.state.history.push('/')} aria-current="page"><button className="text-red">Tensiometer Log</button></li>
                                        <li className="breadcrumb-item">{propSerialNumber}</li>                                    
                                    </ol>
                                </nav>
                                <h1 className="page_title">Updates<button type="button" onClick={this.addUpdateForm} className="btn-main add_update">Add Update</button></h1>
                                {this.state.hasError !== '' &&
                                    <ErrorsList errors={this.state.hasError}></ErrorsList>}
                                {this.state.hasSuccess !== '' &&
                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                        <strong>Success!</strong> {this.state.hasSuccess}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    </div>}
                                {showUpdateForm &&
                                    <LogForm
                                    onHandleSubmit={this.onHandleSubmit}
                                    updateTypes={updateTypes}
                                    allParts={allParts}
                                    onCancelClick={this.hideUpdateForm}>
                                    </LogForm>}

                                <div className="show_log_updates">
                                    <div className="accordion" id="accordionExample">
                                        {loader && <ReactLoading className="m-auto" type="bubbles" color="#ca3c3c" />}
                                        <Accordion defaultActiveKey="0">
                                        {equipLogs.map((equipLog)=>
                                            <React.Fragment key={equipLog.id}>
                                            {equipLog.id !== logIdToEdit
                                            ? (<MyCard
                                                equipLog={equipLog}
                                                handleEdit={this.handleEdit}
                                                handleEditCancel={this.handleEditCancel}
                                                customer={customer}
                                                serialNumber={propSerialNumber}
                                                deleteLog={this.handleDelete}
                                                itemName={item.item_name}>
                                                </MyCard>
                                            ) : (
                                                <LogForm
                                                editId={logIdToEdit}
                                                onHandleSubmit={this.onHandleUpdate}
                                                updateTypes={updateTypes}
                                                allParts={allParts}
                                                formState={equipLog}
                                                equipment_id={equipment_id}
                                                onCancelClick={this.handleEditCancel}>
                                                </LogForm>
                                            )}
                                            </React.Fragment>
                                        )}
                                        
                                            
                                        </Accordion>
                                    </div>
                                </div> 
                            </div>
                        </div>                
                    </div>
                </div>
                <div className="log_right_sidebar">
                    {this.state.ShowSidebarForm ?
                        <div className="log_edit">
                            <button type="button" onClick={this.sidebarEditCancel}>Cancel</button>
                        </div>
                        :
                        <div className="log_edit">
                            <button type="button" onClick={this.handleSidebarEdit}><img src="./img/verticle-dots-red.svg" alt="" height="16"/> Edit</button>
                        </div>}
                    {this.state.ShowSidebarForm ?
                        <SidebarForm
                        serialNumber={propSerialNumber}
                        purchaseDate={order ? order.created_at : equipmentDate}
                        item_id={item.id}
                        itemName={item.item_name}
                        itemImage={item.image}
                        itemSeries={item.series}
                        clientName={customer.first_name+' '+customer.last_name}
                        client_id={customer.id}
                        company={customer.company}
                        email={customer.email}
                        phone={customer.phone}
                        reminder={reminder}
                        onUpdate={this.onHandleUpdateSidebar}>
                        </SidebarForm>
                        :
                        <Sidebar
                        serialNumber={propSerialNumber}
                        purchaseDate={order ? order.created_at : equipmentDate}
                        itemName={item.item_name}
                        itemImage={item.image}
                        clientName={customer && customer.first_name+' '+customer.last_name}
                        company={customer && customer.company}
                        email={customer && customer.email}
                        phone={customer && customer.phone}
                        reminder={reminder}>
                        </Sidebar>
                    }
                    
                </div>
            </React.Fragment>
        );
    }
}

export default Log;