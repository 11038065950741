import httpService from './httpService';

// This is an example of movie service, we should create a service like this for each Model defined on loopback API and use is in all components / pages as needed.

export const getCarrierList = (data) => {
    return httpService.get("/ShippingCarriers",data)
            .then((response) => {
               return response && response.data;
            });
}

export const addNewCarrier = (data) => {
    return httpService.post("/ShippingCarriers",data)
            .then((response) => {
               return response && response.data;
            });
}

export const deleteCarrier = (id) => {
    return httpService.delete("/ShippingCarriers/"+id)
            .then((response) => {
               return response && response.data;
            });
}

export const updateCarrier = (data) => {
   return httpService.patch("/ShippingCarriers/",data)
           .then((response) => {
              return response && response.data;
           });
}

// more such methods can be defined here, for different endpoints of a model

