import React from 'react';
import Select from 'react-select';
import { getItems } from '../../services/itemService';

export class ModelList extends React.Component {

    constructor(props){
        super(props);
        this.state = {
			modelOptions:[],
            selectedModel: null,
            series: props.series || null
		}
	}

	componentWillReceiveProps(props) {
        
        getItems({ params: { filter:{"where":{ "series":props.series }, "fields":[ "item_name", "id" ] }}}).then(model => {
                var modelOpt = [];
                model.map((m) => {
                    modelOpt.push({"value":m.id,"label": m.item_name});
                    return modelOpt;
                });
                this.setState({
                    modelOptions: modelOpt,
                }); 
            });
        
    }

    render(){
        const {onChangeFunc, selectedValue} = this.props;
        const {modelOptions} = this.state;

        return (
            <div className="form-group">
                <label>Model</label>
                <Select
                value={selectedValue}
                onChange={onChangeFunc}
                options={modelOptions}
                defaultValue={selectedValue}
                placeholder={<div>Choose Model</div>}
                isClearable={true}
                />
            </div>
        );
    }

}

export default ModelList;