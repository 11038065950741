import React from 'react';
import ReactLoading from "react-loading";

class PartList extends React.Component {
    
    render(){
        const { 
            parts,
            onEdit,
            deletePart,
            loader
        } = this.props;
        return(
            <React.Fragment>
                <div className="table col-md-12">
                    <div className="row head_row">
                        <div className="col col-sm-3 pl-5">
                            <img src="./img/img-icon.svg" alt="" height="18"/>
                        </div>
                        <div className="col col-sm-3">Name</div>
                        <div className="col col-sm-2">Part Number</div>
                        <div className="col col-sm-2">Unit Cost</div>
                        
                        <div className="col col-sm-2">Action</div>
                    </div>
                    {loader && <ReactLoading className="m-auto" type="bubbles" color="#ca3c3c" />}
                    
                    
                    {parts.map((part)=>    
                        <div className="show_added_list" key={part.id}>
                            <div className="row" id={'row_data_'+part.id} key={part.id}>
                                <div className="col col-sm-3 pl-5">
                                {typeof part.picture === 'undefined' ?
                                    <img width="50px" src={process.env.API_URL+"Containers/parts/download/no-image.jpg"} alt=""/>
                                    :
                                    <img width="50px" src={process.env.API_URL+"Containers/parts/download/"+part.picture} alt=""/>
                                }
                                </div>
                                <div className="col col-sm-3">{part.part_name}</div>
                                <div className="col col-sm-2"><span className="text-red">{part.sku}</span></div>
                                <div className="col col-sm-2"><span className="text-red">$ {parseFloat(part.cost).toFixed(2)}</span></div>
                                <div className="col col-sm-2">
                                    <button className="btn-main link-btn" onClick={() => onEdit(part)}><i className="fas fa-edit"></i></button>
                                    <button className="btn-main link-btn text-red" onClick={() => window.confirm('Are you sure you want to delete '+part.part_name+' ?') ? deletePart(part) : ''}><i className="fas fa-trash"></i></button>
                                </div>
                            </div>
                                
                                    
                            
                        </div>
                    )}
                </div>
                
            </React.Fragment>
        );
    }
}

export default PartList;