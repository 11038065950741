import React from 'react';
import QuoteDetail from './QuoteDetail';
import jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';
import * as rasterizeHTML from 'rasterizehtml';
import Picture from './Image';

export class SuccessQuote extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            img: null,
        }
        this.printDocument = this.printDocument.bind(this);
        window.html2canvas = html2canvas;
        window.rasterizeHTML = rasterizeHTML;
        this.getDataUri = this.getDataUri.bind(this);
    }

    getDataUri(url, cb)
    {
        var image = new Image();
        
        // image.setAttribute('crossOrigin', 'Anonymous'); //getting images from external domain
        image.crossOrigin = 'Anonymous';
        image.onload = function () {
            var canvas = document.createElement('canvas');
            canvas.width = this.naturalWidth;
            canvas.height = this.naturalHeight; 

            //next three lines for white background in case png has a transparent background
            var ctx = canvas.getContext('2d');
            ctx.fillStyle = '#fff';  /// set white fill style
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            canvas.getContext('2d').drawImage(this, 0, 0);

            cb(canvas.toDataURL('image/jpeg'));
        };

        image.src = url;
    }

    printDocument() {
        const {quote, invoice} = this.props;
        const date = new Date(invoice.created_at);

        // this.getDataUri('http://alternoweb.com/opti-wordpress/wp-content/uploads/2013/02/t5-8000-ps.jpg', function(dataUri) {
        //     // this.setState({
        //     //     img: dataUri
        //     // }); 
        //     console.log("logo=" + dataUri);
        // });

        var doc = new jsPDF('p','pt','a4');  
        
        doc.addImage(Picture.logo,'png',10,20,175,29);
        doc.setFont("helvetica");
        
        doc.setFontSize(8);
        doc.setTextColor(255, 0, 0);
        doc.text(540, 100, 'Total: $'+invoice.total.toFixed(2));
        doc.text(540, 115, 'Invoice: #'+invoice.id);
        
        doc.setTextColor(150);
        doc.text(20, 100, 'Date:');
        doc.text(20, 115, 'To:');
        
        doc.setFontSize(6);
        doc.setTextColor(50);
        doc.text(45, 100, date.toLocaleDateString('default', { month: 'long' })+' '+date.getDate()+', '+date.getFullYear());
        doc.text(45, 115, quote.quoteEquipments[0].equipment.customer.first_name+' '+quote.quoteEquipments[0].equipment.customer.last_name);
        doc.text(45, 122, quote.quoteEquipments[0].equipment.customer.company);
        doc.text(45, 129, quote.quoteEquipments[0].equipment.customer.email);

        doc.setFontSize(10);
        doc.setFontStyle("bold");
        doc.text(20, 150, 'Quoted Unit(s)');
        doc.setDrawColor(200);
        doc.line(20, 156, 550, 154);
        for (const eq of quote.quoteEquipments) {
            doc.rect(20, 162, 530, 40); 
            doc.setFontStyle("normal");
            doc.setFontSize(8);
            doc.text(30, 185, 'Image' );
            doc.text(80, 185, eq.equipment.serial_number );
            doc.text(130, 185, eq.equipment.item.item_name );
            doc.text(230, 185, eq.equipment.customer.first_name+' '+eq.equipment.customer.last_name );
            doc.text(330, 185, eq.equipment.customer.company );
        }
        doc.setFontSize(10);
        doc.setFontStyle("bold");
        doc.text(20, 230, 'Parts');
        doc.setDrawColor(200);
        doc.line(20, 236, 550, 236);
        for (const part of quote.quoteParts) {
            doc.rect(20, 242, 530, 20); 
            doc.setFontSize(6);
            doc.text(30, 254, part.part.part_name);
            doc.text(500, 254, 'Q.');
            doc.text(507, 254, part.quantity.toString());
            doc.text(520, 254, '$'+part.unit_cost.toFixed(2));
        }
        doc.setFontStyle("bold");
        doc.text(20, 280, 'Are the parts required for service covered by the warranty?');
        doc.text(530, 280, quote.service_covered ? 'Yes' : 'No');
        
        doc.text(20, 300, 'Details: ');
        doc.text(30, 310, quote.details);

        doc.setFontSize(10);
        doc.setFontStyle("bold");
        doc.text(20, 330, 'Shipping');
        doc.setDrawColor(200);
        doc.line(20, 336, 550, 336);
        doc.rect(20, 342, 530, 20); 
        doc.setFontSize(6);
        doc.text(30, 354, quote.shipping_carrier);
        doc.text(200, 354, '7 Business Days');
        doc.text(520, 354, '$'+quote.shipping_cost.toFixed(2));
        
        doc.setFontStyle("bold");
        doc.text(20, 380, 'Is the shipping cost covered by the warranty?');
        doc.text(530, 380, quote.shipping_cost_covered ? 'Yes' : 'No');
        
        doc.save("receipt.pdf");   
    }

    render() {
        const {quote, invoice} = this.props;
        const date = new Date(invoice.created_at);
        return(
            <div className="opti_wrapper payment_pages">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8">
                            <h1 className="page_title">Thank you for your purchase! <span>Quote #{quote.quote_number}</span></h1>
                            <p>If you have any questions about the quote bellow, please call us at (787) 889-2285 or write Lee.Maldonado@optimanufacturing.com</p>
                            <button type="button" onClick={this.printDocument} className="btn-main btn-black no-radius">Download Your Receipt</button>
                            <QuoteDetail
                            quote={quote}>
                            </QuoteDetail>
                        </div>
                        
                        <div className="col-md-4 pl-5">
                            <div className="add_new_quote payment_option">
                                <div className="quote_card">
                                    <div className="show_total border-0 pb-0 mb-0">
                                        <h3 className="total_label">Paid <small>{date.toLocaleDateString('default', { month: 'long' })} {date.getDate()}, {date.getFullYear()}</small></h3>
                                        <h3 className="total_status ml-auto text-green">$ {quote.total.toFixed(2)}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SuccessQuote;