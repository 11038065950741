import React from 'react';
import Resizer from 'react-image-file-resizer';

class StaffForm extends React.Component {
    constructor(props){
        super(props);
        
        if(props.staffData){
            const { picture, first_name, last_name, email, role } = props.staffData
            this.state = {
                first_name: first_name || '',
                last_name: last_name || '',
                email: email || '',
                password: '',
                role: role || '',
                picture: picture || '',
                showImg: true,
                imageName: '',
                image: null || '',
                classes: []
            };
        } else {
            this.state = {
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                role: '',
                picture: '',
                showImg: false,
                imageName: '',
                image: null,
                errors: {},
                classes: []
            };
        }
        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.handleAddStaff = this.handleAddStaff.bind(this);
    }

    onChange(e) {
        if (e.target.id === 'first_name') {
            this.setState({ first_name: e.target.value });
        } else if (e.target.id === 'last_name') {
            this.setState({ last_name: e.target.value });
        } else if (e.target.id === 'file-upload') {
            this.setState({ showImg: false });
            Resizer.imageFileResizer(
                e.target.files[0],
                300,
                300,
                'JPEG',
                100,
                0,
                uri => {
                    this.setState({ image: uri });
                },
                'blob'
            );
            this.setState({ imageName: e.target.files[0].name });
        } else if (e.target.id === 'staffEmail') {
            this.setState({ email: e.target.value });
        } else if (e.target.id === 'password') {
            this.setState({ password: e.target.value });
        } else if (e.target.id === 'role') {
            this.setState({ role: e.target.value});
        }
    }

    componentDidMount() {      
        let { first_name, last_name, email, password, role, image, imageName } = this.state;
        let classes = this.state.classes;
        if(first_name !== ''){
            classes['first_name'] = "active";
        }
        if(last_name !== ''){
            classes['last_name'] = "active";
        }
        if(email !== ''){
            classes['email'] = "active";
        }
        if(password !== ''){
            classes['password'] = "active";
        }
        if(role !== ''){
            classes['role'] = "active";
        }
        if(image !== ''){
            classes['image'] = "active";
        }
        if(imageName !== ''){
            classes['imageName'] = "active";
        }       
        
        this.setState({classes: classes});
    }

    onFocus(e) {
        let classes = this.state.classes;
        classes[e.target.id] = "active";
        this.setState({classes: classes});
    }

    onBlur(e){
        let classes = this.state.classes;
        if(e.target.value === ''){
            classes[e.target.id] = "";
        }
        else{
            classes[e.target.id] = "active";
        }
        this.setState({classes: classes});
        
    }

    handleValidation()
    {
        
        let { first_name, last_name, email, password, role, image, imageName } = this.state;
        let errors = {};
        let formIsValid = true;
        const { editId } = this.props;
        //Name
        if(!first_name){
            formIsValid = false;
            errors["first_name"] = "Cannot be empty";
        }
    
        if(typeof first_name !== "undefined"){
            if(!first_name.match(/^[a-z A-Z]+$/)){
                formIsValid = false;
                errors["first_name"] = "Only letters";
            }      	
        }

        if(!last_name){
            formIsValid = false;
            errors["last_name"] = "Cannot be empty";
        }
    
        if(typeof last_name !== "undefined"){
            if(!last_name.match(/^[a-z A-Z]+$/)){
                formIsValid = false;
                errors["last_name"] = "Only letters";
            }      	
        }

        //Email
        if(!email){
            formIsValid = false;
            errors["email"] = "Cannot be empty";
        }
    
        if(typeof email !== "undefined"){
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');
    
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "Email is not valid";
            }
        }

        //Password
        if(editId === null){
            if(!password){
                formIsValid = false;
                errors["password"] = "Cannot be empty";
            }

            if(typeof password !== "undefined"){
                if(!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/)){
                    formIsValid = false;
                    errors["password"] = "Please enter a strong password like Test#$@123";
                }      	
            }
        }
    
        //Role
        if(!role){
            formIsValid = false;
            errors["role"] = "Please select atleast one";
        }

        if(image){
            var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
            if(!allowedExtensions.exec(imageName)){
                formIsValid = false;
                errors["image"] = "Only .jpeg/.jpg/.png/.gif allowed";
            }
        }
    
        this.setState({errors: errors});
        return formIsValid;
    }

    // this method collects form data and calls parent method with staff data
    handleAddStaff(event) {
        event.preventDefault();
        const { onSubmit } = this.props;
        const { first_name, last_name, email, password, role, image } = this.state;
        const newStaffData = {
            first_name,
            last_name,
            email,
            password,
            role,
            image
        };
        if(this.handleValidation()){
            onSubmit(newStaffData);
        }
    }

    render() {
        const { onCancel } = this.props;
        return (
            <form className='row add_client' onSubmit={this.handleAddStaff}>     
                <div className='col col-sm-12 mb-4'>
                    <div className="upload_file_part">
                        <input type="file" onChange={this.onChange} id="file-upload" name="file" accept="image/*"/>
                        {this.state.errors && <span className="error">{this.state.errors["image"]}</span>}
                    </div>
                    {this.state.showImg &&
                    <img width="50px" src={process.env.API_URL+"Containers/staff/download/"+this.state.picture} alt=""/>}
                </div>                                   
                <div className='col col-sm-12'>
                    <div className={"form-group "+ [this.state.classes["first_name"] && this.state.classes["first_name"]]}>
                        <input id='first_name' onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} value={this.state.first_name} type="text" className="form-control"/>
                        {this.state.errors && <span className="error">{this.state.errors["first_name"]}</span>}
                        <label for="fname" className="form-control-placeholder">First Name</label>
                    </div>
                </div>
                <div className='col col-sm-12'>
                    <div className={"form-group "+this.state.classes["last_name"]}>
                        <input id='last_name' onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} value={this.state.last_name} type="text" className="form-control"/>
                        {this.state.errors && <span className="error">{this.state.errors["last_name"]}</span>}
                        <label for="lname" className="form-control-placeholder">Last Name</label>
                    </div>
                </div>
                <div className='col col-sm-12'>
                    <div className={"form-group "+this.state.classes["staffEmail"]}>
                        <input id='staffEmail' onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} value={this.state.email} type="text" className="form-control"/>
                        {this.state.errors && <span className="error">{this.state.errors["email"]}</span>}
                        <label for="email" className="form-control-placeholder">Email</label>
                    </div>
                </div>
                <div className='col col-sm-12'>
                    <div className={"form-group "+this.state.classes["password"]}>
                        <input id='password' onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} value={this.state.password} type="password" className="form-control"/>
                        {this.state.errors && <span className="error">{this.state.errors["password"]}</span>}
                        <label for="password" className="form-control-placeholder">Password</label>
                    </div>
                </div>
                <div className='col col-sm-12  mb-4'>
                    <div className="form-group">
                        <select id="role" ref="userType" className="form-control" onChange={this.onChange} value={this.state.role}>
                            <option value="hidden">Choose Role</option>
                            <option value="Admin">Admin</option>
                            <option value="Customer Service">Customer Service</option>
                        </select>
                        {this.state.errors && <span className="error">{this.state.errors["role"]}</span>}
                    </div>
                </div>
                <div className='col col-sm-12'>
                    <button type="submit" className="btn-main btn-green btn-sm">Save</button>
                    <button href="#" onClick={onCancel} className="btn-main link-btn">Cancel</button>
                </div>
            </form>
        );
    }
}

export default StaffForm;