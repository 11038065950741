import React, { Component } from 'react';
import { getLogList, getUniqueUpdateId } from '../../services/updateService';

import { deleteEquipment } from '../../services/equipmentService';
import { getUpdateTypes } from '../../services/updateTypeService';
import Log from './Log';
import "./home.scss";
import ReactLoading from "react-loading";
import TensionmeterForm from "./TensionmeterForm";
import { Form } from 'react-bootstrap';

class HomePage extends Component {

    constructor(props){
        super(props);

        this.state = {
            logs: [],
            serialNumber: '',
            newUpdateFrom: false,
            loader: true,
            addTensionmeter: false,
            tensionResponse: [],
            search: '',
            updateType: '',
            updateTypes: [],
            editBtn: false,
            curID: null,
            history: props.history,
        }
        this.onViewLog = this.onViewLog.bind(this);
        this.handleShow = this.handleShow.bind(this);
        
        
        
        this.handleSearch = this.handleSearch.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        
        getUpdateTypes({ params:{filter :{order: 'id asc'} } }).then(list => {
            this.setState({
                updateTypes: list
            }); 
        });
        this.getLogs();
    }

    handleClick(id){
        
        if(this.state.editBtn) {
            this.setState({
                editBtn: false,
                curID: null
            });
        }else{
            this.setState({
                editBtn: true,
                curID: id
            });
        }
	}

    getLogs(){
        this.setState({
            loader: true
        });
        const {search, updateType} = this.state;
        var updateParam = { params:{filter :{"search":search,"status":updateType} } };
        
        getUniqueUpdateId(updateParam).then( ids => {
            getLogList(ids).then(logs => {
                this.setState({
                    logs: logs,
                    loader: false
                }); 
            });
        });
    }

    handleSearch(event){
        event.preventDefault();
        this.getLogs();
    }

    onChange(e){
        if (e.target.id === 'search') {
            this.setState({ search: e.target.value });
        } else if (e.target.id === 'updateType') {
            this.setState({
                updateType: e.target.value
            }, () => {
                this.getLogs();
            });
            
        }
    }

    deleteTensionmeter(id){
        deleteEquipment(id)
        .then((response) => {
        
            this.setState(prevState => {
                let logs = prevState.logs.filter((post) => {
                    return id !== post.id;
                });
                return {
                    logs: logs,
                }
            });
        })
        .catch(function (error) {
            var err_str = [];
            var errors = Object.values(error.response.data.error.details.messages);
            errors.map((err) => 
                err_str.push(err[0])
            );
            this.setState({ hasError: err_str});
        });
    }

    // resetResponse(){
    //     this.setState({
    //         tensionResponse: []
    //     }); 
    // }

    onViewLog(serialNumber){
        this.setState({
            serialNumber: serialNumber
        }); 
        this.state.history.push('/log/'+serialNumber);
    }

    

    handleShow() {
        this.state.history.push('add-tensionmeter');
    }

    // handleHide() {
    //     this.setState(function () {
    //         return {
    //             addTensionmeter: false
    //         }
    //     })
    // }
    
    saveTensionmeter(data) {
        // newTensionmeter(data)
        // .then((response) => {
        //     this.state.tensionResponse.push(response)
        //     this.setState({
        //         tensionResponse: this.state.tensionResponse
        //     }); 
        // })
        // .catch((error) => {
        //     var err_str = [];
            
        //     if(typeof error.response.data.error.details !== 'undefined'){
        //         var errors = Object.values(error.response.data.error.details.messages);
        //         errors.map((err) => 
        //             err_str.push(err[0])
        //         );
        //     }
        //     else{
        //         err_str.push(error.response.data.error.message);
        //     }
            
        //     this.setState({ hasError: err_str});
        //     setTimeout(() => { this.setState({ hasError: ""}); }, 3000);
        // });
    }


    render() {
        var lists = this.state.logs;
        const {serialNumber, loader, addTensionmeter, tensionResponse, search, updateTypes, editBtn, curID} = this.state;
        return (
            <React.Fragment>
            {addTensionmeter === false ?
                [serialNumber === '' ?
                (<div className="opti_wrapper clients_page ">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="log_page_header">
                                    <div className="log_page_title">
                                        <h1 className="page_title">Tensiometer Log</h1>
                                        <button onClick={this.handleShow} className="btn-main">Add New Tensiometer <img src="./img/plus-icon-white.svg" alt="" height="14"/></button>
                                    </div>                                
                                    <div className="btn-and-search">
                                        {/* 
                                        <div className="dropdown">
                                            <p className="btn-main dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Calibration Cycle <img src="./img/chev-down-white.svg" alt="" height="7"/></p>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                <a className="dropdown-item" href="/">Action</a>
                                                <a className="dropdown-item" href="/">Another action</a>
                                                <a className="dropdown-item" href="/">Something else here</a>
                                            </div>
                                        </div>
                                        */}
                                        <div className="opti_search">
                                            <form onSubmit={this.handleSearch}>
                                            <img src="./img/search-icon.svg" alt="" height="16"/>
                                            <input type="text" id="search" name="search" onChange={this.onChange} value={search} className="form-control" placeholder="Serial Number, Client, Model..."/>
                                            <input type="submit" className="search_btn" value="Search"/>
                                            </form>
                                        </div>
                                        <div className="dropdown">
                                            <label>Filter by Status:</label>
                                                <Form.Control as="select" className="dropdown-menu-right" id="updateType" name="updateType" onChange={this.onChange} value={this.state.updateType}>
                                                <option value="">Choose Status</option>
                                                {updateTypes.map((type)=>
                                                    <option key={type.id} value={type.id}>{type.title}</option>)}
                                                </Form.Control>
                                                
                                            </div>                                        
                                    </div>
                                </div>

                                <div className="row crm_table">
                                    <div className="col-md-12 table">
                                        <div className="row head_row">
                                            <div className="col col-sm-1">
                                                <img src="./img/img-icon.svg" alt="" height="18"/>
                                            </div>
                                            <div className="col">Status</div>
                                            <div className="col">Serial</div>
                                            <div className="col">Customer</div>
                                            <div className="col">Model</div>
                                            <div className="col">Calibration Due</div>
                                            <div className="col">Action</div>
                                        </div>
                                        {loader && <ReactLoading className="m-auto" type="bubbles" color="#ca3c3c" />}
                                        {lists.length > 0 ?
                                        [lists.map((list)=>
                                        <div className="show_added_list" key={list.id}>
                                            <div className="row">
                                                <div className="col col-sm-1">
                                                {list.equipment && list.equipment.item.image !== '' ?
                                                    <img src={list.equipment.item.image} alt=""/> :
                                                    <img src="./img/log-img.png" alt=""/>
                                                }

                                                    
                                                </div>
                                                <div className="col">{list.updateType && list.updateType.title}</div>
                                                <div className="col"><span className="texdivred text-red">{list.equipment && list.equipment.serial_number}</span></div>
                                                <div className="col"><span className="text-red">{list.equipment && list.equipment.customer && list.equipment.customer.first_name} {list.equipment && list.equipment.customer && list.equipment.customer.last_name}</span></div>
                                                <div className="col">{list.equipment && list.equipment.item.item_name}</div>
                                                <div className="col">
                                                    {list.equipment && list.equipment.reminders.map((rem)=> 
                                                        <p key={rem.id}>{rem.reminderType.before_days === 0 && new Date(rem.sending_date).toDateString()}</p>
                                                    )}
                                                </div>
                                                <div className="col Status_dd">
                                                    <button onClick={() => this.onViewLog(list.equipment.serial_number)} className="btn-main secondary">View More</button>
                                                    <img src="./img/verticle-dots.svg" onClick={() => this.handleClick(list.equipment.id)} alt="" height="16" className="ml-2 toggle_dot"/>
                                                    {editBtn && curID === list.equipment.id &&
                                                    <ul> 
                                                    <li onClick={() => window.confirm('Are you sure you want to delete '+list.equipment.serial_number+' ?') ? this.deleteTensionmeter(list.equipment.id) : ''} className="text-red">Delete</li>
                                                    </ul>}
                                                </div>
                                            </div>
                                        </div>
                                        )]
                                        :
                                        <div className="show_added_list">
                                            <div className="row"><div className="col col-sm-12">No Log Found</div></div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>                
                    </div>
                </div>) : (
                    <Log 
                    serialNumber={serialNumber}
                    onAddClick={this.addUpdateForm}
                    showUpdateForm={this.state.newUpdateFrom}
                    onCancelClick={this.hideUpdateForm}></Log>
                )]
                :
                (<TensionmeterForm
                    handleHide={this.handleHide}
                    saveTensionmeter={this.saveTensionmeter}
                    tensionResponse={tensionResponse}
                    resetResponse={this.resetResponse}>
                    </TensionmeterForm>)
            }
            </React.Fragment>
        );
    }
}

export default HomePage;