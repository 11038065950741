import React from 'react';
import propTypes from 'prop-types';
import { getStaffList, addNewStaff, deleteStaff, updateStaff, uploadImage } from '../../services/staffService';
import StaffForm  from './StaffForm';
import StaffList from './StaffList';
import ErrorsList from "../../components/common/ErrorsList";

class StaffContainer extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            users: [],
            addStaff: false,
            staffIdToEdit: null,
            hasError: '',
            hasSuccess: '',
            staffImage: null,
            loader: true,
            editStaff:[]
        }
        
        this.handleShow = this.handleShow.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleEditCancel = this.handleEditCancel.bind(this);
    }

    componentDidMount() {
        getStaffList({ params:{filter :{order: 'id desc'} } }).then(staff => {
            this.setState({
                users: staff,
                loader: false
            }); 
        });
    }

    handleShow() {
        this.setState(function () {
            return {
                addStaff: true,
                staffIdToEdit: null,
                editStaff: []
            }
        })
    }

    handleHide() {
        this.setState(function () {
            return {
                addStaff: false
            }
        })
    }

    prepareStaffPayload(staffData) {
        
        const { first_name, last_name, email, password, role } = staffData
        let payloadData = {
            "first_name": first_name,
            "last_name": last_name,
            "email": email.trim(),
            "role": role
        };

        if(this.state.staffImage != null) {
            payloadData['picture'] = this.state.staffImage;
        }

        if(this.state.staffIdToEdit != null) {
            payloadData['id'] = this.state.staffIdToEdit;
        }
        if(password && password.trim().length > 0) {
            payloadData['password'] = password;
        }

        return payloadData;
    }

    handleSubmit(newStaffData) {

        const { image } = newStaffData;
        
        if(image !== null){

            this.handleUpload(image)
            .then(imgName => {
                const payload = this.prepareStaffPayload(newStaffData);
                this.setState({
                    staffImage: null
                });
                addNewStaff(payload)
                .then((response) => {
                    if(response.id > 0){
                        this.setState({ hasSuccess: "Staff Added"});
                        setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                    }
                    this.setState(prevState => {
                        return {
                            users: [response, ...prevState.users],
                        }
                    });
                    this.handleHide();
                })
                .catch((error) => {
                    var err_str = [];
                    var errors = Object.values(error.response.data.error.details.messages);
                    errors.map((err) => 
                        err_str.push(err[0])
                    );
                    this.setState({ hasError: err_str});
                    
                });
            })
        }
        else
        {
            const payload = this.prepareStaffPayload(newStaffData);
            addNewStaff(payload)
            .then((response) => {
                if(response.id > 0){
                    this.setState({ hasSuccess: "Staff Added"});
                    setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                }
                this.setState(prevState => {
                    return {
                        users: [response, ...prevState.users],
                    }
                });
                this.handleHide();
            })
            .catch((error) => {
                var err_str = [];
                var errors = Object.values(error.response.data.error.details.messages);
                errors.map((err) => 
                    err_str.push(err[0])
                );
                this.setState({ hasError: err_str});
                
            });
        }
         
    }

    handleDelete(data){
        deleteStaff(data.id)
        .then((response) => {
        
            this.setState(prevState => {
                let users = prevState.users.filter((post) => {
                    return data.id !== post.id;
                });
                return {
                    users: users,
                }
            });
        })
        .catch(function (error) {
            var err_str = [];
            var errors = Object.values(error.response.data.error.details.messages);
            errors.map((err) => 
                err_str.push(err[0])
            );
            this.setState({ hasError: err_str});
        });
    }

    handleEdit(user) {
        if (user && user.id){
            this.setState({
                staffIdToEdit: user.id,
                editStaff: user,
                addStaff: false
            });
        }
    }

    handleEditCancel() {
        this.setState({
            staffIdToEdit: null,
            editStaff: []
        });
    }

    handleUpdate(updateStaffData){
        const { image } = updateStaffData;
        if(image !== ''){
            this.handleUpload(image)
            .then(imgName => {
                const payload = this.prepareStaffPayload(updateStaffData);
                this.setState({
                    staffImage: null
                });
                updateStaff(payload)
                .then((response) => {
                    // update the user in list
                    if(response.id > 0){
                        this.setState({ hasSuccess: "Staff Updated"});
                        setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                    }
                    const staffList = [...this.state.users];
                    staffList.forEach(user => {
                        if(user.id === this.state.staffIdToEdit){
                            Object.assign(user, payload); // copy new values to user
                        }
                    });
                    
                    this.setState({
                        users: staffList
                    });
                    this.handleEditCancel();
                })
                .catch(function (error) {
                    var err_str = [];
                    var errors = Object.values(error.response.data.error.details.messages);
                    errors.map((err) => 
                        err_str.push(err[0])
                    );
                    this.setState({ hasError: err_str});
                });
            })
        }
        else
        {
            const payload = this.prepareStaffPayload(updateStaffData);
                updateStaff(payload)
                .then((response) => {
                    // update the user in list
                    if(response.id > 0){
                        this.setState({ hasSuccess: "Staff Updated"});
                        setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                    }
                    const staffList = [...this.state.users];
                    staffList.forEach(user => {
                        if(user.id === this.state.staffIdToEdit){
                            Object.assign(user, payload); // copy new values to user
                        }
                    });
                    
                    this.setState({
                        users: staffList
                    });
                    this.handleEditCancel();
                })
                .catch(function (error) {
                    var err_str = [];
                    var errors = Object.values(error.response.data.error.details.messages);
                    errors.map((err) => 
                        err_str.push(err[0])
                    );
                    this.setState({ hasError: err_str});
                });
        }
    }

    handleUpload(imgData){
    
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('picture',imgData,Date.now());
            
            uploadImage(formData)
            .then((response) => {
                if(response.result.files.picture[0]){
                    
                    this.setState({
                        staffImage: response.result.files.picture[0].name
                    });
                    return resolve(response.result.files.picture[0].name);
                }
                else{
                    return reject("Image Not Uploaded");
                }
            });  
        });
    }

    render() {
        const {addStaff, editStaff, users} = this.state;
        return (
            <React.Fragment>
            <div className="opti_wrapper clients_page">
                <div className="container-fluid" id="staffContainer">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="page_title">Staff</h1>
                            <div className="btn-and-search">
                                    <button onClick={this.handleShow} className="btn-main">Add New Staff <img src="./img/plus-icon-white.svg" alt="" height="14"/></button>
                                <div className="opti_search ml-auto d-none">
                                    <img src="./img/search-icon.svg" alt="" height="16"/>
                                    <input type="text" className="form-control" placeholder="Name, Email…"/>
                                    <input type="submit" className="search_btn" value="Search"/>
                                </div>
                            </div>
                            {this.state.hasError !== '' &&
                            <ErrorsList errors={this.state.hasError}></ErrorsList>}
                            {this.state.hasSuccess !== '' &&
                            <div className="alert alert-success alert-dismissible fade show mt-4" role="alert">
                                <strong>Success!</strong> {this.state.hasSuccess}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>}
                            <div className="crm_table">
                                
                                <StaffList 
                                    deleteStaff={this.handleDelete} 
                                    users={users}
                                    editId={this.state.staffIdToEdit}
                                    onEdit={this.handleEdit}
                                    onEditCancel={this.handleEditCancel}
                                    onEditSubmit={this.handleUpdate}
                                    loader={this.state.loader}>
                                </StaffList>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
            {addStaff && <div className="log_right_sidebar">
            <h2>Add New Staff</h2>
            <StaffForm 
                editId={this.state.staffIdToEdit}
                onSubmit={this.handleSubmit}
                onCancel={this.handleHide}>
            </StaffForm> </div>}
            {this.state.staffIdToEdit != null && <div className="log_right_sidebar">
                <h2>Edit Staff</h2>
                <StaffForm
                    editId={this.state.staffIdToEdit}
                    staffData={editStaff}
                    onSubmit={this.handleUpdate}
                    onCancel={this.handleEditCancel}>
                </StaffForm>
            </div>}
            </React.Fragment>
        )
    }
}

StaffForm.propTypes = {
    onSubmit: propTypes.func.isRequired,
	onCancel: propTypes.func.isRequired
}

StaffList.propTypes = {
	deleteStaff: propTypes.func.isRequired,
	users: propTypes.array.isRequired,
    editId: propTypes.number,
    onEdit: propTypes.func.isRequired,
    onEditCancel: propTypes.func.isRequired,
    onEditSubmit: propTypes.func.isRequired,
}

export default StaffContainer;