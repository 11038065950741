import React from 'react';
import {getCarrierList, addNewCarrier, deleteCarrier, updateCarrier} from '../../services/shippingCarrier';
import CarrierForm  from './CarrierForm';
import CarrierList from './CarrierList';
import ErrorsList from "../../components/common/ErrorsList";

class ShippingCarriers extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            carriers: [],
            addCarrier: false,
            carrierIdToEdit: null,
            hasError: '',
            hasSuccess: '',
            loader: true,
            editCarrier:[]
        }

        this.handleShow = this.handleShow.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleEditCancel = this.handleEditCancel.bind(this);
    }

    componentDidMount() {
        getCarrierList({ params:{filter :{order: 'id desc'} } }).then(carrier => {
            this.setState({
                carriers: carrier,
                loader: false
            }); 
        });
    }

    handleShow() {
        this.setState(function () {
            return {
                addCarrier: true,
                carrierIdToEdit: null,
                editCarrier: []
            }
        })
    }

    handleHide() {
        this.setState(function () {
            return {
                addCarrier: false
            }
        })
    }

    prepareCarrierPayload(carrierData) {
        
        const { carrier_name, cost, speed } = carrierData
        
        let payloadData = {
            "carrier_name": carrier_name,
            "cost": cost,
            "speed": speed
        };

        if(this.state.carrierIdToEdit != null) {
            payloadData['id'] = this.state.carrierIdToEdit;
        }
        
        return payloadData;
    }

    handleSubmit(newCarrierData) {
        const payload = this.prepareCarrierPayload(newCarrierData);
        
        addNewCarrier(payload)
        .then((response) => {
            if(response.id > 0){
                this.setState({ hasSuccess: "Carrier Added"});
                setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
            }
            this.setState(prevState => {
                return {
                    carriers: [response, ...prevState.carriers],
                }
            });
            this.handleHide();
        })
        .catch((error) => {
            var err_str = [];
            var errors = Object.values(error.response.data.error.details.messages);
            errors.map((err) => 
                err_str.push(err[0])
            );
            this.setState({ hasError: err_str});
            
        });
         
    }

    handleDelete(data){
        deleteCarrier(data.id)
        .then((response) => {
        
            this.setState(prevState => {
                let carriers = prevState.carriers.filter((post) => {
                    return data.id !== post.id;
                });
                return {
                    carriers: carriers,
                }
            });
        })
        .catch(function (error) {
            var err_str = [];
            var errors = Object.values(error.response.data.error.details.messages);
            errors.map((err) => 
                err_str.push(err[0])
            );
            this.setState({ hasError: err_str});
        });
    }

    handleEdit(carrier) {
        if (carrier && carrier.id){
            this.setState({
                carrierIdToEdit: carrier.id,
                editCarrier: carrier,
                addCarrier: false
            });
        }
    }

    handleEditCancel() {
        this.setState({
            carrierIdToEdit: null,
            editCarrier: []
        });
    }

    handleUpdate(updateCarrierData){
        const payload = this.prepareCarrierPayload(updateCarrierData);
        updateCarrier(payload)
        .then((response) => {
            // update the carrier in list
            if(response.id > 0){
                this.setState({ hasSuccess: "Carrier Updated"});
                setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
            }
            const carrierList = [...this.state.carriers];
            carrierList.forEach(carrier => {
                if(carrier.id === this.state.carrierIdToEdit){
                    Object.assign(carrier, payload); // copy new values to carrier
                }
            });
            
            this.setState({
                carriers: carrierList
            });
            this.handleEditCancel();
        })
        .catch((error) => {
            var err_str = [];
            
            if(typeof error.response.data.error.details !== 'undefined'){
                var errors = Object.values(error.response.data.error.details.messages);
                errors.map((err) => 
                    err_str.push(err[0])
                );
            }
            else{
                err_str.push(error.response.data.error.message);
            }
            
            this.setState({ hasError: err_str});
            setTimeout(() => { this.setState({ hasError: ""}); }, 3000);
        });
    }

    render() {
        const {addCarrier, editCarrier, carriers} = this.state;
        return (
            <React.Fragment>
            <div className="opti_wrapper clients_page">
                <div className="container-fluid" id="carrierContainer">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="page_title">Carrier</h1>
                            <div className="btn-and-search">
                                    <button onClick={this.handleShow} className="btn-main">Add New Carrier <img src="./img/plus-icon-white.svg" alt="" height="14"/></button>
                                <div className="opti_search ml-auto d-none">
                                    <img src="./img/search-icon.svg" alt="" height="16"/>
                                    <input type="text" className="form-control" placeholder="Name, Email…"/>
                                    <input type="submit" className="search_btn" value="Search"/>
                                </div>
                            </div>
                            {this.state.hasError !== '' &&
                            <ErrorsList errors={this.state.hasError}></ErrorsList>}
                            {this.state.hasSuccess !== '' &&
                            <div className="alert alert-success alert-dismissible fade show mt-4" role="alert">
                                <strong>Success!</strong> {this.state.hasSuccess}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>}
                            <div className="crm_table">
                                
                                <CarrierList 
                                    deleteCarrier={this.handleDelete} 
                                    carriers={carriers}
                                    editId={this.state.carrierIdToEdit}
                                    onEdit={this.handleEdit}
                                    onEditCancel={this.handleEditCancel}
                                    onEditSubmit={this.handleUpdate}
                                    loader={this.state.loader}>
                                </CarrierList>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
            {addCarrier && <div className="log_right_sidebar">
            <h2>Add New Carrier</h2>
            <CarrierForm 
                editId={this.state.carrierIdToEdit}
                onSubmit={this.handleSubmit}
                onCancel={this.handleHide}>
            </CarrierForm> </div>}
            {this.state.carrierIdToEdit != null && <div className="log_right_sidebar">
                <h2>Edit Carrier</h2>
                <CarrierForm
                    editId={this.state.carrierIdToEdit}
                    carrierData={editCarrier}
                    onSubmit={this.handleUpdate}
                    onCancel={this.handleEditCancel}>
                </CarrierForm>
            </div>}
            </React.Fragment>
        )
    }
}

export default ShippingCarriers;