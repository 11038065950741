import React from 'react';
import { Link } from "react-router-dom";

import { forgetPassword } from '../../services/accountService';
import "./password-reset-request.scss";

export class Passwordresetrequest extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            email: '',
            hasError: '',
            hasSuccess: ''
        }
        this.passwordResetSubmit = this.passwordResetSubmit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    handleOnChange(e){
        if (e.target.id === 'email') {
            this.setState({ email: e.target.value });
        }
    }

    passwordResetSubmit(event){
        event.preventDefault();
        if(this.handleValidation()){
            // onSubmit(resetData);
            const payload = this.prepareResetPayload(this.state);
            forgetPassword(payload)
            .then((response) => {
                if(response === 'Email Sent'){
                    this.setState({ hasSuccess: "Reset link sent on your email. Please check."});    
                    this.setState({ email: '' });
                }else{
                    this.setState({ hasError: "Email not found in our records."});    
                }
            })
            .catch((error) => {
                this.setState({ hasError: error.response.data.error.message});
            });
            setTimeout(() => { this.setState({ hasError: ""}); }, 3000);
        }
    }

    prepareResetPayload(resetData){
        const { email } = resetData;

        let payloadData = {
            "email": email
        };

        return payloadData;
    }

    handleValidation()
    {
        
        let { email } = this.state;
        let errors = {};
        let formIsValid = true;
        
        //Password
        if(!email){
            formIsValid = false;
            errors["email"] = "Cannot be empty";
        }

        this.setState({errors: errors});
        return formIsValid;
    }

    render() {
        return (
            <div className="userModule">
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-center">
                        <div className="col-md-6">
                            <div className="card">                            
                                <div className="card-body">
                                    <div className="logo"><img src="./img/logo.svg" alt="" height="38px;"/></div>
                                    <h1>Let's find your account</h1>
                                    {this.state.hasError !== '' &&
                                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                            <strong>Error!</strong> {this.state.hasError}
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        </div>}
                                    {this.state.hasSuccess !== '' &&
                                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                                            <strong>Success!</strong> {this.state.hasSuccess}
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        </div>}
                                    <form onSubmit={this.passwordResetSubmit}>
                                        <div className="form-group">
                                            <label>Email Address</label>
                                            <input type="email" id="email" class="form-control" name="email" onChange={this.handleOnChange} value={this.state.email} placeholder="Email"/>
                                            {this.state.errors && <span className="error">{this.state.errors["email"]}</span>}
                                        </div>                                
                                        <div className="form-group mt-5 d-flex">                                    
                                            <input type="submit" class="btn-main"  value="Reset Link"/>
                                            <Link className="form_link" to="/login">back to Login</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Passwordresetrequest;