import React from 'react';
import DatePicker from "react-datepicker";
import { Form } from 'react-bootstrap';
import {getCarrierList} from '../../services/shippingCarrier';
import "react-datepicker/dist/react-datepicker.css";

class LogForm extends React.Component {
    constructor(props){
        super(props);
        if(props.formState)
        {
            const {formState} = props;
            var note = '';
            var update_id = '';
            var date = '';
            if(formState.unitRequested){
                note = formState.unitRequested.staff_note;
                update_id = formState.unitRequested.id;
                date = formState.unitRequested.date;
            }
            if(formState.received){
                note = formState.received.staff_note;
                update_id = formState.received.id;
                date = formState.received.date;
            }
            if(formState.servicing){
                note = formState.servicing.staff_note;
                update_id = formState.servicing.id;
                date = formState.servicing.date;
            }
            if(formState.decommission){
                note = formState.decommission.staff_note;
                update_id = formState.decommission.id;
                date = formState.decommission.date;
            }
            if(formState.sold){
                note = formState.sold.staff_note;
                update_id = formState.sold.id;
                date = formState.sold.date;
            }
            if(formState.shipped){
                note = formState.shipped.staff_note;
                update_id = formState.shipped.id;
                
                this.state = {
                    equipment_id: props.equipment_id || null,
                    staff_note: note || '',
                    prev_update_type_id: formState.update_type_id || '',
                    update_type_id: formState.update_type_id || '',
                    update_id: update_id || null,
                    parts: [{ part_id: "", quantity: 1, unit_cost: 0, sub_total:0 }],
                    cost: null,
                    grand_total: null,
                    details: '',
                    shipping_carrier_id: formState.shipped.shipping_carrier_id || '',
                    tracking_number: formState.shipped.tracking_number || '',
                    shipping_cost: 0,
                    service_covered: 0,
                    shipping_cost_covered: 0,
                    pdf: '',
                    invoice: {},
                    errors: {},
                    carriers: [],
                    date: new Date(formState.shipped.date) || ''
                }
            }
            else
            {
                this.state = {
                    equipment_id: props.equipment_id || null,
                    staff_note: note || '',
                    prev_update_type_id: formState.update_type_id || '',
                    update_type_id: formState.update_type_id || '',
                    update_id: update_id || null,
                    parts: [{ part_id: "", quantity: 1, unit_cost: 0, sub_total:0 }],
                    cost: null,
                    grand_total: null,
                    details: '',
                    shipping_carrier_id: '',
                    tracking_number: '',
                    shipping_cost: 0,
                    service_covered: 0,
                    shipping_cost_covered: 0,
                    pdf: '',
                    invoice: {},
                    errors: {},
                    carriers: [],
                    date: new Date(date)
                }
            }
                
            
        }
        else
        {
            this.state = {
                equipment_id: null,
                staff_note: '',
                prev_update_type_id: null,
                update_type_id: '',
                update_id: null,
                parts: [{ part_id: "", quantity: 1, unit_cost: 0, sub_total:0 }],
                cost: null,
                grand_total: null,
                details: '',
                shipping_carrier_id: '',
                shipping_cost: 0,
                service_covered: 0,
                shipping_cost_covered: 0,
                pdf: '',
                invoice: {},
                errors: {},
                carriers: [],
                date: new Date()
            }
        }
        this.handleAddLog = this.handleAddLog.bind(this);
        this.onChange = this.onChange.bind(this);
        
    }

    onChange(e) {
        
        if (e.target.id === 'staff_note') {
            this.setState({ staff_note: e.target.value });
        } else if (e.target.id === 'update_type_id') {
            this.setState({ update_type_id: e.target.value });
        }
        else if(e.target.id === 'details'){
            this.setState({details: e.target.value });
        }else if(e.target.id === 'shipping_carrier_id'){
            this.setState({shipping_carrier_id: e.target.value });
            this.setState({shipping_cost: 50 });
        }
        else if(e.target.id === 'shipping_cost'){
            this.setState({shipping_cost: e.target.value });
        }
        else if(e.target.id === 'tracking_number'){
            this.setState({tracking_number: e.target.value });
        }
        else if(e.target.name === 'service_covered'){
            this.setState({service_covered: e.target.value });
        }
        else if(e.target.name === 'shipping_cost_covered'){
            this.setState({shipping_cost_covered: e.target.value });
        }
        else if (e.target.id === 'file-upload') {
            this.setState({ pdf: e.target.files[0] });
        }
    }

    componentDidMount() {
        getCarrierList({ params:{filter :{order: 'id asc'} } }).then(carrier => {
            this.setState({
                carriers: carrier
            }); 
        });
    }

    handlePartChange = idx => evt => {
        const newParts = this.state.parts.map((part, sidx) => {
            if (idx !== sidx) return part;
            if(evt.target.id === 'part_id'){
                this.setState({ cost: 0 });
                var index = evt.target.selectedIndex;
                var optionElement = evt.target.childNodes[index]
                var cost =  optionElement.getAttribute('cost');
                this.setState({ cost: cost });
                return { ...part, part_id: evt.target.value, quantity: 1, unit_cost: cost, sub_total: parseFloat(cost) };
            }else if(evt.target.id === 'quantity'){
                return { ...part, quantity: evt.target.value, sub_total: parseFloat(this.state.cost)*parseFloat(evt.target.value)};
            }
            return part;  
        });
    
        this.setState({ parts: newParts });
        
    };

    handleAddPart = () => {
        this.setState({
          parts: this.state.parts.concat([{ part_id: "", quantity: "", unit_cost: "", sub_total:"" }])
        });
    };

    handleRemovePart = idx => () => {
        this.setState({
          parts: this.state.parts.filter((s, sidx) => idx !== sidx)
        });
    };

    handleValidation()
    {
        
        let { update_type_id } = this.state;
        let errors = {};
        let formIsValid = true;
        // const { editId } = this.props;
        //Name
        if(!update_type_id){
            formIsValid = false;
            errors["update_type_id"] = "Cannot be empty";
        }

        
        
        this.setState({errors: errors});
        return formIsValid;
    }

    handleDate = date => {
        this.setState({
            date: new Date(date)
        });
    };

    

    handleAddLog(event) {
        event.preventDefault();
        const { equipment_id, update_type_id, staff_note, update_id, prev_update_type_id, shipping_carrier_id, tracking_number, date } = this.state;
        const { onHandleSubmit, editId } = this.props;
        var payloadData = [];
        
        if(update_type_id === '1' || parseInt(update_type_id) === 1){
            payloadData = {
                "equipment_id": equipment_id,
                "update_type_id": update_type_id,
                "user_id": localStorage.getItem('user_id'),
                "staff_note": staff_note,
                "shipping_carrier_id": shipping_carrier_id,
                "tracking_number": tracking_number,
                "id": update_id,
                "update_id": editId,
                "prev_update_type_id": prev_update_type_id,
                "date": date
            };
        }
        else
        {
            payloadData = {
                "equipment_id": equipment_id,
                "update_type_id": update_type_id,
                "user_id": localStorage.getItem('user_id'),
                "staff_note": staff_note,
                "id": update_id,
                "update_id": editId,
                "prev_update_type_id": prev_update_type_id,
                "date": date
            };
        }
            
        
        if(this.handleValidation()){
            onHandleSubmit(payloadData);
        }
        
        
    }

    render() {
        const { updateTypes, onCancelClick, editId } = this.props;
        const { carriers } = this.state;
        return (
            <form onSubmit={this.handleAddLog} encType="multipart/form-data">
                <div className="accordion">
                    <div className="card">
                        <div className="card-header d-flex" id="headingOne">
                            <p className="my-auto">{editId > 0 ? 'Edit Update' : 'Add Update'}</p>
                            <h2 className="mb-0 ml-auto">
                                <div className="dropdown">
                                    <Form.Control as="select" className="dropdown-menu-right" id="update_type_id" onChange={this.onChange} value={this.state.update_type_id}>
                                    <option value="">Choose</option>
                                    {updateTypes.map((type)=>
                                        <option key={type.id} value={type.id}>{type.title}</option>)}
                                    </Form.Control>
                                    {this.state.errors && <span className="error small">{this.state.errors["update_type_id"]}</span>}
                                </div>                                 
                            </h2>
                        </div>                                    
                        <div className="card-body">
                        {this.state.update_type_id === '1' || parseInt(this.state.update_type_id) === 1 ? 
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-6 pl-4">
                                            <label>Shipped Date: </label>
                                            {editId > 0 ?
                                            <DatePicker id="date" dateFormat="yyyy/MM/dd" selected={this.state.date} onChange={this.handleDate} autoComplete="off" className="form-control"/>
                                            :
                                            <DatePicker id="date" dateFormat="yyyy/MM/dd" selected={this.state.date} onChange={this.handleDate} autoComplete="off" className="form-control"/>}
                                            <label>Staff Notes (Optional): </label>
                                            <textarea className="form-control" id="staff_note" onChange={this.onChange} value={this.state.staff_note}></textarea>
                                        </div>
                                        <div className="col-5 pl-4">
                                            <label>Shipping Carrier</label>
                                            <select id="shipping_carrier_id" ref="shipping_carrier_id" className="form-control mb-3" onChange={this.onChange} value={this.state.shipping_carrier_id}>
                                                <option value="">Choose Carrier</option>
                                                {carriers.map((carrier)=>
                                                    <option key={carrier.id} value={carrier.id}>{carrier.carrier_name} ({carrier.speed})</option>
                                                )}
                                            </select>
                                            <label>Tracking Number</label>
                                            <div className="tracking_number">
                                                <input type="text" className="form-control text-red" id="tracking_number" value={this.state.tracking_number} onChange={this.onChange}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>   
                                :
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-6">
                                            <label>Date: </label>
                                            {editId > 0 ?
                                            <DatePicker id="date" dateFormat="yyyy/MM/dd" selected={this.state.date} onChange={this.handleDate} autoComplete="off" className="form-control"/>
                                            :
                                            <DatePicker id="date" dateFormat="yyyy/MM/dd" selected={this.state.date} onChange={this.handleDate} autoComplete="off" className="form-control"/>}
                                        </div>
                                        <div className="col-6">
                                            <label>Staff Notes (Optional): </label>
                                            <textarea className="form-control" id="staff_note" onChange={this.onChange} value={this.state.staff_note}></textarea>
                                        </div>
                                    </div>
                                </div>
                        }
                            <div className="form_button">
                                <button className="btn-main btn-sm">Save</button>
                                <button onClick={onCancelClick} className="btn-main link-btn update_terminate">Cancel</button>
                                <button className="btn-main btn-sm ml-auto disabled">Send Notification</button>
                            </div>
                        </div>
                    </div>                                
                </div>
            </form>
        );
    }    
}

export default LogForm;