import React from 'react';

class Sidebar extends React.Component {

    render() {
        const {serialNumber, purchaseDate, itemName, itemImage, clientName, company, email, phone, reminder} = this.props;
        var calibrationDate = '';
        var Status = '';
        var todayDate = new Date();
        reminder.map((rem)=> 
            {rem.reminderType.before_days === 0 && (calibrationDate = new Date(rem.sending_date)); return true;}
        );
        if(todayDate > calibrationDate)
        {
            Status = 'Expired';
        }
        else
        {
            Status = 'Active';
        }
        return (
            <ul>
                <li className="row d-flex align-items-center">
                    <div className="product_series col">
                        <h3>{serialNumber}</h3>
                        <p>{itemName}</p>
                    </div>
                    <div className="product_img col text-right">
                        {itemImage && itemImage !== '' ?
                            <img src={itemImage} alt=""/> :
                            <img src="./img/product.png" alt=""/>
                        }
                    </div>
                </li>
                <li className="row">
                    <div className="col text-offtext">Purchase Date</div>
                    <div className="col text-right">{new Date(purchaseDate).toLocaleDateString('default', { month: 'long' })} {new Date(purchaseDate).getDate()}, {new Date(purchaseDate).getFullYear()}</div>
                </li>
                <li className="row">
                    <div className="col text-offtext">Warranty Status</div>
                    <div className="col text-right text-red">{Status}</div>
                </li>
                <li className="row">
                    <div className="col text-offtext">Client</div>
                    <div className="col text-right">{clientName}</div>
                </li>
                <li className="row">
                    <div className="col text-offtext">Company</div>
                    <div className="col text-right">{company}</div>
                </li>
                <li className="row">
                    <div className="col text-offtext">Email</div>
                    <div className="col text-right">{email}</div>
                </li>
                <li className="row">
                    <div className="col text-offtext">Telephone</div>
                    <div className="col text-right">{phone}</div>
                </li>
                <li className="row">
                    <div className="col text-offtext">Calibration Due:</div>
                    <div className="col text-right">{calibrationDate && new Date(calibrationDate).toLocaleDateString('default', { month: 'long' })} {new Date(calibrationDate).getDate()}, {new Date(calibrationDate).getFullYear()}</div>
                </li>
            </ul>
        )
    }
}

export default Sidebar;