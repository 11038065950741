import React from 'react';
import { getUserbyToken, passwordReset } from '../../services/accountService';
import "./reset-password.scss";


export class Resetpassword extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            user: null,
            remember_token: '',
            password: '',
            confirmPassword: null,
            hasError: ''
        }
        this.passwordResetSubmit = this.passwordResetSubmit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    componentDidMount(){
        const {token} = this.props.match.params;
        getUserbyToken(token).then(user=> {
            this.setState({
                user: user,
                remember_token: token
            }); 
        });
    }

    handleOnChange(e){
        if (e.target.id === 'password') {
            this.setState({ password: e.target.value });
        } else if (e.target.id === 'confirmPassword') {
            this.setState({ confirmPassword: e.target.value });
        }
    }

    passwordResetSubmit(event){
        event.preventDefault();
        if(this.handleValidation()){
            // onSubmit(resetData);
            const payload = this.prepareResetPayload(this.state);
            passwordReset(payload)
            .then((response) => {
                if(response.affectedRows > 0){
                    window.location.href = '/#/login?reset=true';
                }else{
                    this.setState({ hasError: "Something went wrong please try again later."});    
                }
            })
            .catch((error) => {
                this.setState({ hasError: error.response.data.error.message});
            });
            setTimeout(() => { this.setState({ hasError: ""}); }, 3000);
        }
    }

    prepareResetPayload(resetData){
        const { confirmPassword, remember_token  } = resetData;

        let payloadData = {
            "token": remember_token,
            "password": confirmPassword
        };

        return payloadData;
    }

    handleValidation()
    {
        
        let { password, confirmPassword } = this.state;
        let errors = {};
        let formIsValid = true;
        
        //Password
        if(!password){
            formIsValid = false;
            errors["password"] = "Cannot be empty";
        }

        //Confirm Password
        if(!confirmPassword){
            formIsValid = false;
            errors["confirmPassword"] = "Cannot be empty";
        }else{
            if(password !== confirmPassword){
                formIsValid = false;
                errors["confirmPassword"] = "Confirm password does not match with password.";
            }
        }
        
        this.setState({errors: errors});
        return formIsValid;
    }

    render() {
        const { user } = this.state;
        return (
            <React.Fragment>
            {user !== null && user.length > 0 ? 
            <div className="userModule">
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-center">
                        <div className="col-md-6">
                            <div className="card">                            
                                <div className="card-body">
                                <div className="logo"><img src="./img/logo.svg" alt="" height="38px;"/></div>
                                <h1>Reset Password</h1>
                                {this.state.hasError !== '' &&
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    <strong>Error!</strong> {this.state.hasError}
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                </div>}
                                <form onSubmit={this.passwordResetSubmit}>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="password" id="password" onChange={this.handleOnChange} value={this.state.password} class="form-control" name="password" placeholder="Password"/>
                                        {this.state.errors && <span className="error">{this.state.errors["password"]}</span>}
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm Password</label>
                                        <input type="password" id="confirmPassword" onChange={this.handleOnChange} value={this.state.confirmPassword} class="form-control" name="cpassword"  placeholder="Confirm Password"/>
                                        {this.state.errors && <span className="error">{this.state.errors["confirmPassword"]}</span>}
                                    </div>                                
                                    <div className="form-group mt-5 d-flex">                                    
                                        <input type="submit" class="btn-main btn-block"  value="Reset Password"/>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : <h1>Invalid Link</h1>}
            </React.Fragment>
        );
    }
}

export default Resetpassword;