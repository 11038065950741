import React from 'react';
import Select from 'react-select';
import { getCompanySelect } from '../../services/clientService';

export class CompanyList extends React.Component {

    constructor(props){
        super(props);
        this.state = {
			companyOptions:[]
		}
	}

	componentDidMount() {
        getCompanySelect().then(company => {
            this.setState({
                companyOptions: company,
			}); 
			
		});
    }

    render(){
        const {onChangeFunc, selectedValue} = this.props;
        const {companyOptions} = this.state;

        return (
            <div className="form-group">
                <label>Company</label>
                <Select
                value={selectedValue}
                onChange={onChangeFunc}
                options={companyOptions}
                defaultValue={selectedValue}
                placeholder={<div>Type to search</div>}
                isClearable={true}
                />
            </div>
        );
    }

}

export default CompanyList;