import React from 'react';

const PaymentHeader = () => {
    var style = { "width": "36%" };
    return (
        <header>
            <div className="header">
                <div className="container-fluid h-100">
                    <div className="row h-100 align-items-center">
                         <div className="col-md-3">
                            <a href className="logo">
                                <img src="./img/logo.svg" alt="" height="38px;" style={style}/>
                            </a>
                        </div> 
                    </div>
                </div>
            </div>
        </header>
        
    );
}

export default PaymentHeader;