import React from 'react';
import ReactLoading from "react-loading";

class ServiceRequestList extends React.Component {
    
    render(){
        const { 
            requests,
            onView,
            loader
        } = this.props;
        
        return(
            <div className="table">
                <div className="row head_row">
                    <div className="col">
                        Service Type
                    </div>
                    <div className="col col-sm-3">Customer / Company</div>
                    <div className="col">Serial</div>
                    <div className="col">Response Posted</div>
                    <div className="col">Log Status</div>
                    <div className="col">Action</div>
                </div>
                {loader && <ReactLoading className="m-auto" type="bubbles" color="#ca3c3c" />}
                {requests.map((request)=> 
                    <div className="show_added_list" key={request.id}>
                        <div className="row" id={'row_data_'+request.id} key={request.id}>
                            <div className="col">{request.service_requested}</div>
                            <div className="col col-sm-3"><h4>{request.name}</h4><span className="client_company">{request.company}</span></div>
                            <div className="col"><span className="">{request.serial_number}</span></div>
                            <div className="col"><span className="">
                            {new Date(request.created_at).toLocaleDateString('default', { month: 'long' })+' '+new Date(request.created_at).getDate()+', '+new Date(request.created_at).getFullYear()}
                            </span></div>
                            <div className="col"><span className="text-red">{request.status}</span></div>
                            <div className="col">
                                <button className="btn-main secondary" onClick={() => onView(request)}>View More</button>
                            </div>
                        </div>
                    </div>
                )}
                {Object.keys(requests).length === 0 &&
                    <div className="show_added_list">
                        <div className="row"><div className="col col-sm-12">Service request not found</div></div>
                    </div>
                }
            </div>
        );
    }
}

export default ServiceRequestList;