import React from 'react';
import { InvoiceList } from "./InvoiceList";
import { InvoiceSingle } from "./InvoiceSingle";
import { getInvoiceList, getInvoiceTotal, updateInvoice } from '../../services/invoiceService';
require('dotenv').load();
export class invoiceContainer extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            invoices: [],
            hasError: '',
            hasSuccess: '',
            loader: true,
            InvoicesTotal: 0,
            currentPage: 1,
            perPage: 5,
            skip: 0,
            search: '',
            editInvoice: false,
            editInvoiceData: [],
            history: props.history,
        }

        // this.handleShow = this.handleShow.bind(this);
        // this.handleHide = this.handleHide.bind(this);
        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        // this.handleCancel = this.handleCancel.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const {search} = this.state;
        getInvoiceTotal(search).then(total=> {
            this.setState({
                InvoicesTotal: total.count
            }); 
        })
        this.getInvoices();
    }

    next() {
        const {currentPage, perPage} = this.state;
        this.setState({
            loader: true,
            skip: perPage*currentPage,
            currentPage: currentPage+1
        }, () => {this.getInvoices();}); 
        
    }

    prev() {
        const {currentPage, perPage, skip} = this.state;
        this.setState({
            loader: true,
            skip: skip-perPage,
            currentPage: currentPage-1
        }, () => {this.getInvoices();}); 
    }

    getTotalPagesCount(){
        const {InvoicesTotal, perPage} = this.state;
        return Math.ceil(InvoicesTotal / perPage);
    }

    handleEdit(invoiceID) {
        this.state.history.push('/invoice/'+invoiceID);
    }

    
    getInvoices(){
        const {perPage, skip, search} = this.state;
        
        getInvoiceList(perPage,skip,search).then(invoice => {
            this.setState({
                invoices: invoice,
                loader: false
            }); 
        });
    }

    onChange(e){
        this.setState({ search: e.target.value });
    }

    handleSearch(event){
        event.preventDefault();
        this.setState({
            loader: true
        }); 
        this.getInvoices();
    }

    updateStatus(data){
        updateInvoice(data)
            .then((response) => {
                if(response.id > 0){
                    this.setState({ hasSuccess: "Invoice Status Updated",editInvoice: false,loader: true});
                    setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                    this.getInvoices();
                }
                
            })
            .catch((error) => {
                var err_str = [];
                
                if(typeof error.response.data.error.details !== 'undefined'){
                    var errors = Object.values(error.response.data.error.details.messages);
                    errors.map((err) => 
                        err_str.push(err[0])
                    );
                }
                else{
                    err_str.push(error.response.data.error.message);
                }
                
                this.setState({ hasError: err_str});
                setTimeout(() => { this.setState({ hasError: ""}); }, 3000);
            });
    }

    render(){
        const {invoices, loader, currentPage, InvoicesTotal, perPage, editInvoice, editInvoiceData, search} = this.state;
        var TotalPages = this.getTotalPagesCount();
        
        return(
            <React.Fragment>
            {editInvoice ?
                <InvoiceSingle
                invoice={editInvoiceData}
                cancel={this.handleCancel}
                submit={this.updateStatus}>
                </InvoiceSingle>
                :
                <div className="opti_wrapper clients_page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="page_title">Invoices</h1>
                            <div className="btn-and-search">
                                <div className="opti_search ml-auto">
                                    <form onSubmit={this.handleSearch}>
                                    <img src="./img/search-icon.svg" alt="" height="16"/>
                                    <input type="text" onChange={this.onChange} className="form-control" value={search} placeholder="Client Name"/>
                                    <input type="submit" className="search_btn" value="Search"/>
                                    </form>
                                </div>
                            </div>
                            <InvoiceList
                            invoices={invoices}
                            onEdit={this.handleEdit}
                            loader={loader}>
                            </InvoiceList>                       
                            {InvoicesTotal > perPage && search === '' &&
                            [currentPage !== 1 &&
                                <button type="button" onClick={this.prev} class="btn-main btn-sm">Prev</button>]
                            }
                            {InvoicesTotal > perPage && search === '' &&
                            [TotalPages !== currentPage &&
                                <button type="button" onClick={this.next} class="btn-main btn-sm">Next</button>]
                            }
                        </div>
                        
                    </div>                
                </div>
            </div>
            }
            </React.Fragment>
        );
    }
}
export default invoiceContainer;