import React from 'react';
import invoiceStatuses from "../invoice/invoiceStatuses";
import axios from 'axios';
import jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';
import Image from './Image';

export class SuccessReturn extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            series: []
        }
        this.printDocument = this.printDocument.bind(this);
        window.html2canvas = html2canvas;
        
    }

    componentDidMount(){
        axios.get('https://optimanufacturing.com/wp-json/wp/v2/get-series').then((response) => {
            this.setState({
                series: response && response.data
            }); 
         });
        
    }

    printDocument() {
        const {quote, invoice} = this.props;
        const date = new Date(invoice.created_at);
        var doc = new jsPDF('p','pt','a4');  
        
        doc.addImage(Image.logo,'png',10,20,175,29);
        doc.setFont("helvetica");
        
        doc.setFontSize(8);
        doc.setTextColor(255, 0, 0);
        doc.text(540, 100, 'Total: $'+invoice.total.toFixed(2));
        doc.text(540, 115, 'Invoice: #'+invoice.id);
        
        doc.setTextColor(150);
        doc.text(20, 100, 'Date:');
        doc.text(20, 115, 'To:');
        
        doc.setFontSize(6);
        doc.setTextColor(50);
        doc.text(45, 100, date.toLocaleDateString('default', { month: 'long' })+' '+date.getDate()+', '+date.getFullYear());
        doc.text(45, 115, quote.quoteEquipments[0].equipment.customer.first_name+' '+quote.quoteEquipments[0].equipment.customer.last_name);
        doc.text(45, 122, quote.quoteEquipments[0].equipment.customer.company);
        doc.text(45, 129, quote.quoteEquipments[0].equipment.customer.email);

        doc.setFontSize(10);
        doc.setFontStyle("bold");
        doc.text(20, 150, 'Quoted Unit(s)');
        doc.setDrawColor(200);
        doc.line(20, 156, 550, 154);
        for (const eq of quote.quoteEquipments) {
            doc.rect(20, 162, 530, 40); 
            doc.setFontStyle("normal");
            doc.setFontSize(8);
            doc.text(30, 185, 'Image' );
            doc.text(80, 185, eq.equipment.serial_number );
            doc.text(130, 185, eq.equipment.item.item_name );
            doc.text(230, 185, eq.equipment.customer.first_name+' '+eq.equipment.customer.last_name );
            doc.text(330, 185, eq.equipment.customer.company );
        }
        doc.setFontSize(10);
        doc.setFontStyle("bold");
        doc.text(20, 230, 'Parts');
        doc.setDrawColor(200);
        doc.line(20, 236, 550, 236);
        for (const part of quote.quoteParts) {
            doc.rect(20, 242, 530, 20); 
            doc.setFontSize(6);
            doc.text(30, 254, part.part.part_name);
            doc.text(500, 254, 'Q.');
            doc.text(507, 254, part.quantity.toString());
            doc.text(520, 254, '$'+part.unit_cost.toFixed(2));
        }
        doc.setFontStyle("bold");
        doc.text(20, 280, 'Are the parts required for service covered by the warranty?');
        doc.text(530, 280, quote.service_covered ? 'Yes' : 'No');
        
        doc.text(20, 300, 'Details: ');
        doc.text(30, 310, quote.details);

        doc.setFontSize(10);
        doc.setFontStyle("bold");
        doc.text(20, 330, 'Shipping');
        doc.setDrawColor(200);
        doc.line(20, 336, 550, 336);
        doc.rect(20, 342, 530, 20); 
        doc.setFontSize(6);
        doc.text(30, 354, quote.shipping_carrier);
        doc.text(200, 354, '7 Business Days');
        doc.text(520, 354, '$'+quote.shipping_cost.toFixed(2));
        
        doc.setFontStyle("bold");
        doc.text(20, 380, 'Is the shipping cost covered by the warranty?');
        doc.text(530, 380, quote.shipping_cost_covered ? 'Yes' : 'No');
        
        doc.save("receipt.pdf");        
    }

    render() {
        const {quote, invoice} = this.props;
        const {series} = this.state;
        const date = new Date(invoice.created_at);
        return(
            <React.Fragment>
            <div className="opti_wrapper payment_pages">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8">
                        {invoice.status === invoiceStatuses.Decommissioned || parseInt(invoice.status) === invoiceStatuses.Decommissioned ?
                            <React.Fragment>
                            <h1 className="page_title">We have receiver you decommission request.</h1>
                            <p>Your unit(s) will be decommissioned on [insert date + 7 days]. If you chnage your mind before then, please call us at (787) 889-2285 or write Lee.Maldonado@optimanufacturing.com</p>
                            </React.Fragment>
                            :
                            <React.Fragment>
                            <h1 className="page_title">Your unit(s) will be returned as per your request.</h1>
                            <p>Please allow 2-3 business days for processing. You will notified through email once the unit ships. If you have any questions, please call us at (787) 889-2285 or write Lee.Maldonado@optimanufacturing.com</p>
                            </React.Fragment>
                        }
                            <button type="button" onClick={this.printDocument} className="btn-main btn-black no-radius">Download Your Receipt</button>
                            <div className="items_detail">
                                <h4 className="titleSmall border_under">Quoted Unit(s) </h4>
                                <div className="crm_table unit_quoted">
                                    <div className="table">
                                    {quote.quoteEquipments.length > 0 && 
                                        [quote.quoteEquipments.map((eq)=> 
                                        <div className="show_added_list" key={eq.id}>
                                            <div className="row align-items-center">
                                                <div className="col col-sm-1">
                                                    <img src={eq.equipment.item.image} alt=""/>
                                                </div>	
                                                <div className="product_series col-2">
                                                    {eq.equipment.serial_number}
                                                </div>
                                                <div className="col-2">
                                                    {eq.equipment.item.item_name}
                                                </div>
                                                <div className="col-2">
                                                {eq.equipment.customer.first_name+' '+eq.equipment.customer.last_name}
                                                </div>
                                                <div className="col-2">
                                                {eq.equipment.customer.company}
                                                </div>
                                            </div>
                                        </div>)]}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-4 pl-5">
                            <div className="add_new_quote payment_option">
                                <div className="quote_card">
                                    <div className="show_total border-0 pb-0 mb-0">
                                        <h3 className="total_label">Paid <small> {date.toLocaleDateString('default', { month: 'long' })} {date.getDate()}, {date.getFullYear()}</small></h3>
                                        <h3 className="total_status ml-auto text-green">$ {invoice.total.toFixed(2)}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opti_footer_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section_heading">
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <h2>Find your next tension meter</h2>
                                        <p>Choose for our wide variety of series and models.</p>
                                    </div>
                                    <div className="col-md-6 pl-5">
                                        <div className="couponCode">
                                        Save 10% off your next purchase with coupon code: <span>new10</span>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>                            
                    </div>
                    <div className="row">
                    {series.map((s)=>
                        <div className="col-md-3">
                            <div className="product_box">
                                <div className="prduct_img">
                                    <img src={s.image} alt=""/>
                                </div>
                                <a href={s.url} target="blank" className="btn-main no-radius btn-block">
                                    View {s.title}
                                </a>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
            </div>
            </React.Fragment>
        );
    }
}

export default SuccessReturn;