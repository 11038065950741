import React from 'react';
import { userLogout } from '../../services/accountService';
import { NavLink } from "react-router-dom";

const Logout = () => {
    userLogout('')
    .then((response) => {
        localStorage.setItem('token', '');
        localStorage.setItem('user_id', '');
        localStorage.getItem('user_name', '');
        window.location.href = '/'; 
    });
}


class Sidenav extends React.Component {    
    render(){
        const {curUser} = this.props;
        return (
            
            <div className="sidenav">
                <ul>
                    <li><NavLink exact activeClassName="active" refresh="true" className="nav-link" to="/">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.4" height="16" viewBox="0 0 14.4 16"><path d="M274.5,906.4a1.641,1.641,0,0,1,1.6,1.6v11.2a1.64,1.64,0,0,1-1.6,1.6H263.3a1.452,1.452,0,0,1-1.12-.48,1.58,1.58,0,0,1-.48-1.12V908a1.641,1.641,0,0,1,1.6-1.6h.8v-1.6h1.6v1.6h6.4v-1.6h1.6v1.6Zm0,12.8v-8.8H263.3v8.8Zm-1.6-7.2v1.6h-8V912Zm-2.4,3.2v1.6h-5.6v-1.6Z" transform="translate(-261.7 -904.8)" fill="#fff" opacity="0.5"/></svg> Log</NavLink></li>
                    <li><NavLink activeClassName="active" refresh="true" className="nav-link" to="/clients">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13.433" height="15" viewBox="0 0 13.433 15"><path d="M680.24,1010.667a1.53,1.53,0,0,1,1.493,1.493v10.448a1.53,1.53,0,0,1-1.493,1.493H669.792a1.53,1.53,0,0,1-1.492-1.493V1012.16a1.53,1.53,0,0,1,1.492-1.493h3.134a2.44,2.44,0,0,1,.821-1.119,2.021,2.021,0,0,1,2.537,0,2.44,2.44,0,0,1,.821,1.119Zm-.746,11.94v-1.045c0-.672-.522-1.194-1.567-1.642a7.433,7.433,0,0,0-2.91-.672,7.155,7.155,0,0,0-2.911.672c-.97.448-1.567.97-1.567,1.642v1.045Zm-2.91-8.284a2.164,2.164,0,1,0,.672,1.567A2.148,2.148,0,0,0,676.584,1014.324Zm-1.045-3.433a.721.721,0,1,0,.224.522A.677.677,0,0,0,675.539,1010.891Z" transform="translate(-668.3 -1009.1)" fill="#fff" opacity="0.5"/></svg> Clients</NavLink></li>
                    <li><NavLink activeClassName="active" refresh="true" className="nav-link" to="/quotes">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12.6" height="14" viewBox="0 0 12.6 14"><path d="M742.2,1128.6v-14l1.05,1.05,1.05-1.05,1.05,1.05,1.05-1.05,1.05,1.05,1.05-1.05,1.05,1.05,1.05-1.05,1.05,1.05,1.05-1.05,1.05,1.05,1.05-1.05v14l-1.05-1.05-1.05,1.05-1.05-1.05-1.05,1.05-1.05-1.05-1.05,1.05-1.05-1.05-1.05,1.05-1.05-1.05-1.05,1.05-1.05-1.05Zm10.5-9.1v-1.4h-8.4v1.4Zm0,2.8v-1.4h-8.4v1.4Zm0,2.8v-1.4h-8.4v1.4Z" transform="translate(-742.2 -1114.6)" fill="#fff" opacity="0.5"/></svg> Quotes</NavLink></li>
                    <li><NavLink activeClassName="active" refresh="true" className="nav-link" to="/service-requests"><i className="fas fa-circle"></i> Service Requests</NavLink></li>
                    <li><NavLink activeClassName="active" refresh="true" className="nav-link" to="/orders"><i className="fas fa-circle"></i> Orders</NavLink></li>
                    <li><NavLink activeClassName="active" refresh="true" className="nav-link" to="/invoices"><svg xmlns="http://www.w3.org/2000/svg" width="16.1" height="12.88" viewBox="0 0 16.1 12.88"><path d="M90.39,768.6a1.462,1.462,0,0,1,1.127.483A1.589,1.589,0,0,1,92,770.21v9.66a1.651,1.651,0,0,1-1.61,1.61H77.51A1.462,1.462,0,0,1,76.383,781a1.589,1.589,0,0,1-.483-1.127v-9.66a1.651,1.651,0,0,1,1.61-1.61Zm0,11.27v-9.66H77.51v9.66Zm-7.245-.8v-.8h-1.61v-1.61h3.22v-.8H82.34a.825.825,0,0,1-.805-.8v-2.415a.825.825,0,0,1,.805-.8h.805v-.8h1.61v.8h1.61v1.61h-3.22v.8H85.56a.825.825,0,0,1,.805.8v2.415a.825.825,0,0,1-.805.8h-.805v.8Z" transform="translate(-75.9 -768.6)" fill="#fff" opacity="0.5"/></svg> Invoices</NavLink></li>
                    <li><NavLink activeClassName="active" refresh="true" className="nav-link" to="/shipping-carrier"><svg xmlns="http://www.w3.org/2000/svg" width="14.46" height="9.84" viewBox="0 0 14.46 9.84"><path d="M13.488,6.46H11.516V4h-9.2A1.278,1.278,0,0,0,1,5.23V12H2.315A1.909,1.909,0,0,0,4.286,13.84,1.909,1.909,0,0,0,6.258,12H10.2a1.909,1.909,0,0,0,1.972,1.845A1.909,1.909,0,0,0,14.146,12H15.46V8.92Zm-9.2,6.458a.925.925,0,1,1,0-1.845.925.925,0,1,1,0,1.845ZM13.16,7.383,14.448,8.92H11.516V7.383Zm-.986,5.535A.925.925,0,1,1,13.16,12,.955.955,0,0,1,12.174,12.918Z" transform="translate(-1 -4)" fill="#fff" opacity="0.5"/></svg> Shipping Carriers</NavLink></li>
                    <li className="gap"></li>
                    {curUser.role === 'Admin' &&
                    <li><NavLink activeClassName="active" refresh="true" className="nav-link" to="/staff">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.634" height="17.868" viewBox="0 0 15.634 17.868"><g transform="translate(-240 -90)" opacity="0.5"><path d="M-4-308.132a6.72,6.72,0,0,1,6.7-6.7H4.933a6.72,6.72,0,0,1,6.7,6.7Zm2.9-2.234H8.842A4.5,4.5,0,0,0,4.933-312.6H2.812A4.506,4.506,0,0,0-1.1-310.366Zm.447-10.051v-1.117A4.48,4.48,0,0,1,3.817-326a4.48,4.48,0,0,1,4.467,4.466v1.117a4.48,4.48,0,0,1-4.467,4.467A4.48,4.48,0,0,1-.65-320.417Zm2.233-1.117v1.117a2.24,2.24,0,0,0,2.233,2.234,2.24,2.24,0,0,0,2.234-2.234v-1.117a2.24,2.24,0,0,0-2.234-2.233A2.24,2.24,0,0,0,1.583-321.533Z" transform="translate(244 416)" fill="#fff" opacity="0.5"/></g></svg> Staff</NavLink></li>}
                    <li><NavLink activeClassName="active" refresh="true" className="nav-link" to="/parts"><svg xmlns="http://www.w3.org/2000/svg" width="13.746" height="13.746" viewBox="0 0 13.746 13.746"><path d="M0,0H13.746V13.746H0Z" fill="none"/><path d="M13.41,11.352,8.2,6.14a3.676,3.676,0,0,0-.859-3.952A3.786,3.786,0,0,0,3.1,1.444L5.563,3.906,3.845,5.625,1.325,3.162A3.691,3.691,0,0,0,2.069,7.4a3.676,3.676,0,0,0,3.952.859l5.212,5.212a.554.554,0,0,0,.8,0l1.317-1.317a.516.516,0,0,0,.057-.8Z" transform="translate(-0.408 -0.47)"  fill="#fff" opacity="0.5"/></svg> Parts</NavLink></li>
                    <li className="nested"><NavLink activeClassName="" className="nav-link" to=""><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path d="M8,0a8,8,0,1,0,8,8A8.024,8.024,0,0,0,8,0ZM8,10a2,2,0,1,1,2-2A2.006,2.006,0,0,1,8,10ZM8,2a5.834,5.834,0,0,1,2.6.6L9.044,4.156a3.591,3.591,0,0,0-2.089,0L5.4,2.6A5.834,5.834,0,0,1,8,2ZM2,8a5.834,5.834,0,0,1,.6-2.6L4.156,6.956a3.591,3.591,0,0,0,0,2.089L2.6,10.6A5.834,5.834,0,0,1,2,8Zm6,6a5.834,5.834,0,0,1-2.6-.6l1.556-1.556a3.591,3.591,0,0,0,2.089,0L10.6,13.4A5.834,5.834,0,0,1,8,14Zm5.4-3.4L11.844,9.044a3.591,3.591,0,0,0,0-2.089L13.4,5.4a5.933,5.933,0,0,1,0,5.2Z" fill="#fff"/></svg> FAQ</NavLink>
                        <ul className="sub_nav">
                            <li><NavLink activeClassName="active" refresh="true" className="nav-link" to="/faq">Status Updates</NavLink></li>
                            <li><NavLink activeClassName="active" refresh="true" className="nav-link" to="/calibration">Calibration Cycle</NavLink></li>
                            <li><NavLink activeClassName="active" refresh="true" className="nav-link" to="/service-request-faq">Service Requests</NavLink></li>
                            <li><NavLink activeClassName="active" refresh="true" className="nav-link" to="/parts-management">Parts Management</NavLink></li>
                            <li><NavLink activeClassName="active" refresh="true" className="nav-link" to="/staff-management">Staff Management</NavLink></li>
                        </ul>
                    </li>
                    {/*
                    <li><NavLink activeClassName="active" className="nav-link" to="/emails"><i className="fas fa-envelope"></i> Emails</NavLink></li>
                    <li><NavLink activeClassName="active" className="nav-link" to="/statistics"><i className="fas fa-chart-bar"></i> Statistics</NavLink></li>
                    <li><NavLink activeClassName="active" className="nav-link" to="/settings"><i className="fas fa-cog"></i> Settings</NavLink></li>
                    */}
                    <li><NavLink className="nav-link" onClick={Logout} to="/signout"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="9.98" viewBox="0 0 14 9.98"><path d="M13.727,3.01H2.273A1.2,1.2,0,0,0,1,4.12V6.335H2.273V4.109H13.727V11.9H2.273V9.665H1v2.226a1.2,1.2,0,0,0,1.273,1.1H13.727A1.2,1.2,0,0,0,15,11.891V4.12a1.2,1.2,0,0,0-1.273-1.11ZM7.364,10.22,9.909,8,7.364,5.78V7.445H1v1.11H7.364Z" transform="translate(-1 -3.01)" fill="#fff" opacity="0.5"/></svg> Sign Out</NavLink></li>
                </ul>       
            </div>
            
        );
    }
}

export default Sidenav;