import React from 'react';
import { NavLink } from "react-router-dom";

export class faq extends React.Component {
    render(){
        return(
        <div className="opti_wrapper faq_page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <h1 className="page_title">FAQ</h1>
                        <div className="section_break">
                            <h3>Status Updates Types and Definitions</h3>
                            <p>To track the current status of Tensiometers, we will use Status “Updates”. These updates do have a logical order to them, but you may use them as you see fit within the Customer Relation Process. Below are the definitions, suggested use cases and notes for each type of update.</p>
                        </div>                  

                        <div className="section_break">
                            <h4>Unit Requested</h4>
                            <h5>Definition</h5>
                            <p>This update will be use to send the shipping information to a particular client requesting the unit be sent to Opti Manufacturing.</p>
                            <h5>Suggested Use Cases</h5>
                            <ul>
                                <li>When a customer requests servicing for their unit via the servicing form or any other means of communication. </li>
                                <li>When a unit is recalled</li>
                            </ul>
                            <h5>Notes:</h5>
                            <p>When a customer submits a Service Request from the OptiWebsite, a case will be created and displayed within the Service Request Section. If a Service Request is approved, it will be converted into a “Unit Request” and added to the profile of that particular Tensiometer based on the serial provided by the customer within the Service Request.</p>
                            <p>If the serial number does not match a particular unit within the Tensiometer Log, an alert will be shown above the Service Request. In this case, a Unit may be Requested, but no update will be posted to any profile since it can not be found.  </p>
                        </div>

                        <div className="section_break">
                            <h4>Received</h4>
                            <h5>Definition</h5>
                            <p>This a simple update use to send  notification to the client that their unit has been received by Opti Manufacturing.</p>
                            <h5>Suggested Use Cases</h5>
                            <ul>
                                <li>When a customer requests servicing for their unit via the servicing form or any other means of communication. </li>
                            </ul>
                            <h5>Notes:</h5>
                            <p>If a unit is not serviceable, you may use this update to notify the client and offer to decommission the unit at no cost and a replacement coupon (if applicable).</p>
                        </div>

                        <div className="section_break">
                            <h4>Quoted</h4>
                            <h5>Definition</h5>                            
                            <ul>
                                <p>With this update, we will specify: </p>
                                <li>Parts Needed for Repairs (Including: quantity, unit cost, and total cost) and if its covered under warranty</li>
                                <li>Shipping Carrier, Cost and if that amount is covered under warranty.</li>
                                <li>Additional Details (Open textfield)</li>
                            </ul>
                            <h5>Suggested Use Cases</h5>
                            <ul>
                                <li>When a unit is received and inspected</li>
                            </ul>
                            <h5>Notes:</h5>
                            <p>The Parts Needed for Repairs are values that are stored within the <span className="text-red">Parts</span> section of this tool.</p>
                            <ul>
                                <p>When the notification is sent for this update, three possible options will be presented to the client: </p>
                                <li>Pay for repairs</li>
                                <li>We Ship the Unit Back</li>
                                <li>Decomission the Unit</li>
                            </ul>
                            <p>Once the client chooses and completes the process of each options, a label will be added next to the quote.</p>
                            <div className="btn_groups">
                                <button className="btn-main btn-line red">Ship Back</button>
                                <button className="btn-main btn-line green">Repairs Paid</button>
                                <button className="btn-main btn-line black">Decomission</button>
                            </div>
                        </div>

                        <div className="section_break">
                            <h4>Shipped</h4>
                            <h5>Definition</h5>   
                            <p>Shares the shipping information with the client.</p>                         
                            <h5>Suggested Use Cases</h5>   
                            <p>This update has two distinct use cases: </p>                        
                            <div className="pl-5">
                                <div><p className="mb-0"><strong>Client request we Ship Back the Unit</strong></p>
                                here we will notify the client of the shipping carrier and the tracking number for their unit.                                 
                                </div>
                                <div className="mt-4"><p className="mb-0"><strong>Client Pays for Repairs and the unit is Serviced</strong></p>
                                Here we will notify the client of the shipping carrier and the tracking number for their unit. If Calibration was completed within the servicing process, the unit must be marked to restart the <span className="text-red">Calibration Cycle</span>.                                   
                                </div>                                
                            </div>                            
                        </div>

                        <div className="section_break">
                            <h4>Decommissioned</h4>
                            <h5>Definition</h5>   
                            <p>Archives a Tensiometer’s profile and removes it from the main Tensiometer Log.</p>                         
                            <h5>Suggested Use Cases</h5>   
                            <p>This update should only be applied at the clients request. A coupon for a replacement unit may be offered (if applicable) within the notes section of the Update.</p>                        
                        </div>

                        <div className="section_break">
                            <h4>Calibration Cycle</h4>
                            <h5>Definition</h5>   
                            <p>This status follow a separate logic from other updates. If main goal if to manage the notifications sent to clients about their Tensiometer’s Calibration and its need to be recalibrated every 365 days (1 year). <NavLink className="text-red" to="/">Read more here</NavLink></p>                         
                        </div>

                        <div className="section_break">
                            <h4>Calibration Expired</h4>
                            <h5>Definition</h5>   
                            <p>This is a consequential status based on the Calibration Cycle. It is applied to units that have gone more than 365 days without re-calibration. <NavLink className="text-red" to="/">Read more here</NavLink></p>                         
                        </div>
                    </div>
                </div>                
            </div>
        </div>
        );
    }
}
export default faq;