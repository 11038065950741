import httpService from './httpService';

// This is an example of movie service, we should create a service like this for each Model defined on loopback API and use is in all components / pages as needed.
export const getCalibrationById = (id) => {
   var CaliParam= { 
               params: {
                  filter : {
                              "where": {"id": id }
                           } 
               } 
   }
   return httpService.get("/Reminders/findOne",CaliParam)
           .then((response) => {
              return response && response.data;
           });
}

export const updateCalibration = (data) => {
   return httpService.patch("/Reminders/",data)
           .then((response) => {
              return response && response.data;
           });
}

// more such methods can be defined here, for different endpoints of a model