import httpService from './httpService';

// This is an example of movie service, we should create a service like this for each Model defined on loopback API and use is in all components / pages as needed.

export const updateEquipment = (data) => {
   return httpService.patch("/Equipment/",data)
           .then((response) => {
              return response && response.data;
           });
}

export const addEquipment = (data) => {
   return httpService.post("/Equipment/",data)
           .then((response) => {
              return response && response.data;
           });
}

export const getEquipment = (data) => {
   return httpService.get("/Equipment",data)
           .then((response) => {
              return response && response.data;
           });
}

export const getEquipmentByOrderId = (id) => {
   var equipParam = { 
      params: 
      {
         filter : 
         {
            "where": { 
               "order_id": id
            },
            "include": 
            [
               {
                  "relation": "item",
                  "scope": {
                     "fields": ["item_name"]
                  }
               },
               {"relation": "updates",
                  "scope": {
                     // "order": "id desc",
                     "include": [
                                    {"relation": "updateType"},
                                    {"relation": "sold"},
                                    {"relation": "shipped",
                                       "scope": {
                                          "include": [ {"relation": "shippingCarrier"}]
                                       }
                                    }
                                 ]
                  }
               }
            ]
            
         }
      } 
}
   return httpService.get("/Equipment",equipParam)
           .then((response) => {
              return response && response.data;
           });
}

export const getEquipmentSelect = (data) => {
   return httpService.get("/Equipment/equipSelect",data)
           .then((response) => {
              return response && response.data;
           });
}

export const searchEquipment = (data) => {
   return httpService.post("/Equipment/equipSearch",data)
           .then((response) => {
              return response && response.data;
           });
}

export const deleteEquipment = (id) => {
   return httpService.delete("/Equipment/"+id)
           .then((response) => {
              return response && response.data;
           });
}

// more such methods can be defined here, for different endpoints of a model