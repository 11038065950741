import React from 'react';
import PartForm from './PartForm';
import PartList from './PartList';
import { getPartList, addNewPart, deletePart, updatePart, uploadImage } from '../../services/partService';
import ErrorsList from "../../components/common/ErrorsList";
import "./part.scss";

class PartContainer extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            parts: [],
            addPart: false,
            partIdToEdit: null,
            hasError: '',
            hasSuccess: '',
            partImage: null,
            loader: true,
            editPart:[]
        }

        this.handleShow = this.handleShow.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleEditCancel = this.handleEditCancel.bind(this);
    }

    componentDidMount() {
        getPartList({ params:{filter :{order: 'id desc'} } }).then(part => {
            this.setState({
                parts: part,
                loader: false
            }); 
        });
    }

    handleShow() {
        this.setState(function () {
            return {
                addPart: true
            }
        })
    }

    handleHide() {
        this.setState(function () {
            return {
                addPart: false
            }
        })
    }

    preparePartPayload(partData) {
        
        const { name, sku, cost, description } = partData;
        
        let payloadData = {
            "part_name": name,
            "sku": sku,
            "cost": parseFloat(cost).toFixed(2),
            "description": description
        };
        
        if(this.state.partImage != null) {
            payloadData['picture'] = this.state.partImage;
        }
        if(this.state.partIdToEdit != null) {
            payloadData['id'] = this.state.partIdToEdit;
        }
        
        return payloadData;
    }

    handleSubmit(newPartData) {
        const { image } = newPartData;
        
        if(image !== null){
            
            this.handleUpload(image)
            .then(imgName => {
                
                const payload = this.preparePartPayload(newPartData);
                this.setState({
                    partImage: null
                });
                addNewPart(payload)
                .then((response) => {
                    if(response.id > 0){
                        this.setState({ hasSuccess: "Part Added"});
                        setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                    }
                    this.setState(prevState => {
                        return {
                            parts: [response, ...prevState.parts],
                        }
                    });
                    this.handleHide();
                })
                .catch((error) => {
                    var err_str = [];
                    var errors = Object.values(error.response.data.error.details.messages);
                    errors.map((err) => 
                        err_str.push(err[0])
                    );
                    this.setState({ hasError: err_str});
                    
                });
            })
        }
        else
        {
            const payload = this.preparePartPayload(newPartData);
            addNewPart(payload)
                .then((response) => {
                    if(response.id > 0){
                        this.setState({ hasSuccess: "Part Added"});
                        setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                    }
                    this.setState(prevState => {
                        return {
                            parts: [response, ...prevState.parts],
                        }
                    });
                    this.handleHide();
                })
                .catch((error) => {
                    var err_str = [];
                    var errors = Object.values(error.response.data.error.details.messages);
                    errors.map((err) => 
                        err_str.push(err[0])
                    );
                    this.setState({ hasError: err_str});
                    
                });
        }
         
    }

    handleDelete(data){
        deletePart(data.id)
        .then((response) => {
        
            this.setState(prevState => {
                let parts = prevState.parts.filter((post) => {
                    return data.id !== post.id;
                });
                return {
                    parts: parts,
                }
            });
        })
        .catch(function (error) {
            var err_str = [];
            var errors = Object.values(error.response.data.error.details.messages);
            errors.map((err) => 
                err_str.push(err[0])
            );
            this.setState({ hasError: err_str});
        });
    }

    handleEdit(part) {
        if (part && part.id){
            this.setState({
                partIdToEdit: part.id,
                editPart: part,
                addPart: false
            });
        }
    }

    handleEditCancel() {
        this.setState({
            partIdToEdit: null,
            editPart:[]
        });
    }

    handleUpload(imgData){
    
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('picture',imgData,Date.now());
            
            uploadImage(formData)
            .then((response) => {
                if(response.result.files.picture[0]){
                    
                    this.setState({
                        partImage: response.result.files.picture[0].name
                    });
                    return resolve(response.result.files.picture[0].name);
                }
                else{
                    return reject("Image Not Uploaded");
                }
            });  
        });
    }

    handleUpdate(updatePartData){
        const { image } = updatePartData;
        if(image !== ''){
            this.handleUpload(image)
            .then(imgName => {
                const payload = this.preparePartPayload(updatePartData);
                this.setState({
                    partImage: null
                });
                updatePart(payload)
                .then((response) => {
                    // update the user in list
                    if(response.id > 0){
                        this.setState({ hasSuccess: "Part Updated"});
                        setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                    }
                    const partList = [...this.state.parts];
                    partList.forEach(user => {
                        if(user.id === this.state.partIdToEdit){
                            Object.assign(user, payload); // copy new values to user
                        }
                    });
                    
                    this.setState({
                        parts: partList
                    });
                    this.handleEditCancel();
                })
                .catch(function (error) {
                    var err_str = [];
                    var errors = Object.values(error.response.data.error.details.messages);
                    errors.map((err) => 
                        err_str.push(err[0])
                    );
                    this.setState({ hasError: err_str});
                });
            });
        }
        else{
            const payload = this.preparePartPayload(updatePartData);
            updatePart(payload)
                .then((response) => {
                    // update the user in list
                    if(response.id > 0){
                        this.setState({ hasSuccess: "Part Updated"});
                        setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                    }
                    const partList = [...this.state.parts];
                    partList.forEach(user => {
                        if(user.id === this.state.partIdToEdit){
                            Object.assign(user, payload); // copy new values to user
                        }
                    });
                    
                    this.setState({
                        parts: partList
                    });
                    this.handleEditCancel();
                })
                .catch(function (error) {
                    var err_str = [];
                    var errors = Object.values(error.response.data.error.details.messages);
                    errors.map((err) => 
                        err_str.push(err[0])
                    );
                    this.setState({ hasError: err_str});
                });
        }
    }

    render(){
        const {addPart, parts, loader} = this.state;
        return (
        <React.Fragment>
        <div className="opti_wrapper part_page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="page_title">Parts</h1>
                        <div className="btn-and-search">
                                <button onClick={this.handleShow} className="btn-main">Add New Part <img src="./img/plus-icon-white.svg" alt="" height="14"/></button>
                            <div className="opti_search ml-auto d-none">
                                <img src="./img/search-icon.svg" alt="" height="16"/>
                                <input type="text" className="form-control" placeholder="Part Name, SKU, Unit Cost…"/>
                                <input type="submit" className="search_btn" value="Search"/>
                            </div>
                        </div>
                        {this.state.hasError !== '' &&
                            <ErrorsList errors={this.state.hasError}></ErrorsList>}
                        {this.state.hasSuccess !== '' &&
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                <strong>Success!</strong> {this.state.hasSuccess}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>}
                        <div className="row crm_table">
                            <PartList 
                            parts={parts}
                            addForm={addPart}
                            hideForm={this.handleHide}
                            onSubmit={this.handleSubmit}
                            editId={this.state.partIdToEdit}
                            editPart={this.state.editPart}
                            onEdit={this.handleEdit}
                            onEditCancel={this.handleEditCancel}
                            onEditSubmit={this.handleUpdate}
                            deletePart={this.handleDelete}
                            loader={loader}>
                            </PartList>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
        {addPart && <div className="log_right_sidebar">
            <h2>Add New Part</h2>
            <PartForm
            onCancel={this.handleHide}
            onSubmit={this.handleSubmit}>
            </PartForm>
        </div>}
        {this.state.partIdToEdit != null && <div className="log_right_sidebar">
            <h2>Edit Part</h2>
            <PartForm
            editId={this.state.partIdToEdit}
            partData={this.state.editPart}
            onSubmit={this.handleUpdate}
            onCancel={this.handleEditCancel}>
            </PartForm>
        </div>}
        </React.Fragment>
        );
    }    
  }

  export default PartContainer;