import React from 'react';
import { Link, Redirect } from "react-router-dom";
import { userLogin } from '../../services/accountService';
import "./login.scss";

class Login extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
            email: '',
            password: '',
            hasError: '',
            hasSuccess: props.location.search === '?reset=true' ? "Please login with new password" : ""
        }
        if(this.state.hasSuccess !== ""){
            setTimeout(() => { this.setState({ hasSuccess: ""}); }, 5000);
        }
        this.handleLogin = this.handleLogin.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    prepareLoginPayload(loginData){
        const { email, password } = loginData;

        let payloadData = {
            "email": email.trim(),
            "password": password
        };

        return payloadData;
    }

    handleOnChange(e){
        if (e.target.id === 'loginEmail') {
            this.setState({ email: e.target.value });
        } else if (e.target.id === 'loginPass') {
            this.setState({ password: e.target.value });
        }
    }

    handleLogin(event){
        event.preventDefault();
        const payload = this.prepareLoginPayload(this.state);
        userLogin(payload)
        .then((response) => {
            if(response.id){
                localStorage.setItem('token', response.id);
                localStorage.setItem('user_id', response.userId);
                window.location.href = '/';
            }else{
                this.setState({ hasError: "Invalid Login"});    
            }
        })
        .catch((error) => {
            this.setState({ hasError: error.response.data.error.message});
        });
        setTimeout(() => { this.setState({ hasError: ""}); }, 3000);
    }

    render() {
        if (localStorage.getItem('token') !== '') {
            return <Redirect to='/' />
        }
        return (
            <div className="userModule">
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-center">
                        <div className="col-md-6">
                            <div className="card">                            
                                <div className="card-body">
                                <div className="logo"><img src="./img/logo.svg" alt="" height="38px;"/></div>
                                <h1>Login</h1>
                                {this.state.hasError !== '' &&
                                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                        <strong>Error!</strong> {this.state.hasError}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    </div>}
                                {this.state.hasSuccess !== '' &&
                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                        <strong>Success!</strong> {this.state.hasSuccess}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    </div>}
                                <form onSubmit={this.handleLogin}>   
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <input id="loginEmail" type="email" onChange={this.handleOnChange} value={this.state.email} className="form-control" name="email" placeholder="Email"/>
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input id="loginPass" type="password" onChange={this.handleOnChange} value={this.state.password} className="form-control" name="password" placeholder="Password"/>
                                    </div>
                                    <div className="form-group mt-5 d-flex">                                    
                                        <button type="submit" className="btn-main">Login</button>
                                        <Link className="form_link" to="/password-reset-request">Forgot Password?</Link>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;