import httpService from './httpService';

// This is an example of movie service, we should create a service like this for each Model defined on loopback API and use is in all components / pages as needed.

export const getServiceRequestList = (data) => {
   return httpService.get("/ServiceRequests",data)
           .then((response) => {
              return response && response.data;
           });
}

export const getServiceRequest = (data) => {
   return httpService.get("/ServiceRequests/findOne",data)
           .then((response) => {
              return response && response.data;
           });
}

export const addServiceRequest = (data) => {
   return httpService.post("/ServiceRequests",data)
           .then((response) => {
              return response && response.data;
           });
}

export const updateServiceRequest = (data) => {
   return httpService.patch("/ServiceRequests/",data)
           .then((response) => {
              return response && response.data;
           });
}

export const uploadPDF = (data) => {
   return httpService.post("/Containers/reply/upload",data)
           .then((response) => {
              return response && response.data;
           });
}

export const addServiceRequestReply = (data) => {
   return httpService.post("/ServiceRequestReplies",data)
           .then((response) => {
              return response && response.data;
           });
}

// more such methods can be defined here, for different endpoints of a model