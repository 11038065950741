import React from 'react';

class OrderItems extends React.Component {
    
    constructor(props){
        super(props);
        
        this.state = {
            equipments: [],
            serials:[],
            updates: [],
        }
        this.handleSubmit = this.handleSubmit.bind(this); 
    }

    componentDidMount() {
        var param = this.props;
        this.setStateByProps(param);
    }

    setStateByProps(myprops){
        const {units, orderItem, customerId, orderId} = myprops;
        
        var unitArray = [];
        
        var serial_number = [];
        var equipments = orderItem.item.equipment;
        if(equipments.length === 0){
            for(let i = 0; i<units;i++){
                const data = {'customer_id':customerId,'order_id':orderId,'item_id':orderItem.item.id,'serial_number': '','id':null};
                unitArray.push(i);
                serial_number.push(data);
            }
        }
        else{
            if(equipments.length === units){
                
                for(let i = 0; i<equipments.length;i++){
                    const data = {'customer_id':customerId,'order_id':orderId,'item_id':orderItem.item.id,'serial_number': equipments[i].serial_number,'id':equipments[i].id};
                    unitArray.push(i);
                    serial_number.push(data);
                }
            }
            else{
                equipments = orderItem.item.equipment;
                
                for(let i = 0; i<equipments.length;i++){
                    const data = {'customer_id':customerId,'order_id':orderId,'item_id':orderItem.item.id,'serial_number': equipments[i].serial_number,'id':equipments[i].id};
                    unitArray.push(i);
                    serial_number.push(data);
                }
                
                for(let j = equipments.length; j<units;j++){
                    const data = {'customer_id':customerId,'order_id':orderId,'item_id':orderItem.item.id,'serial_number': '','id':null};
                    unitArray.push(j);
                    serial_number.push(data);
                }
                
            }
        }
        this.setState({ updates: myprops.updates, equipments: serial_number });
    }

    componentWillReceiveProps(props) {
        this.setStateByProps(props);
    }

    

    handleSubmit(event) {
        event.preventDefault();
        const {onSubmit} = this.props;
        onSubmit(this.state.equipments);
    }

    handleEquipmentChange = idx => evt => {
		const newEquipments = this.state.equipments.map((equipment, sidx) => {
            if (idx !== sidx) return equipment;
            if(evt.target.id === 'serial_number'){
                return { ...equipment, customer_id: equipment.customer_id, order_id: equipment.order_id, item_id: equipment.item_id, serial_number: evt.target.value, id: equipment.id };
            }
            return equipment;  
        });
    
        this.setState({ equipments: newEquipments });
        
    };

    render(){
        const {orderItem } = this.props;
        const {equipments, updates} = this.state;
        return(
            
            [equipments.map((equipment,index)=>
                <form onSubmit={this.handleSubmit}>
                <div className="show_added_list">
                    <div className="row align-items-center">
                        <div className="col col-sm-1">
                            <img src={orderItem.item.image} alt=""/>
                        </div>	
                        <div className="product_series col">
                        {orderItem.item.series}
                        </div>
                        <div className="col">
                        {orderItem.item.item_name}
                        </div>
                        <div className="col">
                        <input type="text" className="form-control" value={equipment.serial_number} id="serial_number" onChange={this.handleEquipmentChange(index)} placeholder="Serial Number" />
                        </div>
                        <div className="col">
                            {updates.includes(equipment.id) ?
                                <button type="submit" className="btn-main btn-block btn-green">Added!</button>
                                :
                                [equipment.id !== null ?
                                    <button type="submit" className="btn-main btn-block secondary">Update</button>
                                    :
                                    <button type="submit" className="btn-main btn-block secondary">Add to Log</button>
                                ]
                            }
                        </div>
                    </div>
                </div>
                </form>
            )]
            
        )
        
    }
}

export default OrderItems;