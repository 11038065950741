import React from 'react';

class CarrierForm extends React.Component {
    constructor(props){
        super(props);
        
        if(props.carrierData){
            const { carrier_name, cost, speed } = props.carrierData
            this.state = {
                carrier_name: carrier_name || '',
                cost: cost || '',
                speed: speed || ''
            };
        } else {
            this.state = {
                carrier_name: '',
                cost: '',
                speed: ''
            };
        }
        this.onChange = this.onChange.bind(this);
        this.handleAddCarrier = this.handleAddCarrier.bind(this);
    }

    onChange(e) {
        const value = e.target.value;
        this.setState({
            [e.target.name]: value
        });
        
    }

    handleValidation()
    {
        
        let { carrier_name, cost, speed } = this.state;
        let errors = {};
        let formIsValid = true;
        //Name
        if(!carrier_name){
            formIsValid = false;
            errors["carrier_name"] = "Cannot be empty";
        }
    
        //Cost
        if(!cost){
            formIsValid = false;
            errors["cost"] = "Cannot be empty";
        }
    
        //Speed
        if(!speed){
            formIsValid = false;
            errors["speed"] = "Cannot be empty";
        }
    
        this.setState({errors: errors});
        return formIsValid;
    }

    // this method collects form data and calls parent method with carrier data
    handleAddCarrier(event) {
        event.preventDefault();
        const { onSubmit } = this.props;
        const { carrier_name, cost, speed } = this.state;
        const newCarrierData = {
            carrier_name,
            cost,
            speed
        };
        if(this.handleValidation()){
            onSubmit(newCarrierData);
        }
    }

    render() {
        const { onCancel } = this.props;
        return (
            <form className='row' onSubmit={this.handleAddCarrier}>                                        
                <div className='col col-sm-12 mb-4'>
                    <input name='carrier_name' onChange={this.onChange} value={this.state.carrier_name} type="text" className="form-control" placeholder="DHL (Internation Shipping)"/>
                    {this.state.errors && <span className="error">{this.state.errors["carrier_name"]}</span>}
                </div>
                <div className='col col-sm-12  mb-4'>
                    <input name='cost' onChange={this.onChange} value={this.state.cost} type="number" className="form-control" placeholder="50.00"/>
                    {this.state.errors && <span className="error">{this.state.errors["cost"]}</span>}
                </div>
                <div className='col col-sm-12  mb-4'>
                    <input name='speed' onChange={this.onChange} value={this.state.speed} type="text" className="form-control" placeholder="7 Business days"/>
                    {this.state.errors && <span className="error">{this.state.errors["speed"]}</span>}
                </div>
                <div className='col col-sm-12'>
                    <button type="submit" className="btn-main btn-green btn-sm">Save</button>
                    <button href="#" onClick={onCancel} className="btn-main link-btn">Cancel</button>
                </div>
            </form>
        );
    }
}

export default CarrierForm;