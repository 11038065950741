import httpService from './httpService';

export const getInvoiceList = (perPage,skip,search) => {
   var invoiceParam = {};
   if(search === ''){
         invoiceParam =   {
                           params: {
                              filter:{
                                 "limit":perPage,
                                 "skip":skip, 
                                 "order": "id desc",
                                 "include": [
                                       {"relation": "customer"}
                                 ]
                              }
                           }    
                     };
   }
   else{
      invoiceParam =   {
         params: {
            filter:{
               // "limit":perPage,
               // "skip":skip, 
               "order": "id desc",
               "include": [
                  {"relation": "customer",
                  scope: {
                     "where": {
                        "or": [
                            {
                            "first_name": search
                            },
                            {
                            "last_name": search
                            }
                        ]
                     }
                  }}
               ]
            }
         }    
   };
      
   }
   return httpService.get("/Invoices",invoiceParam)
            .then((response) => {
               return response && response.data;
            });
}

export const getInvoiceDetail = (id) => {
   var invoiceParam =   {
                           params: {
                              filter:{
                                 "where": {"id":id},
                                 "order": "id desc",
                                 "include": [
                                       {"relation": "customer"},
                                       {
                                          "relation": "user",
                                          "scope": {
                                             "fields": ["first_name","last_name"]
                                          }
                                       },
                                       {"relation": "quote",
                                       "scope": {
                                          "include": [
                                             {
                                                   "relation": "quoteParts",
                                                   "scope": {
                                                      "include": [
                                                         {
                                                               "relation": "part",
                                                               "scope": {
                                                                  "fields": ["part_name"]
                                                               }
                                                         }
                                                      ]
                                                   }
                                             },
                                             {
                                                   "relation": "quoteEquipments",
                                                   "scope": {
                                                      "include": [
                                                         {
                                                               "relation": "equipment",
                                                               "scope": {
                                                                  "include": [
                                                                     {
                                                                           "relation": "item",
                                                                           "scope": {
                                                                           "fields": [
                                                                                 "id",
                                                                                 "item_name",
                                                                                 "image"
                                                                              ]
                                                                           }
                                                                     },
                                                                     {
                                                                           "relation": "reminders",
                                                                           "scope": {
                                                                              "fields": [
                                                                                 "id",
                                                                                 "reminder_type_id",
                                                                                 "serial_number",
                                                                                 "sending_date"
                                                                              ],
                                                                              "include": [
                                                                                 {
                                                                                 "relation": "reminderType"
                                                                                 }
                                                                              ]
                                                                           }
                                                                     }
                                                                  ]
                                                               }
                                                         }
                                                      ]
                                                   }
                                             }
                                          ]
                                       }}
                                 ]
                              }
                           }    
                     };
   return httpService.get("/Invoices/findOne",invoiceParam)
            .then((response) => {
               return response && response.data;
            });
}

export const getInvoiceTotal = (search) => {
   if(search !== ''){
      search = {
                  "params": {
                     "include": [
                        {"relation": "customer",
                        scope: {
                           "where": {
                              "or": [
                                  {
                                  "first_name": search
                                  },
                                  {
                                  "last_name": search
                                  }
                              ]
                           }
                        }}
                     ]   
                  }
               };
   }
   return httpService.get("/Invoices/count",search)
           .then((response) => {
              return response && response.data;
           });
}

export const updateInvoice = (data) => {
   return httpService.patch("/Invoices/",data)
           .then((response) => {
              return response && response.data;
           });
}

export const sendInvoice = (data) => {
   return httpService.post("/Invoices/sendemail",data)
           .then((response) => {
              return response && response.data;
           });
}

export const addNewInvoice = (data) => {
   return httpService.post("/Invoices",data)
           .then((response) => {
              return response && response.data;
           });
}

export const checkInvoiceExist = (quoteID) => {
   var invoiceParam = {
      params: {
         filter:{
            "where" : {"quote_id": quoteID}
         }
      }  
   };
   return httpService.get("/Invoices/findOne",invoiceParam)
      .then((response) => {
         return response && response.data;
      });
}