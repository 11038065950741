import httpService from './httpService';

export const getQuoteList = (ids,perPage,skip) => {

   var quoteParam =    {
      params: {
          filter:{
              "where": {"id": {"inq":[ids]} },
              "limit":perPage,
              "skip":skip, 
              "order": "id desc",
              "include": [
                  {"relation": "invoice"},
                  {
                      "relation": "quoteParts",
                      "scope": {
                          "include": [
                              {
                                  "relation": "part",
                                  "scope": {
                                  "fields": ["part_name"]
                                  }
                              }
                          ]
                      }
                  },
                  {
                      "relation": "quoteEquipments",
                      "scope": {
                          "include": [
                              {
                                  "relation": "equipment",
                                  "scope": {
                                      "include": [
                                            {
                                               "relation": "customer",
                                               "scope": {
                                                  "fields": ["id", "first_name", "last_name", "email", "phone", "company"]
                                               }
                                            },
                                            {
                                               "relation": "item",
                                               "scope": {
                                                  "fields": ["id", "item_name", "image"]
                                               }
                                            },
                                            {
                                               "relation": "reminders",
                                               "scope": {
                                                  "fields": ["id", "reminder_type_id","serial_number","sending_date"],
                                                  "include": [{"relation": "reminderType"}]
                                               }
                                            }
                                      ]
                                      }
                              }
                          ]
                      }        
                  }
               ]
          }
      }    
   };

    return httpService.get("/Quotes",quoteParam)
            .then((response) => {
               return response && response.data;
            });
}

export const getQuoteTotal = (data) => {
   return httpService.get("/Quotes/count",data)
           .then((response) => {
              return response && response.data;
           });
}

export const getQuoteIds = (data) => {
   return httpService.get("/Quotes/search",data)
           .then((response) => {
              return response && response.data;
           });
}

export const updateQuote = (data) => {
   return httpService.patch("/Quotes/",data)
           .then((response) => {
              return response && response.data;
           });
}

export const deleteQuote = (id) => {
   return httpService.delete("/Quotes/"+id)
           .then((response) => {
              return response && response.data;
           });
}

export const sendQuote = (data) => {
   return httpService.post("/Quotes/sendemail",data)
           .then((response) => {
              return response && response.data;
           });
}

export const getQuotebyTokenAndID = (quote_id,token) => {
   var quoteParam =    {
      params: {
          filter:{
              "where": {"id": quote_id, "token": token },
              "include": [
                  {
                      "relation": "quoteParts",
                      "scope": {
                          "include": [
                              {
                                  "relation": "part",
                                  "scope": {
                                  "fields": ["part_name"]
                                  }
                              }
                          ]
                      }
                  },
                  {
                      "relation": "quoteEquipments",
                      "scope": {
                          "include": [
                              {
                                  "relation": "equipment",
                                  "scope": {
                                      "include": [
                                            {
                                               "relation": "customer",
                                               "scope": {
                                                  "fields": ["id", "first_name", "last_name", "email", "phone", "company"]
                                               }
                                            },
                                            {
                                               "relation": "item",
                                               "scope": {
                                                  "fields": ["id", "item_name", "image"]
                                               }
                                            },
                                            {
                                               "relation": "reminders",
                                               "scope": {
                                                  "fields": ["id", "reminder_type_id","serial_number","sending_date"],
                                                  "include": [{"relation": "reminderType"}]
                                               }
                                            }
                                      ]
                                      }
                              }
                          ]
                      }        
                  }
               ]
          }
      }    
   };
   return httpService.get("/Quotes/findOne",quoteParam)
           .then((response) => {
              return response && response.data;
           });
}