import React from 'react';
import RightSideBar from './RightSideBar';
// import ServiceRequestForm from './ServiceRequestForm';
import ServiceRequestList from './ServiceRequestList';
// import ReplyForm from './ReplyForm';

import { getLogbySerialNo, addNewUpdate, addNewUpdateTable } from '../../services/updateService';
import { addServiceRequest, getServiceRequestList, updateServiceRequest, uploadPDF, addServiceRequestReply } from '../../services/servicerequestService';
import ErrorsList from "../../components/common/ErrorsList";
import "./service.scss";

class Servicerequest extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            found: '',
            customer:[],
            order:[],
            reminder:[],
            item:[],
            propSerialNumber: '',
            hasError: '',
            hasSuccess: '',
            requests: [],
            archivedRequests: [],
            action:'',
            cls:'',
            replyFile: null,
            replies:[],
            equipment_id: null,
            loader: true,
            showArchived: false,
            history: props.history,
        }

        this.onHandleSubmit = this.onHandleSubmit.bind(this);
        this.onHandleUpdate = this.onHandleUpdate.bind(this);
        this.getDatabySerialNo = this.getDatabySerialNo.bind(this);
        this.onView = this.onView.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.submitReply = this.submitReply.bind(this);
        // this.doArchived = this.doArchived.bind(this);
        // this.doUnArchived = this.doUnArchived.bind(this);
        this.showActiveRequest = this.showActiveRequest.bind(this);
        this.showArchivedRequest = this.showArchivedRequest.bind(this);
        this.BacktoList = this.BacktoList.bind(this);
    }

    componentDidMount() {
        
        this.getUnArchivedRequest();
        this.getArchivedRequest();
    }

    showActiveRequest(){
        this.getUnArchivedRequest();
        this.setState({
            showArchived: false
        }); 
    }

    showArchivedRequest(){
        this.getArchivedRequest();
        this.setState({
            showArchived: true
        }); 
    }

    getUnArchivedRequest(){
        getServiceRequestList({params: {filter : { "where":{"is_archived":0},"order": "id desc", "include": "serviceRequestReplies" }}}).then(requests => {
            this.setState({
                requests: requests,
                loader: false
            }); 
        });
    }

    getArchivedRequest(){
        getServiceRequestList({params: {filter : { "where":{"is_archived":1},"order": "id desc", "include": "serviceRequestReplies" }}}).then(unrachived => {
            this.setState({
                archivedRequests: unrachived,
                loader: false
            }); 
        });
    }

    getDatabySerialNo(serialNumber){
        getLogbySerialNo(serialNumber).then(list => {
            if(list[0]){
                this.setState({
                    item: list[0].item,
                    equipment_id: list[0].id,
                    customer: list[0].customer,
                    reminder: list[0].reminders,
                    propSerialNumber: serialNumber,
                    order: list[0].order,
                    found: true
                }); 
            }
            else
            {
                this.setState({
                    found: false
                });
            }
        });
    }

    onAdd(){
        this.setState({
            action: 'add',
            editId: null,
            cls: 'log_update_page'
        }); 
        this.state.history.push('service-request/add');
    }

    BacktoList(){
        this.setState({
            action: '',
            editId: null,
            cls: ''
        }); 
    }

    onView(data){
        this.state.history.push('service-request/'+data.id);
    }

    preparePayload(Data) {
        
        const { user_id, service_request_id, message, to } = Data;
        
        let payloadData = {
            "user_id": user_id,
            "service_request_id": service_request_id,
            "message": message,
            "to": to
        };
        
        if(this.state.replyFile != null) {
            payloadData['file'] = this.state.replyFile;
        }
        
        
        return payloadData;
    }

    submitReply(Data){
        
        const { file } = Data;
        
        if(file !== ''){
            
            this.handleUpload(file)
            .then(fileName => {
                
                const payload = this.preparePayload(Data);
                addServiceRequestReply(payload)
                .then((response) => {
                    if(response.id > 0){
                        this.setState({ hasSuccess: "Reply Added"});
                        setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                    }
                    this.setState(prevState => {
                        return {
                            replies: [response, ...prevState.replies],
                        }
                    });
                    
                })
                .catch((error) => {
                    var err_str = [];
                    var errors = Object.values(error.response.data.error.details.messages);
                    errors.map((err) => 
                        err_str.push(err[0])
                    );
                    this.setState({ hasError: err_str});
                    
                });
            })
        }
        else
        {
            const payload = this.preparePayload(Data);
            addServiceRequestReply(payload)
                .then((response) => {
                    if(response.id > 0){
                        this.setState({ hasSuccess: "Reply Added"});
                        setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                    }
                    this.setState(prevState => {
                        return {
                            replies: [response, ...prevState.replies],
                        }
                    });
                })
                .catch((error) => {
                    this.handleError(error);                    
                });
        }
    }

    

    onHandleUpdate(updateData){
        const {name, company, email, telephone, service_requested, details, serial_number, purchase_date, series, model} = updateData;
        const { found, editId } = this.state;
        var status = 'Not Found';
        if(found === true){
            status = 'Match Found';
        }
        
        var serviceRequestData = {
            name: name,
            company: company,
            email: email,
            telephone: telephone,
            service_requested: service_requested,
            details: details,
            serial_number: serial_number,
            purchase_date: purchase_date,
            series: series,
            model: model,
            status: status,
            id: editId
        }
        updateServiceRequest(serviceRequestData)
        .then(sRequest => {
            
            const sreqList = [...this.state.requests];
            sreqList.forEach(req => {
                if(req.id === this.state.editId){
                    Object.assign(req, sRequest); // copy new values to user
                }
            });
            
            this.setState({
                requests: sreqList
            });
            this.setState({
                action: '',
                editId: null,
                cls: ''
            }); 
            this.setState({ hasSuccess: "Service Request Update"});
            setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
        })
        .catch((error) => {
            this.handleError(error);
        });
        
    }
    
    onHandleSubmit(submitData){

        const {name, company, email, telephone, service_requested, details, serial_number, purchase_date, series, model} = submitData;
        const { found, equipment_id } = this.state;
        var status = 'Not Found';
        if(found === true){
            status = 'Match Found';
        }
        
        const fullname = name.trim().split(" ");
        const first_name = fullname[0];
        const last_name = (fullname[1]) ? fullname[1] : '';
        var serviceRequestData = {
            name: first_name+' '+last_name,
            company: company,
            email: email,
            telephone: telephone,
            service_requested: service_requested,
            details: details,
            serial_number: serial_number,
            purchase_date: purchase_date,
            series: series,
            model: model,
            status:status
        }
        addServiceRequest(serviceRequestData)
        .then(sRequest => {
            if(sRequest.id > 0){
                
                this.setState(prevState => {
                    return {
                        requests: [sRequest, ...prevState.requests],
                    }
                });
                this.setState({
                    action: '',
                    editId: null,
                    cls: ''
                }); 
                this.setState({ hasSuccess: "Service Request Added"});
                setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);

                if(found === true){
                    let payloadData = {
                        "equipment_id": equipment_id,
                        "update_type_id": 2,
                        "user_id": localStorage.getItem('user_id')
                    };

                    addNewUpdate(payloadData)
                    .then((response) => {
                        if(response.id > 0){
                            let payloadData = {
                                "update_id": response.id,
                                "staff_note": details,
                                "date": new Date()
                            };
                            let table = '/UnitRequested';
                            addNewUpdateTable(table,payloadData)
                            .then((res)=>{
                                if(res.id > 0){

                                }
                            })
                            .catch((error) => {
                                this.handleError(error);
                            });
                        }
                    })
                    .catch((error) => {
                        this.handleError(error);
                    }); 
                }
            }    
        })
        .catch((error) => {
            this.handleError(error);
        });
        
        
    }

    handleError(error){
        var err_str = [];
                                            
        if(typeof error.response.data.error.details !== 'undefined'){
            var errors = Object.values(error.response.data.error.details.messages);
            errors.map((err) => 
                err_str.push(err[0])
            );
        }
        else{
            err_str.push(error.response.data.error.message);
        }
        
        this.setState({ hasError: err_str});
        setTimeout(() => { this.setState({ hasError: ""}); }, 3000);
    }

    handleUpload(imgData){
    
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file',imgData);
            
            uploadPDF(formData)
            .then((response) => {
                if(response.result.files.file[0]){
                    
                    this.setState({
                        replyFile: response.result.files.file[0].name
                    });
                    return resolve(response.result.files.file[0].name);
                }
                else{
                    return reject("PDF Not Uploaded");
                }
            });  
        });
    }

    render(){
        const { customer, reminder, item, order, propSerialNumber, found, requests, archivedRequests, showArchived, action, cls, loader } = this.state;
        return (    
            <React.Fragment>    
                <div className={'opti_wrapper '+cls}>
                    <div className="container-fluid service_request_page">
                        <div className="row">
                            <div className="col-md-12">
                                {action === '' ?
                                <React.Fragment>
                                <h1 className="page_title">Service Requests</h1>
                                <div className="btn-and-search">
                                    <button onClick={this.onAdd} className="btn-main">Add New<img src="./img/plus-icon-white.svg" alt="" height="14"/></button>
                                    <div className="opti_search ml-auto d-none">
                                        <img src="./img/search-icon.svg" alt="" height="16"/>
                                        <input type="text" className="form-control" placeholder="Client Name, SKU, Unit Cost…"/>
                                        <input type="submit" className="search_btn" value="Search"/>
                                    </div>
                                </div>
                                <div className="request_filter">
                                    {showArchived ?
                                        <React.Fragment>
                                            <span className="filter_text" onClick={this.showActiveRequest}>Active [{requests.length}]</span>
                                            <span className="filter_text ml-auto active" onClick={this.showArchivedRequest}>Archived [{archivedRequests.length}]</span>
                                        </React.Fragment>
                                    :
                                    <React.Fragment>
                                    <span className="filter_text active" onClick={this.showActiveRequest}>Active [{requests.length}]</span>
                                    <span className="filter_text ml-auto" onClick={this.showArchivedRequest}>Archived [{archivedRequests.length}]</span>
                                    </React.Fragment>}
                                </div>
                                </React.Fragment>
                                :
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li onClick={this.BacktoList} className="breadcrumb-item active" aria-current="page"><button className="text-red">Service Requests</button></li>
                                    </ol>
                                </nav>}
                                {this.state.hasError !== '' &&
                                    <ErrorsList errors={this.state.hasError}></ErrorsList>}
                                {this.state.hasSuccess !== '' &&
                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                        <strong>Success!</strong> {this.state.hasSuccess}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    </div>}
                                <div className="client_info accordion">
                                {action === '' ?
                                    <div className="crm_table">
                                        {showArchived ?
                                            <ServiceRequestList
                                        requests={archivedRequests}
                                        onView={this.onView}
                                        loader={loader}> 
                                        </ServiceRequestList>
                                        :
                                        <ServiceRequestList
                                        requests={requests}
                                        onView={this.onView}
                                        loader={loader}> 
                                        </ServiceRequestList>}
                                    </div> :
                                    <React.Fragment>
                                    {/* {action === 'add' ?
                                    <ServiceRequestForm
                                    seriesList={seriesList}
                                    itemList={itemList}
                                    onHandleSubmit={this.onHandleSubmit}
                                    getDatabySerialNo={this.getDatabySerialNo}
                                    editId={editId}
                                    doArchived={this.doArchived}
                                    doUnArchived={this.doUnArchived}
                                    requestData={requestData}>
                                    </ServiceRequestForm>
                                    :
                                    <React.Fragment>
                                        <ServiceRequestForm
                                        seriesList={seriesList}
                                        itemList={itemList}
                                        onHandleSubmit={this.onHandleUpdate}
                                        getDatabySerialNo={this.getDatabySerialNo}
                                        editId={editId}
                                        doArchived={this.doArchived}
                                        doUnArchived={this.doUnArchived}
                                        requestData={requestData}>
                                        </ServiceRequestForm>
                                        <ReplyForm
                                        onHandleSubmit={this.submitReply}
                                        editId={editId}
                                        replies={replies}
                                        requestData={requestData}>
                                        </ReplyForm>
                                    </React.Fragment>    
                                    } */}
                                    </React.Fragment>
                                }
                                </div>
                            </div>
                        </div>                
                    </div>
                </div>
                {action !== '' &&
                <RightSideBar
                serialNumber={propSerialNumber}
                purchaseDate={order && order.created_at}
                itemName={item.item_name}
                clientName={customer && customer.first_name+' '+customer.last_name}
                company={customer && customer.company}
                email={customer && customer.email}
                phone={customer && customer.phone}
                reminder={reminder}
                found={found}>
                </RightSideBar>}
            </React.Fragment>
        );
    }
}

  export default Servicerequest;