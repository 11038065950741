import httpService from './httpService';

// This is an example of movie service, we should create a service like this for each Model defined on loopback API and use is in all components / pages as needed.

export const getClientList = (data) => {
    return httpService.get("/Customers",data)
            .then((response) => {
               return response && response.data;
            });
}

export const addNewClient = (data) => {
    return httpService.post("/Customers",data)
            .then((response) => {
               return response && response.data;
            });
}


export const deleteClient = (id) => {
    return httpService.delete("/Customers/"+id)
            .then((response) => {
               return response && response.data;
            });
}

export const updateClient = (data) => {
   return httpService.patch("/Customers/",data)
           .then((response) => {
              return response && response.data;
           });
}

export const getClientSelect = (data) => {
   return httpService.get("/Customers/customerSelect",data)
           .then((response) => {
              return response && response.data;
           });
}

export const getCompanySelect = (data) => {
   return httpService.get("/Customers/companySelect",data)
           .then((response) => {
              return response && response.data;
           });
}

export const getClientTotal = (data) => {
   return httpService.get("/Customers/count",data)
           .then((response) => {
              return response && response.data;
           });
}

export const getClient = (data) => {
   return httpService.get("/Customers/findOne",data)
           .then((response) => {
              return response && response.data;
           });
}

// more such methods can be defined here, for different endpoints of a model

