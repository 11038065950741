import React from 'react';
import DatePicker from "react-datepicker";
import { getSeries, getItems } from '../../services/itemService';
import { newTensionmeter } from '../../services/customerService';
import ClientList from '../lists/ClientList';
import CompanyList from '../lists/CompanyList';
import SeriesList from '../lists/SeriesList';
import ModelList from '../lists/ModelList';
import "react-datepicker/dist/react-datepicker.css";

class TensionmeterForm extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
            series:[],
            itemList: [],
            serial_number: '',
            item_series: '',
            item_id: '',
            number_of_unit: 0,
            customer_name: '',
            customer_id: null,
            sold_date: '',
            calibration_date: '',
            generate: [],
            submitId:[],
            selectedCompany: null,
            selectedClient: null,
            selectedSeries: null,
            selectedModel: null,
            tensionResponse: [],
            history: props.history,
        }
        this.onChange = this.onChange.bind(this);
        this.handleGenerate = this.handleGenerate.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleHide = this.handleHide.bind(this);
        // this.saveTensionmeter = this.saveTensionmeter.bind(this);
        this.resetResponse = this.resetResponse.bind(this);
    }

    componentDidMount() {
        
        getSeries().then(series => {
            this.setState({
                series: series
            }); 
        });

        getItems({params: {filter : { "order": "item_name asc" }}}).then(items => {
            this.setState({
                itemList: items
            }); 
        });
    }

    handleCompany = selectedCompany => {
        this.setState({ selectedCompany });
    };

    handleSeries = selectedSeries => {
        this.setState({ selectedSeries });
    };
    
    handleModel = selectedModel => {
        this.setState({ selectedModel });
        if(selectedModel !== null)
            this.setState({item_id: selectedModel.value});
	}
    
    handleClient = selectedClient => {
        this.setState({ selectedClient });
        this.setState({customer_id: selectedClient.value});
	};

    handlePuchaseDt = date => {
        this.setState({
            sold_date: date
        });
    };

    handleCalibrationDt = date => {
        this.setState({
            calibration_date: date
        });
    };

    onChange(e) {
        const value = e.target.value;
        this.setState({
            [e.target.name]: value
        });
        
    }

    handleGenerate(event){
        event.preventDefault();
        this.resetResponse();
        var arr = [];
        for (var i = 0; i < this.state.number_of_unit; i++) {
            arr.push(i);    
        }
        this.setState({
            generate: arr,
            submitId:[]
        }); 
        
    }

    
    handleDelete = idx => () => {
        this.setState({
            generate: this.state.generate.filter((s, sidx) => idx !== sidx)
        });
        
    }

    handleform = idx => () => {
        this.state.submitId.push(idx);
        this.setState({
            submitId: this.state.submitId
        }); 
    };

    handleSave(event){
        event.preventDefault();
        const form = event.target;
        const data = new FormData(form);
        let payloadData = {};
        for (let name of data.keys()) {
            const input = form.elements[name];
            if(input.name === 'customer_name'){
                const fullname = input.value.trim().split(" ");
                const first_name = fullname[0];
                const last_name = (fullname[1]) ? fullname[1] : '';
                payloadData["first_name"]= first_name;
                payloadData["last_name"]= last_name;
                
            }
            else
            {
                payloadData["user_id"] = localStorage.getItem('user_id');
                payloadData[input.name]= input.value;
            }
            
        }
        
        const {sold_date, calibration_date, customer_id, item_id} = this.state;
        
        payloadData["sold_date"]= sold_date;
        payloadData["calibration_date"]= calibration_date;
        payloadData["customer_id"]= customer_id;
        payloadData["item_id"] = item_id;
        // this.props.saveTensionmeter(payloadData);
        newTensionmeter(payloadData)
        .then((response) => {
            this.state.tensionResponse.push(response)
            this.setState({
                tensionResponse: this.state.tensionResponse
            }); 
        })
        .catch((error) => {
            var err_str = [];
            console.log('error = ',error);
            if(typeof error.response.data.error.details !== 'undefined'){
                var errors = Object.values(error.response.data.error.details.messages);
                errors.map((err) => 
                    err_str.push(err[0])
                );
            }
            else{
                err_str.push(error.response.data.error.message);
            }
            
            this.setState({ hasError: err_str});
            setTimeout(() => { this.setState({ hasError: ""}); }, 3000);
        });
    }
    
    getCalibrationDate(reminder){
        var CData = {"CDT":'',"calibration_id":0};
        for(var i = 0; i < reminder.length; i++){
            if(reminder[i].reminderType.before_days === 0){ 
                var CDT = new Date(reminder[i].sending_date);
                CData = {"CDT":CDT,"calibration_id":reminder[i].id};
            }    
        }
        return CData;
    }

    resetResponse(){
        this.setState({
            tensionResponse: []
        }); 
    }

    handleHide() {
        this.state.history.push('/');
    }

    render() {
        // const {} = this.props;
        const {tensionResponse, selectedModel, selectedSeries, selectedClient, selectedCompany, serial_number, calibration_date, sold_date, number_of_unit, generate, submitId} = this.state;
        
        var frmResponse = {};
        if(submitId.length > 0){
            for(var i = 0; i<tensionResponse.length;i++){
                let srNo = tensionResponse[i].serial_number;
                frmResponse[srNo] = tensionResponse[i].message;
            }
        }
        
        return (        
            <div className="opti_wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.handleGenerate} encType="multipart/form-data">                                 
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-3">
                                            <label>Starting Serial Number</label>
                                            <input type="text" id="serial_number" name="serial_number" value={serial_number} className="form-control" onChange={this.onChange}/>
                                        </div>
                                        <div className="col-3">
                                            <SeriesList
                                            onChangeFunc={this.handleSeries}
                                            selectedValue={selectedSeries}>
                                            </SeriesList>
                                        </div>
                                        <div className="col-3">
                                            {selectedSeries === null ?
                                            <ModelList
                                            series={selectedSeries}
                                            onChangeFunc={this.handleModel}>
                                            </ModelList>
                                                :
                                            <ModelList
                                            series={selectedSeries.value}
                                            onChangeFunc={this.handleModel}
                                            selectedValue={selectedModel}>
                                            </ModelList>}
                                        </div>
                                        <div className="col-3">
                                            <label>Number of Units to Generate</label>
                                            <input type="number" id="number_of_unit" name="number_of_unit" value={number_of_unit} className="form-control" onChange={this.onChange}/>
                                        </div>
                                        
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            <CompanyList
                                            onChangeFunc={this.handleCompany}
                                            selectedValue={selectedCompany}>
                                            </CompanyList>
                                        </div>
                                        <div className="col-3">
                                            {selectedCompany === null ?
                                            <ClientList
                                            company={selectedCompany}
                                            onChangeFunc={this.handleClient}>
                                            </ClientList>
                                            :
                                            <ClientList
                                            company={selectedCompany.value}
                                            onChangeFunc={this.handleClient}
                                            selectedValue={selectedClient}>
                                            </ClientList>
                                            }
                                        </div>
                                        <div className="col-3">
                                            <label>Sold Date</label><br/>
                                            <DatePicker id="sold_date" className="form-control" dateFormat="yyyy/MM/dd" selected={sold_date} onChange={this.handlePuchaseDt}/>
                                        </div>
                                        <div className="col-3">
                                            <label>Last Calibration Date</label>
                                            <DatePicker id="calibration_date" className="form-control" dateFormat="yyyy/MM/dd" selected={calibration_date} onChange={this.handleCalibrationDt}/>
                                        </div>
                                        
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-12">
                                            <button className="btn-main btn-sm">Generate</button>
                                            <button className="btn-main btn-sm" onClick={this.handleHide}>Cancel</button>
                                        </div>
                                    </div>
                                </div> 
                            </form>  
                        </div>
                    </div>  
                    <div className="row">
                        <div className="col-md-12">
                        {generate.map((gen,idx) => ( 
                            <form onSubmit={this.handleSave} encType="multipart/form-data" key={idx}>                                 
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-3">
                                            <label>Starting Serial Number</label>
                                            <input type="text" id="serial_number" name="serial_number" onChange={this.onChange} value={parseInt(serial_number)+parseInt(gen)} className="form-control"/>
                                        </div>
                                        <div className="col-3">
                                            <SeriesList
                                            onChangeFunc={this.handleSeries}
                                            selectedValue={selectedSeries}>
                                            </SeriesList>
                                        </div>
                                        <div className="col-3">
                                            {selectedSeries === null ?
                                            <ModelList
                                            series={selectedSeries}
                                            onChangeFunc={this.handleModel}>
                                            </ModelList>
                                                :
                                            <ModelList
                                            series={selectedSeries.value}
                                            onChangeFunc={this.handleModel}
                                            selectedValue={selectedModel}>
                                            </ModelList>}
                                        </div>
                                        <div className="col-3">
                                        
                                        </div>
                                        
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            <CompanyList
                                            onChangeFunc={this.handleCompany}
                                            selectedValue={selectedCompany}>
                                            </CompanyList>
                                        </div>
                                        <div className="col-3">
                                            {selectedCompany === null ?
                                            <ClientList
                                            company={selectedCompany}
                                            onChangeFunc={this.handleClient}>
                                            </ClientList>
                                            :
                                            <ClientList
                                            company={selectedCompany.value}
                                            onChangeFunc={this.handleClient}
                                            selectedValue={selectedClient}>
                                            </ClientList>
                                            }
                                        </div>
                                        <div className="col-3">
                                            <label>Sold Date</label><br/>
                                            <DatePicker id="sold_date" className="form-control" dateFormat="yyyy/MM/dd" selected={sold_date} onChange={this.handlePuchaseDt}/>
                                        </div>
                                        <div className="col-3">
                                            <label>Last Calibration Date</label>
                                            <DatePicker id="calibration_date" className="form-control" dateFormat="yyyy/MM/dd" selected={calibration_date} onChange={this.handleCalibrationDt}/>
                                        </div>
                                        
                                    </div>
                                    
                                    {!frmResponse[parseInt(serial_number)+parseInt(gen)] &&    
                                    <div className="row mt-5">
                                        <div className="col-12">
                                            <button className="btn-main btn-sm" onClick={this.handleform(parseInt(serial_number)+parseInt(gen))} type="submit">Confirm</button>
                                            <button type="button" className="btn-main btn-sm" onClick={this.handleDelete(idx)}>Delete</button>
                                        </div>
                                    </div> 
                                    }
                                    {submitId.indexOf((parseInt(serial_number)+parseInt(gen))) >= 0 &&
                                        <div className="row mt-5">
                                            {frmResponse[parseInt(serial_number)+parseInt(gen)] === 'serial number already exist.' &&
                                            <div className="col-12">
                                                <span>{frmResponse[parseInt(serial_number)+parseInt(gen)]}</span>
                                            </div>}
                                            {frmResponse[parseInt(serial_number)+parseInt(gen)] === 'Added Tensionmeter Successfully' &&
                                            <div className="col-12">
                                                <button type="button" className="btn-main btn-sm">Added</button>
                                            </div>}
                                        </div>
                                    }
                                    
                                </div> 
                            </form>))}
                        </div>
                    </div>
                    
                </div>
            </div>  
        );
    }
  }

  export default TensionmeterForm;