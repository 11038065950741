import React from 'react';
import propTypes from 'prop-types';
import StaffForm from './StaffForm';
import ReactLoading from "react-loading";

class StaffList extends React.Component {
    
    // use object destructuring logic to extract variables and methods from props or state
    render(){
        const { 
            users,
            deleteStaff,
            onEdit,
            loader
        } = this.props;

        return(
            <div className="table">
                <div className="row head_row">    
                    <div className="col col-sm-2 pl-5">
                        <img src="./img/img-icon.svg" alt="" height="18"/>
                    </div>                                    
                    <div className="col col-sm-2 pl-5">Name</div>
                    <div className="col col-sm-3">Email</div>
                    <div className="col">Password</div>
                    <div className="col">Role</div>
                    <div className="col col-sm-2 text-center">Action</div>
                </div>
                {loader && <ReactLoading className="m-auto" type="bubbles" color="#ca3c3c" />}
                {users.map((user)=>
                    <div className="show_added_list" key={user.id}>
                        <div className="row" id={'row_data_'+user.id} key={user.id}>
                            <div className="col col-sm-2 pl-5">
                            {typeof user.picture === 'undefined' ?
                                <img width="50px" src={process.env.API_URL+"Containers/staff/download/no-image.jpg"} alt=""/>
                                :
                                <img width="50px" src={process.env.API_URL+"Containers/staff/download/"+user.picture} alt=""/>
                            }
                            </div>
                            <div className="col col-sm-2">{user.first_name} {user.last_name}</div>
                            <div className="col col-sm-3">{user.email}</div>
                            <div className="col">******</div>
                            <div className="col"><span className="text-red">{user.role}</span></div>
                            <div className="col col-sm-2 text-center">
                                <button className="btn-main link-btn" onClick={() => onEdit(user)}><i className="fas fa-edit"></i></button>
                                <button className="btn-main link-btn text-red" onClick={() => window.confirm('Are you sure you want to delete '+user.first_name+' ?') ? deleteStaff(user): ''}><i className="fas fa-trash"></i></button>
                            </div>
                        </div>
                        
                    </div>
                    )}
            </div>
        );
    }
}

StaffForm.propTypes = {
    editId: propTypes.number.isRequired,
	staffData: propTypes.object,
	onSubmit: propTypes.func.isRequired,
	onCancel: propTypes.func.isRequired
}

export default StaffList;