import React from 'react';

class RightSideBar extends React.Component {

    // constructor(props){
    //     super(props);
        
    // }

    componentDidMount() {
        
    }

    render() {
        
        const { purchaseDate, clientName, company, email, phone, reminder, found} = this.props;
        var calibrationDate = '';
        var Status = '';
        var todayDate = new Date();
        reminder.map((rem)=> 
            {rem.reminderType.before_days === 0 && (calibrationDate = new Date(rem.sending_date)); return true;}
        );
        if(todayDate > calibrationDate)
        {
            Status = 'Expired';
        }
        else
        {
            Status = 'Active';
        }
        return(
            <div className="log_right_sidebar">
                <div className="log_edit">
                    <img src="./img/verticle-dots-red.svg" alt="" height="16"/> Tension Meter Serial Match Results
                </div>
                <ul>
                    {found === true &&
                    <li className="row d-flex align-items-center">
                        <div className="log_found">
                            Serial Number found in Log!
                        </div>
                    </li>
                    }
                    {found === false &&
                    <li className="row d-flex align-items-center">
                        <div className="log_not_found">
                            Serial Number not found in Log!
                        </div>
                    </li>
                    }
                    <li className="row">
                        <div className="col text-offtext">Purchase Date</div>
                        <div className="col text-right">{purchaseDate ? new Date(purchaseDate).toDateString() : '-'}</div>
                    </li>
                    <li className="row">
                        <div className="col text-offtext">Warranty Status</div>
                        <div className="col text-right text-red">{Status}</div>
                    </li>
                    <li className="row">
                        <div className="col text-offtext">Client</div>
                        <div className="col text-right">{clientName ? clientName : '-'}</div>
                    </li>
                    <li className="row">
                        <div className="col text-offtext">Company</div>
                        <div className="col text-right">{company ? company : '-'}</div>
                    </li>
                    <li className="row">
                        <div className="col text-offtext">Email</div>
                        <div className="col text-right">{email ? email : '-'}</div>
                    </li>
                    <li className="row">
                        <div className="col text-offtext">Telephone</div>
                        <div className="col text-right">{phone ? phone : '-'}</div>
                    </li>
                    <li className="row">
                        <div className="col text-offtext">Calibration Due:</div>
                        <div className="col text-right">{calibrationDate && new Date(calibrationDate).toDateString()}</div>
                    </li>
                </ul>
            </div>
        )
    }
}    

export default RightSideBar;