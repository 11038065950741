import React from 'react';
import Resizer from 'react-image-file-resizer';

class PartForm extends React.Component {

    constructor(props){
        super(props);
        
        if(props.partData){
            
            const { part_name, picture, sku, cost, description } = props.partData
            const name = part_name;
            this.state = {
                name: name || '',
                image: null || '',
                picture: picture || '',
                sku: sku || '',
                cost: cost || '',
                description: description || '',
                showImg: true,
                imageName: '',
                classes: []
            };
            
        } else {
            this.state = {
                name: '',
                image: null,
                picture: null,
                sku: '',
                cost: '',
                description: '',
                showImg: false,
                imageName: '',
                errors: {},
                classes: []
            };
        }
        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.handleAddPart = this.handleAddPart.bind(this);
    
    }

    onChange(e) {
        if (e.target.id === 'part_name') {
            this.setState({ name: e.target.value });
        } else if (e.target.id === 'file-upload') {
            this.setState({ showImg: false });
            Resizer.imageFileResizer(
                e.target.files[0],
                300,
                300,
                'JPEG',
                100,
                0,
                uri => {
                    this.setState({ image: uri });
                },
                'blob'
            );
            this.setState({ imageName: e.target.files[0].name });
        } else if (e.target.id === 'sku') {
            this.setState({ sku: e.target.value });
        } else if (e.target.id === 'cost') {
            this.setState({ cost: e.target.value});
        } else if (e.target.id === 'description') {
            this.setState({ description: e.target.value});
        }
    }

    componentDidMount() {      
        let {  name, image, cost, sku } = this.state;
        let classes = this.state.classes;
        if(name !== ''){
            classes['name'] = "active";
        }
        if(cost !== ''){
            classes['cost'] = "active";
        }
        if(sku !== ''){
            classes['sku'] = "active";
        } 
        if(image !== ''){
            classes['image'] = "active";
        }             
        
        this.setState({classes: classes});
    }

    onFocus(e) {
        let classes = this.state.classes;
        classes[e.target.id] = "active";
        this.setState({classes: classes});
    }

    onBlur(e){
        let classes = this.state.classes;
        if(e.target.value === ''){
            classes[e.target.id] = "";
        }
        else{
            classes[e.target.id] = "active";
        }
        this.setState({classes: classes});
        
    }

    handleValidation()
    {
        
        let { name, image, cost, sku, imageName} = this.state;
        let errors = {};
        let formIsValid = true;
        
        //Name
        if(!name){
            formIsValid = false;
            errors["name"] = "Cannot be empty";
        }

        //Sku
        if(!sku){
            formIsValid = false;
            errors["sku"] = "Cannot be empty";
        }

        //Cost
        if(!cost){
            formIsValid = false;
            errors["cost"] = "Cannot be empty";
        }
        
        if(cost){
            if(!cost.toString().match(/^\d+(\.\d{1,2})?$/)){
                formIsValid = false;
                errors["cost"] = "Only numbers and two place decimal";
            }
            if(cost < 0){
                formIsValid = false;
                errors["cost"] = "Can't be less than 0";
            }
        }

        //Image
        // if(isNaN(editId)){
        //     if(!image){
        //         formIsValid = false;
        //         errors["image"] = "Cannot be empty";
        //     }
        // }
        if(image){
            var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
            if(!allowedExtensions.exec(imageName)){
                formIsValid = false;
                errors["image"] = "Only .jpeg/.jpg/.png/.gif allowed";
            }
        }
        
        this.setState({errors: errors});
        return formIsValid;
    }

    // this method collects form data and calls parent method with part data
    handleAddPart(event) {
        event.preventDefault();
        const { onSubmit } = this.props;
        const { name, image, sku, cost, description } = this.state;
        const newPartData = {
            name,
            image,
            sku,
            cost,
            description
        };
        if(this.handleValidation()){
            onSubmit(newPartData);
        }
    }

    render(){
        const { onCancel } = this.props;
        return(
            
            <form className='row add_client' onSubmit={this.handleAddPart} encType="multipart/form-data">
                <div className='col col-sm-12 mb-4'>
                    <div className="upload_file_part">
                        <input type="file" onChange={this.onChange} id="file-upload" name="file" accept="image/*"/>
                        {this.state.errors && <span className="error">{this.state.errors["image"]}</span>}
                    </div>
                    {this.state.showImg &&
                    <img width="50px" src={process.env.API_URL+"Containers/parts/download/"+this.state.picture} alt=""/>}
                </div>
                <div className='col col-sm-12'>
                    <div className={"form-group "+ [this.state.classes["part_name"] && this.state.classes["part_name"]]}>
                        <input type="text" onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} className="form-control" id="part_name" value={this.state.name}/>
                        {this.state.errors && <span className="error">{this.state.errors["name"]}</span>}
                        <label for="partname" className="form-control-placeholder">Part Name</label>
                    </div>
                </div>
                <div className='col col-sm-12'>
                    <div className={"form-group "+this.state.classes["sku"]}>
                        <input type="text" onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} className="form-control" id="sku" value={this.state.sku}/>
                        {this.state.errors && <span className="error">{this.state.errors["sku"]}</span>}
                        <label for="partnumber" className="form-control-placeholder">SKU</label>
                    </div>
                </div>
                <div className='col col-sm-12'>
                    <div className={"form-group "+this.state.classes["cost"]}>
                        <input type="text" onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} className="form-control" id="cost" value={this.state.cost}/>
                        {this.state.errors && <span className="error">{this.state.errors["cost"]}</span>}
                        <label for="cost" className="form-control-placeholder">Cost</label>
                    </div>
                </div>
                <div className='col col-sm-12'>
                    <div className={"form-group "+this.state.classes["description"]}>
                        <textarea onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} className="form-control" id="description" value={this.state.description}></textarea>
                        <label for="desc" className="form-control-placeholder">Description</label>
                    </div>
                </div>
                <div className='col col-sm-12'>
                    <button className="btn-main btn-green btn-sm">Save</button><button onClick={onCancel} className="btn-main link-btn">Cancel</button>
                </div>
            </form>
            
        );
    }
}

export default PartForm;