import React from 'react';
import { Form } from 'react-bootstrap';
import { getPartList } from '../../services/partService';
import { getEquipment } from '../../services/equipmentService';
import { getClient } from '../../services/clientService';
import { getCarrierList } from '../../services/shippingCarrier';
import SideBar from './SideBar';

export class Parts extends React.Component {
	constructor(props){
        super(props);
        this.state = {
			equipment_id: null,
			prev_update_type_id: null,
			update_type_id: 4,
			update_id: null,
			parts: props.fields.parts || [{ part_id: "", part_name: "", quantity: 1, unit_cost: 0, sub_total:0 }],
			cost: props.fields.cost || null,
			grand_total: props.fields.grand_total || null,
			details: props.fields.details || '',
			shipping_carrier: props.fields.shipping_carrier || '',
			shipping_speed: props.fields.shipping_speed || '',
			shipping_cost: props.fields.shipping_cost || 0,
			service_covered: props.fields.service_covered || 0,
			shipping_cost_covered: props.fields.shipping_cost_covered || 0,
			pdf: props.fields.pdf || '',
			invoice: {},
			allParts: [],
			QuoteEquip: [],
			carriers: []
		}
		
		this.onChange = this.onChange.bind(this);
		this.removePDF = this.removePDF.bind(this);
	}

	componentDidMount(){
		getPartList({ params:{filter :{order: 'id asc'} } }).then(parts => {
            this.setState({
                allParts: parts
            }); 
		});
		getCarrierList({ params:{filter :{order: 'id asc'} } }).then(carrier => {
            this.setState({
                carriers: carrier
            }); 
        });
		if(this.props.fields.equipIDs.length > 0){
			getEquipment({ params:{filter :{"where": {"id": {"inq":this.props.fields.equipIDs}},"include":"item"} } }).then(eqs => {
				this.setState({
					QuoteEquip: eqs
				}); 
				this.setIDs('QuoteEquip',this.state.QuoteEquip);
			});
		}
		if(this.props.fields.client > 0){
			getClient({ params:{filter :{where: {"id": this.props.fields.client} } } }).then(client => {
				this.setIDs('client_email',client.email);
			});
		}
		this.setIDs('showNext',false);
	}

	removePDF() {
		this.setState({ pdf: '' });
		this.setIDs('pdf','');
	}

	onChange(e) {
        
       	if(e.target.id === 'details'){
			this.setState({details: e.target.value });
			this.setIDs('details',e.target.value);
        }else if(e.target.id === 'shipping_carrier'){
			if(e.target.value === ''){
				this.setIDs('showNext',false);
			}
			else{
				this.setIDs('showNext',true);
			}
            this.setState({shipping_carrier: e.target.value });
			this.setIDs('shipping_carrier',e.target.value);
			var index = e.target.selectedIndex;
			var optionElement = e.target.childNodes[index]
			var speed =  optionElement.getAttribute('speed');
			var scost = optionElement.getAttribute('scost');
			this.setState({shipping_cost: scost });
			this.setIDs('shipping_cost',scost);
			this.setState({shipping_speed: speed });
			this.setIDs('shipping_speed',speed);
		}
        else if(e.target.id === 'shipping_cost'){
			this.setState({shipping_cost: e.target.value });
			this.setIDs('shipping_cost',e.target.value);
        }
        else if(e.target.id === 'tracking_number'){
			this.setState({tracking_number: e.target.value });
			this.setIDs('tracking_number',e.target.value);
        }
        else if(e.target.name === 'service_covered'){
			this.setState({service_covered: e.target.value });
			this.setIDs('service_covered',e.target.value);
        }
        else if(e.target.name === 'shipping_cost_covered'){
			this.setState({shipping_cost_covered: e.target.value });
			this.setIDs('shipping_cost_covered',e.target.value);
        }
        else if (e.target.id === 'file-upload') {
			this.setState({ pdf: e.target.files[0] });
			this.setIDs('pdf',e.target.files[0]);
		}
		if(e.target.name === 'shipping_cost_covered' || e.target.name === 'service_covered'){
			var totcal = this.state.grand_total || null;
			if((parseInt(this.state.shipping_cost_covered) === 0 || this.state.shipping_cost_covered === false) && (parseInt(this.state.service_covered) === 0 || this.state.service_covered === false)){
				totcal= parseFloat(this.state.parts.reduce((totalCalories, part) => totalCalories + part.sub_total, 0))+parseFloat(this.state.shipping_cost);
			}
			else if((parseInt(this.state.shipping_cost_covered) === 0 || this.state.shipping_cost_covered === false) && (parseInt(this.state.service_covered) === 1 || this.state.service_covered === true)){
				totcal= parseFloat(this.state.shipping_cost);
			}
			else if((parseInt(this.state.shipping_cost_covered) === 1 || this.state.shipping_cost_covered === true) && (parseInt(this.state.service_covered) === 0 || this.state.service_covered === false)){
				totcal= parseFloat(this.state.parts.reduce((totalCalories, part) => totalCalories + part.sub_total, 0));
			}
			else if((parseInt(this.state.shipping_cost_covered) === 1 || this.state.shipping_cost_covered === true) && (parseInt(this.state.service_covered) === 1 || this.state.service_covered === true)){
				totcal= 0;
			}
			else
			{ 
				totcal= parseFloat(this.state.parts.reduce((totalCalories, part) => totalCalories + part.sub_total, 0));
			}  
			this.setIDs('grand_total',totcal);
		}
	}
	
	setIDs(label,value){
		setTimeout(() => { 
			const {afterSelect} = this.props;
			afterSelect({label:label,value:value});
		}, 500);
	}

	handlePartChange = idx => evt => {
		const newParts = this.state.parts.map((part, sidx) => {
            if (idx !== sidx) return part;
            if(evt.target.id === 'part_id'){
				this.setState({ cost: 0 });
				var index = evt.target.selectedIndex;
                var optionElement = evt.target.childNodes[index]
				var cost =  optionElement.getAttribute('cost');
				var part_name =  optionElement.getAttribute('name');
				this.setState({ cost: cost });
                return { ...part, part_id: evt.target.value, part_name: part_name, quantity: 1, unit_cost: cost, sub_total: parseFloat(cost) };
            }else if(evt.target.id === 'quantity'){
				var unitcost = this.state.parts[idx].unit_cost;
                return { ...part, quantity: evt.target.value, sub_total: parseFloat(unitcost)*parseFloat(evt.target.value)};
            }
            return part;  
        });
    
        this.setState({ parts: newParts });
        this.setIDs('parts',newParts);
    };

	handleAddPart = () => {
        this.setState({
          parts: this.state.parts.concat([{ part_id: "", part_name: "", quantity: 1, unit_cost: 0, sub_total:0 }])
		});
		this.setIDs('parts',this.state.parts);
	};

	handleRemovePart = idx => () => {
        this.setState({
          parts: this.state.parts.filter((s, sidx) => idx !== sidx)
		});
		this.setIDs('parts',this.state.parts);
    };
	
	render () {
		const {allParts, QuoteEquip, carriers} = this.state;
		var totcal = this.state.grand_total || null;
		if((parseInt(this.state.shipping_cost_covered) === 0 || this.state.shipping_cost_covered === false) && (parseInt(this.state.service_covered) === 0 || this.state.service_covered === false)){
			totcal= parseFloat(this.state.parts.reduce((totalCalories, part) => totalCalories + part.sub_total, 0))+parseFloat(this.state.shipping_cost);
		}
		else if((parseInt(this.state.shipping_cost_covered) === 0 || this.state.shipping_cost_covered === false) && (parseInt(this.state.service_covered) === 1 || this.state.service_covered === true)){
			totcal= parseFloat(this.state.shipping_cost);
		}
		else if((parseInt(this.state.shipping_cost_covered) === 1 || this.state.shipping_cost_covered === true) && (parseInt(this.state.service_covered) === 0 || this.state.service_covered === false)){
			totcal= parseFloat(this.state.parts.reduce((totalCalories, part) => totalCalories + part.sub_total, 0));
		}
		else if((parseInt(this.state.shipping_cost_covered) === 1 || this.state.shipping_cost_covered === true) && (parseInt(this.state.service_covered) === 1 || this.state.service_covered === true)){
			totcal= 0;
		}
		else
		{ 
			totcal= parseFloat(this.state.parts.reduce((totalCalories, part) => totalCalories + part.sub_total, 0));
		}  
		this.setIDs('grand_total',totcal);
    	return (
			<div className="opti_wrapper">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12">
							<div className="add_new_quote">
								<div className="section_title">
									<h2>New Quote</h2>
								</div>
							</div>
						</div>
						<div className="col-md-8">
							<div className="add_new_quote">
								<div className="quote_card">
									<div className="card_title">Parts Information</div>
									<div className="QuoteFrm">
										<button type="button" className="btn-main btn-sm mb-4" onClick={this.handleAddPart}>Add Part <img src="./img/plus-icon-white.svg" alt="" height="10" className="ml-2"/></button>
										{this.state.parts.map((part, idx) => (
											<div className="form-group" key={idx}>
												<div className="row">
													<div className="col-5">
														<label>Parts Required for Service</label>
														<div className="part_search">
															
															<Form.Control as="select" className="dropdown-menu-right" id="part_id" onChange={this.handlePartChange(idx)} value={part.part_id}>
															<option value="hidden">Choose Part</option>
															{allParts.map((ap) => 
																<option key={ap.id} cost={ap.cost} name={ap.part_name} value={ap.id}>{ap.part_name}</option>
															)}
																
															</Form.Control>
														</div>
														
													</div>
													<div className="col-2">
														<label className="text-center w-100">Units</label>
														<input type="number" className="form-control text-center" placeholder="qty" value={part.quantity} id="quantity" min="1" onChange={this.handlePartChange(idx)}/>               
													</div>
													<div className="col-2">
														<label className="text-center w-100">Unit Cost</label>
														<div className="part_relative">
															<img src="./img/dollar-icon.svg" alt="" height="14"/>
															<input type="text" className="form-control text-center" value={parseFloat(part.unit_cost).toFixed(2)} placeholder="cost" id="unit_cost" readOnly/>
														</div>
													</div>
													<div className="col-2">
														<label className="text-center w-100">Total</label>
														<div className="part_relative">
															<img src="./img/dollar-icon.svg" alt="" height="14"/>
															<input type="text" className="form-control text-center" value={parseFloat(part.sub_total).toFixed(2)} placeholder="total" id="sub_total" readOnly/>
														</div>
													</div> 
													<div className="col-1 mt-4 pt-3">
														<label className="text-center"></label>
														<button type="button" className="part_remove" onClick={this.handleRemovePart(idx)}><i className="fas fa-trash"></i></button>
													</div>        
												</div>
											</div>
										))}
										<div className="form-group">
											<div className="row">												
												<div className="col-6">
													<label>Shipping Carrier</label>
													<select id="shipping_carrier" ref="shipping_carrier" className="form-control mb-3" onChange={this.onChange} value={this.state.shipping_carrier}>
														<option value="">Choose Carrier</option>
														{carriers.map((carrier)=>
															<option key={carrier.id} scost={carrier.cost} speed={carrier.speed} value={carrier.carrier_name}>{carrier.carrier_name} ({carrier.speed})</option>
														)}
													</select>
												</div>
												<div className="col-6">
													<label>Shipping Cost</label>
													<div className="shipping_cost">
														<img src="./img/dollar-icon.svg" alt="" height="14"/>
														<input type="number" min="0" className="form-control text-red" id="shipping_cost" value={this.state.shipping_cost} onChange={this.onChange}/>
													</div>
												</div>
												<div className="col-12 d-flex">
													<label className="my-auto">Is the shipping cost covered by the warranty?</label>
													{this.state.shipping_cost_covered === false || parseInt(this.state.shipping_cost_covered) === 0 ?
														<div className="custome_radio ml-auto">
															<label className="opti_radio">
																<input type="radio" name="shipping_cost_covered" checked="checked" value="0" className="warranty" onChange={this.onChange}/> 
																<span className="checkmark"></span>
																No
															</label>
															<label className="opti_radio"> 
																<input type="radio" name="shipping_cost_covered" value="1" className="warranty" onChange={this.onChange}/> 
																<span className="checkmark"></span>
																Yes
															</label>
														</div>
														:
														<div className="custome_radio ml-auto">
															<label className="opti_radio">
																<input type="radio" name="shipping_cost_covered" value="0" className="warranty" onChange={this.onChange}/> 
																<span className="checkmark"></span>
																No
															</label>
															<label className="opti_radio"> 
																<input type="radio" name="shipping_cost_covered" checked="checked" value="1" className="warranty" onChange={this.onChange}/> 
																<span className="checkmark"></span>
																Yes
															</label>
														</div>}
												</div>
											</div>
										</div>
										<div className="form-group">
											<div className="row">						
												<div className="col-12 d-flex">
													<label className="my-auto">Are the parts required for service covered by the warranty?</label>
													{this.state.service_covered === false || parseInt(this.state.service_covered) === 0 ? 
														<div className="custome_radio ml-auto">
															<label className="opti_radio">
																<input type="radio" name="service_covered" checked="checked" value="0" className="warranty" onChange={this.onChange}/> 
																<span className="checkmark"></span>
																No
															</label>
															<label className="opti_radio"> 
																<input type="radio" name="service_covered" value="1" className="warranty" onChange={this.onChange}/> 
																<span className="checkmark"></span>
																Yes
															</label>
														</div>
														:
														<div className="custome_radio ml-auto">
															<label className="opti_radio">
																<input type="radio" name="service_covered" value="0" className="warranty" onChange={this.onChange}/> 
																<span className="checkmark"></span>
																No
															</label>
															<label className="opti_radio"> 
																<input type="radio" name="service_covered" checked="checked" value="1" className="warranty" onChange={this.onChange}/> 
																<span className="checkmark"></span>
																Yes
															</label>
														</div>}
												</div>
											</div>
										</div>
										
										<div className="form-group">
											<div className="row">
												<div className="col-6 d-flex">                 
													<label className="my-auto">Inspection PDF</label>
													<div className="upload_file">
														<span>Select File <img src="./img/upload-file.svg" alt=""/></span>
														<input type="file" id="file-upload" name="file" onChange={this.onChange}/>
													</div>
													
												</div>
												<div className="col-6 my-auto text-right">
													<span className="text-red">
													{this.state.pdf.name && this.state.pdf.name} 
													{this.state.pdf.name && 
														<a className="ml-3" href onClick={this.removePDF}>
															<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><path d="M277.7,144.6l-5.6,5.6,5.6,5.6-1.4,1.4-5.6-5.6-5.6,5.6-1.4-1.4,5.6-5.6-5.6-5.6,1.4-1.4,5.6,5.6,5.6-5.6Z" transform="translate(-263.7 -143.2)" fill="#910000"/></svg>
														</a>
													}
													</span>
												</div>
											</div>
										</div>
										<div className="form-group">
											<label>Details</label>
											<textarea className="form-control" id="details" onChange={this.onChange} value={this.state.details}></textarea>
										</div>
										<div className="form-group border-0">
											<div className="col-6 offset-6 my-auto text-right">
												<label className="mb-0">Grand Total : <span className="text-red"><img src="./img/dollar-icon.svg" alt="" height="14" className="mr-2 ml-3"/>
												{parseFloat(totcal).toFixed(2)}
												</span></label>
											</div>
										</div>
									</div>
										
									</div>
								</div>
							</div>
						{QuoteEquip.length > 0 &&									
						<SideBar
						QuoteEquip={QuoteEquip}
						partIDS={null}
						parts={null}
						shipping_carrier={null}
						shipping_speed={null}
						shipping_cost={null}
						grand_total={null}>
						</SideBar>}
					</div>  
				</div>
			</div> 
    	)
  	}
}

export default Parts;