import httpService from './httpService';

// This is an example of movie service, we should create a service like this for each Model defined on loopback API and use is in all components / pages as needed.

export const getStaffList = (data) => {
    return httpService.get("/Accounts",data)
            .then((response) => {
               return response && response.data;
            });
}

export const addNewStaff = (data) => {
    return httpService.post("/Accounts",data)
            .then((response) => {
               return response && response.data;
            });
}

export const deleteStaff = (id) => {
    return httpService.delete("/Accounts/"+id)
            .then((response) => {
               return response && response.data;
            });
}

export const updateStaff = (data) => {
   return httpService.patch("/Accounts/",data)
           .then((response) => {
              return response && response.data;
           });
}

export const uploadImage = (data) => {
   return httpService.post("/Containers/staff/upload",data)
           .then((response) => {
              return response && response.data;
           });
}

// more such methods can be defined here, for different endpoints of a model

