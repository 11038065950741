import React from 'react';
import ReactLoading from "react-loading";

class CarrierList extends React.Component {
    
    // use object destructuring logic to extract variables and methods from props or state
    render(){
        const { 
            carriers,
            deleteCarrier,
            onEdit,
            loader
        } = this.props;

        return(
            <div className="table">
                <div className="row head_row">                                        
                    <div className="col col-sm-3 pl-5">Carrier Name</div>
                    <div className="col col-sm-3">Speed</div>
                    <div className="col col-sm-2">Default Cost</div>
                    <div className="col col-sm-2 text-center">Action</div>
                </div>
                {loader && <ReactLoading className="m-auto" type="bubbles" color="#ca3c3c" />}
                {carriers.map((carrier)=>
                    <div className="show_added_list" key={carrier.id}>
                        <div className="row" id={'row_data_'+carrier.id} key={carrier.id}>
                            <div className="col col-sm-3 pl-5">{carrier.carrier_name}</div>
                            <div className="col col-sm-3">{carrier.speed}</div>
                            <div className="col col-sm-2"><span className="text-red">$ {parseFloat(carrier.cost).toFixed(2)}</span></div>
                            <div className="col col-sm-2 text-center">
                                <button className="btn-main link-btn" onClick={() => onEdit(carrier)}><i className="fas fa-edit"></i></button>
                                <button className="btn-main link-btn text-red" onClick={() => window.confirm('Are you sure you want to delete '+carrier.carrier_name+' ?') ? deleteCarrier(carrier) : ''}><i className="fas fa-trash"></i></button>
                            </div>
                        </div>
                        
                    </div>
                    )}
            </div>
        );
    }
}

export default CarrierList;