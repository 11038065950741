import React from 'react';

class ReplyForm extends React.Component {
                                  
    constructor(props){
        super(props);
        
        this.state = {
            message: '',
            file: '',
            verrors: {}
        }
        this.onChange = this.onChange.bind(this);
        this.handleAddReply = this.handleAddReply.bind(this);
    }
    
    onChange(e) {
        
        if (e.target.id === 'message') {
            this.setState({ message: e.target.value });
        } 
        else if (e.target.id === 'file-upload') {
            this.setState({ file: e.target.files[0] });
        }
    }

    handleValidation()
    {
        
        let { message } = this.state;
        let verrors = {};
        let formIsValid = true;
        
        //Message
        if(!message){
            formIsValid = false;
            verrors["message"] = "Cannot be empty";
        }

        this.setState({verrors: verrors});
        return formIsValid;
    }    

    handleAddReply(event){
        event.preventDefault();
        const { message, file } = this.state;
        const { editId, onHandleSubmit, requestData } = this.props;
        var payloadData = {
            user_id: localStorage.getItem('user_id'),
            service_request_id: editId,
            message: message,
            file: file
        }
        if(Object.keys(requestData).length >= 1){
            payloadData['to'] = requestData.email;
        }
        if(this.handleValidation()){
            onHandleSubmit(payloadData);
            this.setState({ 
                message: '',
                file: ''
            });
        }    
    }
    
    render(){
        const {replies} = this.props;
        return(
            <React.Fragment>
            <div className="comment_section_wrapper">                
            {replies.map((rp)=>
                <div className="show_reply_msg" key={rp.id}>
                    <p>{rp.message}</p>
                    {rp.file !== null &&
                    <span><a target="_blank" rel="noopener noreferrer" href={process.env.API_URL+"Containers/reply/download/"+rp.file}>{rp.file}</a></span>}
                </div>)}
                <div className="comment_section">
                    <form onSubmit={this.handleAddReply} encType="multipart/form-data">
                        <div className="card-body">
                            <div className="quoted_update">
                                <div className="add_parts">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-12">
                                                <label>Write a Reply</label>
                                                <textarea className="form-control" id="message" name="message" value={this.state.message} onChange={this.onChange}></textarea>
                                                {this.state.verrors && <span className="error">{this.state.verrors["message"]}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group border-0">
                                        <div className="row">
                                            <div className="col-12 d-flex">                 
                                                <label className="my-auto">Inspection PDF</label>
                                                <div className="upload_file">
                                                    <span>Select File <img src="./img/upload-file.svg" alt=""/></span>
                                                    <input type="file" id="file-upload" name="file" onChange={this.onChange}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_button">
                                        <button className="btn-main btn-sm">Respond</button>
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </React.Fragment>
        )
    }    
}

export default ReplyForm;