import React from 'react';
import { getQuotebyTokenAndID, updateQuote } from '../../services/quoteService';
import { addNewInvoice, checkInvoiceExist } from '../../services/invoiceService';
import NewPaypalButton from './NewPaypalButton';
import QuoteDetail from './QuoteDetail';
import SuccessQuote from './SuccessQuote';
import PayShipping from './PayShipping';
import SuccessReturn from './SuccessReturn';
import quoteStatuses from "../quotes/quoteStatuses";
import "../quotes/quotes.scss";

const CLIENT = {
    sandbox: 'ARhJN_ai01-kHqEMmzq52eb27jQQuJnLtEfchQpDjBswrVQxwKDi_A0dgDE2vIV9dEVG5tN7-3dhAU8Z',
    production: 'ASm1gYJbCOkZ1BHKW04phqRtmEVa86XoZwoQSSnRZUWS2aVhrjJV4tpuEe-UeM4hXlIntFVWNmbVzUFb',
  };
  const ENV = process.env.NODE_ENV === 'production'
    ? 'production'
    : 'sandbox';

export class PayQuote extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            quote: null,
            showSuccess: false,
            showError: false,
            invoice: null,
            requestReturn: false,
            isDecommission: false,
            isActive: false
        }
        this.onSuccess = this.onSuccess.bind(this);
        this.requestReturn = this.requestReturn.bind(this);
        this.requestReturnFalse = this.requestReturnFalse.bind(this);
        this.isDecommission = this.isDecommission.bind(this);
        this.isDecommissionFalse = this.isDecommissionFalse.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
    }

    componentDidMount(){
        const {quote_id, token} = this.props.match.params;
        getQuotebyTokenAndID(quote_id,token).then(quote=> {
            this.setState({
                quote: quote
            }); 
        });
        checkInvoiceExist(quote_id).then(invoice =>{
            if(invoice.id > 0){
                this.setState({
                    showSuccess: true,
                    invoice:invoice
                }); 
            }
        })
    }

    requestReturn(){
        this.setState({
            requestReturn: true
        }); 
    }

    requestReturnFalse(){
        this.setState({
            requestReturn: false
        }); 
    }

    isDecommission(){
        this.setState({
            isDecommission: true
        }); 
    }

    isDecommissionFalse(){
        this.setState({
            isDecommission: false
        }); 
    }

    onSuccess(payment){
        const {quote} = this.state;
        let quoteData = {
            "id": quote.id,
            "status": quoteStatuses.Paid
        }
        updateQuote(quoteData).then((quote)=> {
            if(quote.id > 0){

            }
        });

        const InvoiceData = {
            "quote_id": quote.id,
            "customer_id": quote.quoteEquipments.length > 0 ? quote.quoteEquipments[0].equipment.customer.id : 0,
            "total": quote.total.toFixed(2),
            "status": "0",
            "payment_date": new Date(),
            "token": payment.paymentToken
        };
        addNewInvoice(InvoiceData)
        .then((inv)=>{
            if(inv.id > 0){
                this.setState({
                    showSuccess: true,
                    invoice: inv
                }); 
            }
        });
        
    }

    handleCheck(event){
        const isActive = event.target.checked;
        this.setState({ isActive: isActive });
    }

    onError(error){
        
    }

    onCancel(data){
        
    }

    render() {
        const { quote, showSuccess, invoice, requestReturn, isDecommission, isActive } = this.state;
        const validUntil = quote !== null ? new Date(quote.valid_until) : '';
        
        return(
            <React.Fragment>
            {quote !== null ? 
                [showSuccess ? 
                    [quote.status === quoteStatuses.Shipback || quote.status === quoteStatuses.Decommissioned ?
                        <SuccessReturn
                        quote={quote}
                        invoice={invoice}>
                        </SuccessReturn>
                        :
                    <SuccessQuote
                    quote={quote}
                    invoice={invoice}>
                    </SuccessQuote>]
                :
                [requestReturn || isDecommission ?
                    <PayShipping
                    quote={quote}
                    invoice={invoice}
                    client={CLIENT}
                    env={ENV}
                    requestReturnFalse={this.requestReturnFalse}
                    isDecommission={isDecommission}
                    isDecommissionFalse={this.isDecommissionFalse}>
                    </PayShipping>
                :
                <React.Fragment>
                <div className="opti_wrapper payment_pages">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8">
                                <h1 className="page_title">Quote #{quote.quote_number} <span>Valid until {validUntil.toLocaleDateString('default', { month: 'long' })} {validUntil.getDate()}, {validUntil.getFullYear()}</span></h1>
                                <p>If you have any questions about the quote bellow, please call us at (787) 889-2285 or write Lee.Maldonado@optimanufacturing.com</p>
                                <QuoteDetail
                                quote={quote}>
                                </QuoteDetail>
                            </div>
                            <NewPaypalButton
                            total={quote.total.toFixed(2)}
                            onError={this.onError}
                            onSuccess={this.onSuccess}
                            onCancel={this.onCancel}
                            handleCheck={this.handleCheck}
                            shipBack={false}
                            sendMeBack={true}
                            custom={quote.id}
                            isActive={isActive}
                            isShipping={false}
                            isDecommission={false}
                            label='Grand Total'>
                            </NewPaypalButton>
                            
                        </div>                      

                    </div>
                </div>
                <div className="opti_footer_wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="small_heading">Other Options:</div>
                            </div>                            
                        </div>
                        <div className="row status_divider">
                            <div className="col-md-8">
                                <h4>Request Return</h4>
                                <p>If you choose not to complete the servicing as quote above, you may request a return of the unit. As per our Terms and Conditions, any return costs not covered by warranty must be paid by the customer. </p>
                            </div>
                            <div className="col-md-4 pl-5">
                                <div className="return_cost">
                                Return Shipping Cost: <span>${quote.shipping_cost_covered ? '00.00' : quote.shipping_cost.toFixed(2)}</span>
                                </div> 
                                <button onClick={this.requestReturn} className="btn-main no-radius">Request Return</button>
                            </div>
                        </div>
                        <div className="row status_divider">
                            <div className="col-md-8">
                                <h4>Decommission</h4>
                                <p>If you do not wish to pay for the servicing cost and are not interested in the return of the unit, you may also request that we decommission or dispose of the unit for your convenience. </p>
                            </div>
                            <div className="col-md-4 pl-5">
                                <div className="return_cost">
                                Cost: <span>$0.00</span>
                                </div> 
                                <button onClick={this.isDecommission} className="btn-main no-radius">Decommission Unit(s)</button>
                            </div>
                        </div>
                    </div>
                </div>
                </React.Fragment>]
                ]

            : <h1>Invalid Link</h1>}
            </React.Fragment>
        );
    }
}

export default PayQuote;