import React from 'react';
import { NavLink } from "react-router-dom";
import { getUser } from '../../services/accountService';
import { userLogout } from '../../services/accountService';

const Logout = () => {
    userLogout('')
    .then((response) => {
        localStorage.setItem('token', '');
        localStorage.setItem('user_id', '');
        localStorage.getItem('user_name', '');
        window.location.href = '/'; 
    });
}

getUser({ params:{filter :{ where: {id: localStorage.getItem('user_id') }} } })
.then((user) => {
    localStorage.setItem('user_name', user.first_name+" "+user.last_name);
    
});  
class Header extends React.Component {  

    constructor(props){
        super(props);

        this.state = {
            open: false,
            class: ""
        }
        
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(){
        if(this.state.open) {
            this.setState({
              open: false,
              class: ""
            });
        }else{
            this.setState({
              open: true,
              class: "show"
            });
        }
    }

    render(){
        const { open } = this.state;
        var style = { "width": "36%" };
        return (
            <header>
                <div className="header">
                    <div className="container-fluid h-100">
                        <div className="row h-100 align-items-center">
                            <div className="col-md-3">
                                <a href="/" className="logo">
                                    <img src="./img/logo.svg" alt="" height="38px;" style={style}/>
                                </a>
                            </div> 
                            <div className="col-md-5">
                                {/*
                                <div className="search_top">
                                    <img src="./img/search-icon.svg" alt="" height="16"/>
                                    <input type="text" className="form-control" placeholder="Search"/>
                                </div>
                                */}
                            </div>
                            <div className="col-md-4 d-flex">
                                <div className="top-right_sec d-flex ml-auto">
                                    <div className="top_rols">
                                    <NavLink className="nav-link" to="/faq"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path d="M8,0a8,8,0,1,0,8,8A8.024,8.024,0,0,0,8,0ZM8,10a2,2,0,1,1,2-2A2.006,2.006,0,0,1,8,10ZM8,2a5.834,5.834,0,0,1,2.6.6L9.044,4.156a3.591,3.591,0,0,0-2.089,0L5.4,2.6A5.834,5.834,0,0,1,8,2ZM2,8a5.834,5.834,0,0,1,.6-2.6L4.156,6.956a3.591,3.591,0,0,0,0,2.089L2.6,10.6A5.834,5.834,0,0,1,2,8Zm6,6a5.834,5.834,0,0,1-2.6-.6l1.556-1.556a3.591,3.591,0,0,0,2.089,0L10.6,13.4A5.834,5.834,0,0,1,8,14Zm5.4-3.4L11.844,9.044a3.591,3.591,0,0,0,0-2.089L13.4,5.4a5.933,5.933,0,0,1,0,5.2Z" fill="#a7a7a7"/></svg></NavLink>
                                        
                                    {/* <a href="/"><img src="./img/support-icon.svg" alt="" height="16"/></a>
                                        <a href="/"><img src="./img/bell-icon.svg" alt="" height="16"/></a>
                                        */}
                                    </div>
                                    <div className="user_profile_top">
                                        <div className="dropdown">
                                            <div className="dropdown-toggle" onClick={this.handleClick} id="profile_dd" data-toggle="dropdown">{localStorage.getItem('user_name')}</div>
                                            {open && 
                                            <div className="dropdown-menu dropdown-menu-right show" aria-labelledby="profile_dd">
                                                <a className="dropdown-item" onClick={Logout} href>Logout</a>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;