import React from 'react';

class ErrorsList extends React.Component {
    render() {
        const { 
            errors
        } = this.props;
        return (
            <React.Fragment>
            {errors.length > 0 &&
            <div className="alert alert-danger alert-dismissible fade show">
                <ul>
                {errors.map((error)=>
                    <li>{error}</li>)}
                </ul>
                <button type="button" className="close" data-dismiss="alert">&times;</button>
            </div>}
            </React.Fragment>
        );
    }
}

export default ErrorsList;