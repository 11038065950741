import httpService from './httpService';

// This is an example of movie service, we should create a service like this for each Model defined on loopback API and use is in all components / pages as needed.
export const newOrder = (data) => {
   return httpService.post("/Orders/",data)
           .then((response) => {
              return response && response.data;
           });
}

export const getOrders = (ids) => {
   
   var orderParam= { 
      params: {
                  filter : {
                              "where": {"id": {"inq":[ids]} },
                              "order": "created_at desc",
                              "include": [
                                 {
                                    "relation": "customer"
                                 },
                                 {
                                    "relation": "OrderItems",
                                    "scope": {
                                       "include": [
                                       {
                                          "relation": "item",
                                          "scope": {
                                             "include": [{
                                                "relation": "equipment"
                                             }]    
                                          }
                                       }]
                                 }
                              }
                              ]
                        } 
            } 
   }
   return httpService.get("/Orders/",orderParam)
           .then((response) => {
              return response && response.data;
           });
}

export const getOrderById = (id) => {
   var orderParam= { 
      params: {
                  filter : {
                              "where": {"id": id },
                              "order": "id desc",
                              "include": [
                                 {
                                    "relation": "customer"
                                 },
                                 {
                                    "relation": "OrderItems",
                                    "scope": {
                                       "include": [
                                       {
                                          "relation": "item",
                                          "scope": {
                                             "include": [{
                                                "relation": "equipment",
                                                "scope": {
                                                   "where": {"order_id": id} // only select order with id 5
                                                }
                                         
                                             }]    
                                          }
                                       }]
                                 }
                              }
                              ]
                        } 
            } 
   }
   return httpService.get("/Orders/findOne",orderParam)
           .then((response) => {
              return response && response.data;
           });
}

export const getOrderIds = (data) => {
   return httpService.get("/Orders/search",data)
           .then((response) => {
              return response && response.data;
           });
}

export const updateOrder = (data) => {
   return httpService.patch("/Orders/",data)
           .then((response) => {
              return response && response.data;
           });
}

export const deleteOrder = (id) => {
   return httpService.delete("/Orders/"+id)
           .then((response) => {
              return response && response.data;
           });
}

// more such methods can be defined here, for different endpoints of a model