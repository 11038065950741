import React from 'react';
import MultiStep from 'react-multistep';
import Customer from './Customer';
import Parts from './Parts';
import Review from './Review';
import { addNewUpdateTable, uploadPDF, addQuoteParts, addQuoteEquipments, updateQuoteParts } from '../../services/updateService';
import {sendQuote} from '../../services/quoteService';
import ErrorsList from "../../components/common/ErrorsList";
import md5 from 'md5';
import "./quotes.scss";

class NewQuote extends React.Component {
    constructor(props){
        super(props);
        var validDate = new Date();
        var numberOfDaysToAdd = 30;
        validDate.setDate(validDate.getDate() + numberOfDaysToAdd); 
        this.state = {
            company:'',
            client:'',
            client_name:'',
            client_email:'',
            serial_number:'',
            details:'',
            equipIDs:[],
            update_type_id:4,
            user_id: localStorage.getItem('user_id'),
            cost: null,
            grand_total: null,
            shipping_carrier: '',
            shipping_speed: '',
            tracking_number: '',
            shipping_cost: 0,
            service_covered: 0,
            shipping_cost_covered: 0,
            pdf: '',
            invoice: {},
            QuoteEquip:{},
            account_number:'',
            valid_until: validDate,
            house_account: 0,
            quote_number: parseInt(props.quotesTotal)+1,
            hasSuccess: '',
            hasError: '',
            showNext: false,
            token: this.getUniqueToken()
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleValidDt = this.handleValidDt.bind(this);
    }

    handleChange(event) {
        const {name, value} = event.target
        this.setState({
          [name]: value
        })    
        
    }

    handleSelect(data) {
        this.setState({
            [data.label]: data.value
          })  
    }

    handleValidDt(date) {
		
        this.setState({
            valid_until: date
		});
		
    };

    getUniqueToken(){
        var value = JSON.stringify(new Date());
        return md5(value);
    }

    baseUrl(){
        return window.location.protocol + '//' + window.location.host + '/#/';
    }

    preparePayload(Data,response_id) {
        
        const { details, shipping_carrier, shipping_speed, shipping_cost, service_covered, shipping_cost_covered, grand_total, id, quote_number, valid_until, is_house_account, account_number, token } = Data;
        var payloadData = [];
        payloadData = {
            "update_id": response_id,
            "details": details,
            "shipping_carrier": shipping_carrier,
            "shipping_speed": shipping_speed,
            "shipping_cost": parseFloat(shipping_cost).toFixed(2),
            "service_covered": service_covered,
            "shipping_cost_covered": shipping_cost_covered,
            "total": parseFloat(grand_total).toFixed(2),
            "quote_number": quote_number,
            "valid_until": valid_until,
            "is_house_account": is_house_account,
            "account_number": account_number,
            "token": token
        };
    
        if(this.state.quotedPDF !== '') {
            payloadData['pdf'] = this.state.quotedPDF;
        }
        if(id != null && id > 0) {
            payloadData['id'] = id;
        }
        
        return payloadData;
    }

    prepareQuoteParts(Data,quote_id) {
        
        const { parts } = Data;
        var payloadData = [];
        var qPart = {};
        if(typeof parts !== 'undefined'){
            for (var i = 0; i < parts.length; i++) {
                if(parts[i].id){
                    qPart = {
                        "id": parts[i].id,
                        "quote_id": quote_id,
                        "part_id": parts[i].part_id,
                        "quantity": parts[i].quantity,
                        "unit_cost": parseFloat(parts[i].unit_cost).toFixed(2),
                        "sub_total": parseFloat(parts[i].sub_total).toFixed(2),
                    }
                    updateQuoteParts(qPart)
                        .then((qp)=>{
                            
                        });
                }
                else
                {
                    qPart = {
                        "quote_id": quote_id,
                        "part_id": parts[i].part_id,
                        "quantity": parts[i].quantity,
                        "unit_cost": parseFloat(parts[i].unit_cost).toFixed(2),
                        "sub_total": parseFloat(parts[i].sub_total).toFixed(2),
                    }
                    addQuoteParts(qPart)
                        .then((qp)=>{
                            
                        });
                }
                payloadData.push(qPart);
            }
        }
        return payloadData;
    }

    prepareQuoteEquipments(Data,quote_id) {
        
        
        var payloadData = [];
        var qEquip = {};
        for (var i = 0; i < Data.length; i++) {
            qEquip = {
                "quote_id": quote_id,
                "equipment_id": Data[i]
            }
            addQuoteEquipments(qEquip)
                .then((qe)=>{
                    
                });
            payloadData.push(qEquip);
        }
        return payloadData;
    }

    prepareEmail(Data,quoteID) {
        
        const {client_name,client_email,quote_number,details,grand_total,QuoteEquip,parts,shipping_carrier,shipping_cost,service_covered, shipping_cost_covered,valid_until,token, house_account, shipping_speed} = Data;
        var payloadData = [];
        payloadData = {
            "client_name": client_name,
            "client_email": client_email,
            "house_account": house_account,
            "details": details,
            "shipping_carrier": shipping_carrier,
            "shipping_speed": shipping_speed,
            "shipping_cost": parseFloat(shipping_cost).toFixed(2),
            "service_covered": service_covered,
            "shipping_cost_covered": shipping_cost_covered,
            "total": parseFloat(grand_total).toFixed(2),
            "quote_number": quote_number,
            "valid_until": valid_until,
            "QuoteEquip": QuoteEquip,
            "parts": parts,
            "token": token,
            "quote_id": quoteID,
            "url": this.baseUrl()
        };
        if(this.state.quotedPDF !== '') {
            payloadData['pdf'] = this.state.quotedPDF;
        }
    
        return payloadData;
    }

    sendEmail(quoteID){
        const emailData = this.prepareEmail(this.state,quoteID);
        sendQuote(emailData)
        .then((response) => {
            return response;
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        
        const { equipIDs } = this.state;
        var table = '/Quotes';
            
            const { pdf } = this.state;
            if(pdf !== '')
            {
                this.handleUpload(pdf)
                .then(pdfName => {

                    const tableData = this.preparePayload(this.state,0);
                    addNewUpdateTable(table,tableData)
                        .then((res)=>{
                            if(res.id > 0){
                                this.prepareQuoteParts(this.state,res.id);
                                this.prepareQuoteEquipments(equipIDs,res.id);
                                this.sendEmail(res.id);
                                
                                this.setState({ hasSuccess: "Log Updated"});
                                setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                                this.props.handleHide("Quote Created");
                            }
                        })
                        .catch((error) => {
                            this.handleError(error);
                        });
                })
            }
            else
            {
                const tableData = this.preparePayload(this.state,0);
                addNewUpdateTable(table,tableData)
                    .then((res)=>{
                        if(res.id > 0){
                            
                            this.prepareQuoteParts(this.state,res.id);
                            this.prepareQuoteEquipments(equipIDs,res.id);
                            this.sendEmail(res.id);
                            
                            this.setState({ hasSuccess: "Log Updated"});
                            setTimeout(() => { this.setState({ hasSuccess: ""}); }, 5000);
                            this.props.handleHide("Quote Created");
                        }
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            }
    }

    handleUpload(imgData){
    
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('pdf',imgData);
            
            uploadPDF(formData)
            .then((response) => {
                if(response.result.files.pdf[0]){
                    
                    this.setState({
                        quotedPDF: response.result.files.pdf[0].name
                    });
                    return resolve(response.result.files.pdf[0].name);
                }
                else{
                    return reject("PDF Not Uploaded");
                }
            });  
        });
    }

    handleError(error){
        var err_str = [];
        console.log('error = ',error);
        if(typeof error.response.data.error.details !== 'undefined'){
            var errors = Object.values(error.response.data.error.details.messages);
            errors.map((err) => 
                err_str.push(err[0])
            );
        }
        else{
            err_str.push(error.response.data.error.message);
        }
        
        this.setState({ hasError: err_str});
        setTimeout(() => { this.setState({ hasError: ""}); }, 3000);
    }

    render() {
        const steps = [
            {name: 'Customer', component: <Customer fields={this.state} change={this.handleChange} afterSelect={this.handleSelect}/>},
            {name: 'Parts & Shipping', component: <Parts fields={this.state} change={this.handleChange} afterSelect={this.handleSelect}/>},
            {name: 'Review & Send', component: <Review fields={this.state} handleValidDt={this.handleValidDt} change={this.handleChange} afterSelect={this.handleSelect}/>}
          ];
        var css = {"width":"100%"};
        return (
            <React.Fragment>
            {this.state.hasError !== '' &&
                <ErrorsList errors={this.state.hasError}></ErrorsList>}
            {this.state.hasSuccess !== '' &&
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    <strong>Success!</strong> {this.state.hasSuccess}
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>}
            <form onSubmit={this.handleSubmit} encType="multipart/form-data" style={css}>
                <MultiStep showNavigation={true} showNext={this.state.showNext} steps={steps}/>
            </form>
            </React.Fragment>
        );
    }
}

export default NewQuote;