import React from 'react';
import Select from 'react-select';
import { getSeriesSelect } from '../../services/itemService';

export class SeriesList extends React.Component {

    constructor(props){
        super(props);
        this.state = {
			seriesOptions:[]
		}
	}

	componentDidMount() {
        getSeriesSelect().then(series => {
            this.setState({
                seriesOptions: series,
			}); 
			
		});
    }

    render(){
        const {onChangeFunc, selectedValue} = this.props;
        const {seriesOptions} = this.state;

        return (
            <div className="form-group">
                <label>Series</label>
                <Select
                value={selectedValue}
                onChange={onChangeFunc}
                options={seriesOptions}
                defaultValue={selectedValue}
                placeholder={<div>Choose Series</div>}
                isClearable={true}
                />
            </div>
        );
    }

}

export default SeriesList;