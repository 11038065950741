import React, { useState, useEffect} from 'react';
import ReactDOM from 'react-dom';

const NewPaypalButton = props => {
  const [sdkReady, setSdkReady] = useState(false);
  const addPaypalSdk = () => {
    const clientID = process.env.NODE_ENV === 'production'
    ? 'ASm1gYJbCOkZ1BHKW04phqRtmEVa86XoZwoQSSnRZUWS2aVhrjJV4tpuEe-UeM4hXlIntFVWNmbVzUFb'
    : 'ARhJN_ai01-kHqEMmzq52eb27jQQuJnLtEfchQpDjBswrVQxwKDi_A0dgDE2vIV9dEVG5tN7-3dhAU8Z';
    
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://www.paypal.com/sdk/js?client-id=${clientID}`;
    script.async = true;
    script.onload = () => {
      setSdkReady(true);
    };
    script.onerror = () => {
      throw new Error('Paypal SDK could not be loaded.');
    };

    document.body.appendChild(script);
  };

  useEffect(() => {
    if (window !== undefined && window.paypal === undefined) {
      addPaypalSdk();
    } else if (
      window !== undefined &&
      window.paypal !== undefined &&
      props.onButtonReady
    ) {
      props.onButtonReady();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  //amount goes in the value field we will use props of the button for this   
  const createOrder = (data, actions) => {
    return actions.order.create({
        purchase_units: [
        {
          amount: {
            currency_code: 'USD',
            value: props.total,
          }
          ,invoice_number: props.custom
        }
        
      ]
    });
  };

  const onApprove = (data, actions) => {
    return actions.order
      .capture()
      .then(details => {
        if (props.onSuccess) {
          return props.onSuccess(data);
        }
      })
      .catch(err => {
        console.log(err)
      });
  };

  if (!sdkReady && window.paypal === undefined) {
    return (
      <div>Loading...</div>
    );
  }

  const Button = window.paypal.Buttons.driver('react', {
    React,
    ReactDOM
  });

  return (
    <React.Fragment>
    {!props.isDecommission && 
    <div className="col-md-4 pl-5">
        <div className="add_new_quote payment_option">
            <div className="quote_card">
                <div className="show_total">
                    <h3 className="total_label">{props.label}:</h3>
                    <h3 className="total_status ml-auto">$ {props.total}</h3>
                </div>
                {!props.isShipping &&
                <div className="custome_radio ml-auto">
                    <label className="opti_radio opti_checkbox">
                        <input type="checkbox" name="terms" onChange={props.handleCheck}/> 
                        <span className="checkmark"></span>
                        I have read and agree to with Opti Manufacturing’s <a href="https://optimanufacturing.com/terms-and-conditions/">Terms and Conditions</a>
                    </label>                                        
                </div>}

                <div className="pay_paypal">
                    <p>Please pay using PayPal</p>
                    {props.sendMeBack && props.isActive ?
                        <div className="mb-3">
                            <Button
                            {...props}
                            createOrder={
                                props.total && !createOrder
                                ? (data, actions) => createOrder(data, actions)
                                : (data, actions) => createOrder(data, actions)
                            }
                            onApprove={
                                props.onSuccess
                                ? (data, actions) => onApprove(data, actions)
                                : (data, actions) => onApprove(data, actions)
                            }
                            style={{
                                layout: 'vertical',
                                color: 'blue',
                                shape: 'pill',
                                label: 'paypal'
                            }}
                            />
                        </div>
                        :
                        <button className="paypal_btn"><img src="./img/paypal.jpg" alt=""/></button>}
                        <img src="./img/visacard.jpg" alt=""/>
                        
                    </div>
            </div>
        </div>
    </div>
    }
    </React.Fragment>
  );
};

export default NewPaypalButton;
