import httpService from './httpService';

// This is an example of movie service, we should create a service like this for each Model defined on loopback API and use is in all components / pages as needed.

export const getUpdateTypes = (data) => {
    return httpService.get("/UpdateTypes",data)
            .then((response) => {
               return response && response.data;
            });
}


// more such methods can be defined here, for different endpoints of a model

