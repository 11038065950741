import React from 'react';
import ReactLoading from "react-loading";

class OrderList extends React.Component {
    
    render(){
        const { 
            orders,
            onView,
            deleteOrder,
            loader,
            dotBtn,
            curID,
            handleDot,
            showArchived,
            doAction
        } = this.props;
        const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        return(
            <React.Fragment>
                <div className="table col-md-12">
                    <div className="row head_row">
                        <div className="col col-sm-2 pl-5">Order Received</div>
                        <div className="col col-sm-2">Customer</div>
                        <div className="col col-sm-2">Company</div>
                        <div className="col col-sm-2">Amount</div>
                        <div className="col col-sm-2">Order Status</div>
                        <div className="col col-sm-2">Action</div>
                    </div>
                    {loader && <ReactLoading className="m-auto" type="bubbles" color="#ca3c3c" />}
                    {orders.length > 0 ?
                        [orders.map((order)=>    
                            <div className="show_added_list" key={order.id}>
                                <div className="row" id={'row_data_'+order.id} key={order.id}>
                                    <div className="col col-sm-2 pl-5">
                                        {new Date(order.created_at).toLocaleDateString('en-En', dateOptions)}
                                    </div>
                        <div className="col col-sm-2">#{order.woo_order_id} {order.customer && order.customer.first_name+' '+order.customer.last_name}</div>
                                    <div className="col col-sm-2"><span className="text-red">{order.customer && order.customer.company}</span></div>
                                    <div className="col col-sm-2"><span className="text-red">$ {parseFloat(order.total).toFixed(2)}</span></div>
                                    <div className="col col-sm-2 text-red">{order.status}</div>
                                    <div className="col col-sm-2 Status_dd">
                                        <button className="btn-main secondary" onClick={() => onView(order)}>View More</button>
                                        <img src="./img/verticle-dots.svg" onClick={() => handleDot(order.id)} alt="" height="16" className="ml-2 toggle_dot"/>
                                        {dotBtn && curID === order.id &&
                                        <ul> 
                                        <li onClick={() => doAction(order.id)} className="text-grey">{showArchived ? 'Active' : 'Archive'}</li>
                                        <li onClick={() => window.confirm('Are you sure you want to delete #'+order.woo_order_id+' ?') ? deleteOrder(order.id,order.is_archived) : ''} className="text-red">Delete</li>
                                        </ul>}
                                    </div>
                                </div>
                            </div>
                        )]
                        :
                        <div className="show_added_list">
                            <div className="row"><div className="col col-sm-12">No Order Found</div></div>
                        </div>    
                    }
                </div>
                
            </React.Fragment>
        );
    }
}

export default OrderList;