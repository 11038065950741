import React from 'react';
import DatePicker from "react-datepicker";
import { getEquipment } from '../../services/equipmentService';
import SideBar from './SideBar';

export class Review extends React.Component {
	constructor(props){
		super(props);
		
		this.state = {
			QuoteEquip: [],
			partIDS: []
		}
		this.onChange = this.onChange.bind(this);
		
	}

	componentDidMount(){
		
		var parts = this.props.fields.parts;
		if(typeof parts !== 'undefined'){
			parts.map((item) =>
				this.setState(prevState => {
					return {
						partIDS: [item.part_id, ...prevState.partIDS],
					}
				})
			);
		}
		if(this.props.fields.equipIDs.length > 0){
			getEquipment({ params:{filter :{"where": {"id": {"inq":this.props.fields.equipIDs}},"include":"item"} } }).then(eqs => {
				this.setState({
					QuoteEquip: eqs
				}); 
				this.setIDs('QuoteEquip',this.state.QuoteEquip);
			});
		}
	}

	onChange(e) {
        
		if(e.target.name === 'house_account'){
			this.setState({house_account: e.target.value });
		}
	}
	
	setIDs(label,value){
		setTimeout(() => { 
			const {afterSelect} = this.props;
			afterSelect({label:label,value:value});
		}, 500);
	}

	render () {
		const {fields, change, handleValidDt} = this.props;
		const {QuoteEquip, partIDS} = this.state;
		return (
			<div className="opti_wrapper">
				<div className="container-fluid">
					<div className="row">
					<div className="col-md-12">
							<div className="add_new_quote">
								<div className="section_title">
									<h2>New Quote</h2>
								</div>
							</div>
						</div>
						<div className="col-md-8">
							<div className="add_new_quote">									
								<div className="quote_card">
									<div className="card_title">Information</div>
										<div className="review_form">
										<div className="row">
											<div className="col-6">
												<div className="form-group">
													<label>Quote Number</label>
													<input type="text" id="quote_number" name="quote_number" value={fields.quote_number} onChange={change} placeholder="Search Companies" className="form-control" />
												</div>
											</div>
											<div className="col-6">
												<div className="form-group">
													<label>Valid Until</label>
													<DatePicker id="purchase_date" selected={fields.valid_until} dateFormat="yyyy/MM/dd" onChange={handleValidDt} className="form-control"/>
												</div>
											</div>
											<div className="col-6">
												<div className="form-group">
													<label className="my-auto">Will this be billed to a house account ?</label>
													<div className="custome_radio ml-auto">
														<label className="opti_radio">
															<input type="radio" name="house_account" checked="checked" value="0" className="warranty" onChange={change}/> 
															<span className="checkmark"></span>
															No
														</label>
														<label className="opti_radio"> 
															<input type="radio" name="house_account" value="1" className="warranty" onChange={change}/> 
															<span className="checkmark"></span>
															Yes
														</label>
													</div>
												</div>
											</div>
											<div className="col-6">
												<div className="form-group">
													<label>Account Number</label>
													<input type="text" id="account_number" name="account_number" value={fields.account_number} onChange={change} placeholder="Account Number" className="form-control" />
												</div>
											</div>
											
											
											<div className="col-12">
												<button type="submit" className="btn-main btn-sm btn-green">Send Quote</button>
											</div>
											<div className="col-md-12">
												<div className="notice">
												<strong>Note:</strong> Once the quote is sent, a Quote Update will be added to all the selected units.
												</div>
											</div>
										</div>									
									</div> 
								</div>
							</div>							
						</div>
						{QuoteEquip.length > 0 &&									
						<SideBar
						QuoteEquip={QuoteEquip}
						partIDS={partIDS}
						parts={fields.parts}
						shipping_carrier={fields.shipping_carrier}
						shipping_cost={fields.shipping_cost}
						shipping_speed={fields.shipping_speed}
						grand_total={fields.grand_total}>
						</SideBar>}
					</div>  
				</div>
			</div> 
			
    	)
  	}
}

export default Review;