import httpService from './httpService';

// This is an example of movie service, we should create a service like this for each Model defined on loopback API and use is in all components / pages as needed.

export const getPartList = (data) => {
    return httpService.get("/Parts",data)
            .then((response) => {
               return response && response.data;
            });
}

export const addNewPart = (data) => {
    return httpService.post("/Parts",data)
            .then((response) => {
               return response && response.data;
            });
}

export const uploadImage = (data) => {
   return httpService.post("/Containers/parts/upload",data)
           .then((response) => {
              return response && response.data;
           });
}

export const deletePart = (id) => {
    return httpService.delete("/Parts/"+id)
            .then((response) => {
               return response && response.data;
            });
}

export const updatePart = (data) => {
   return httpService.patch("/Parts/",data)
           .then((response) => {
              return response && response.data;
           });
}

// more such methods can be defined here, for different endpoints of a model

