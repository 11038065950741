import React from 'react';
import ReactLoading from "react-loading";

class ClientList extends React.Component {
    
    render(){
        const { 
            clients,
            onEdit,
            deleteClient,
            loader
        } = this.props;
        return(
            <React.Fragment>
            <div className="col-md-12 table">
                <div className="row head_row">
                    <div className="col col-sm-3">Name / Company</div>
                    <div className="col col-sm-3">Email / Phone</div>
                    <div className="col col-sm-4">Address</div>
                    <div className="col col-sm-2">Action</div>
                </div>
                {loader && <ReactLoading className="m-auto" type="bubbles" color="#ca3c3c" />}
                {clients.length > 0 ?
                    [clients.map((client)=>    
                        <div className="show_added_list" key={client.id}>
                            
                            <div className="row" id={'row_data_'+client.id} key={client.id}>
                                <div className="col col-sm-3"><h4>{client.first_name} {client.last_name}</h4><span className="client_company">{client.company}</span></div>
                                <div className="col col-sm-3"><p className="client_email">{client.email}</p><p className="client_email text-red">{client.phone}</p></div>
                                <div className="col col-sm-4"><span className="address">{client.address}, {client.address1 !== '' ? client.address1+',' : ''} {client.city}, {client.state}, {client.country} {client.zip}</span></div>
                                <div className="col col-sm-2">
                                    <button className="btn-main link-btn" onClick={() => onEdit(client)}><i className="fas fa-edit"></i></button>
                                    <button className="btn-main link-btn text-red" onClick={() => window.confirm('Are you sure you want to delete '+client.first_name+' ?') ? deleteClient(client) : ''}><i className="fas fa-trash"></i></button>
                                </div>
                            </div>
                            
                        </div>
                    )]
                :
                    <div className="show_added_list">
                        <div className="row"><div className="col col-sm-12">No client Found</div></div>
                    </div>    
                }
            </div>
            
            </React.Fragment>
        );
    }
}

export default ClientList;