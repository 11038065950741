import React from 'react';

export class SideBar extends React.Component {
    
    render(){
        const {QuoteEquip, parts, shipping_cost, shipping_carrier, shipping_speed, grand_total} = this.props;
        var partTotal = 0;
        return(
            <div className="col-md-4 quote_success_flow">
                <h4 className="titleSmall border_under">Units Quoted</h4>
                <div className="crm_table unit_quoted">
                {QuoteEquip.map((qe) => 
                    <div className="table">
                        <div className="show_added_list" key={qe.id}>
                            <div className="row align-items-center">
                                <div className="product_img col-3">
                                    <img src={qe.item.image} alt=""/>
                                </div>	
                                <div className="product_series col">
                                    <h3>{qe.serial_number}</h3>
                                    <p className="text-red">{qe.item.item_name}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}	
                    <div className="table">
                        {typeof parts !== 'undefined' && parts !== null &&
                        <div className="section_title_small">
                            <h4>Parts</h4>
                            <p>${parts.reduce(function (accumulator, part) {
											return accumulator + part.sub_total
										}, partTotal).toFixed(2)}</p>
                        </div>}
                        {typeof parts !== 'undefined' && parts !== null &&
                        [parts.map((part)=>     
                        <div className="show_added_list h-40">
                            <div className="row align-items-center">													
                                <div className="col-6">
                                {part.part_name}
                                </div>												
                                <div className="col-6 ml-auto text-right">
                                    <span className="part_qty_quote"><b>Q.</b> {part.quantity}</span>
                                    <span className="part_price_quote">${parseFloat(part.unit_cost).toFixed(2)}</span>
                                </div>
                            </div>
                        </div>)]}
                                    
                        {shipping_cost !== null &&    
                        <div className="section_title_small mt-5">
                            <h4>Shipping</h4>
                            <p>${parseFloat(shipping_cost).toFixed(2)}</p>
                        </div>}
                        {shipping_carrier !== null && 
                        <div className="show_added_list">
                            <div className="row align-items-center">													
                                <div className="col-8">
                                {shipping_carrier},
                                {shipping_speed}
                                </div>																							
                                <div className="col-4 ml-auto text-right">													
                                    <span className="part_price_quote">${parseFloat(shipping_cost).toFixed(2)}</span>
                                </div>
                            </div>
                        </div>}
                        {grand_total !== null &&    
                            <div className="section_title_small mt-5">
                                <h4>Grand Total</h4>
                                <p>${parseFloat(grand_total).toFixed(2)}</p>
                            </div>}
                    </div>
                </div>
            </div>
        );
    }
}

export default SideBar;