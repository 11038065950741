import axios from 'axios';

axios.defaults.baseURL = process.env.API_URL;

// common configuration options for all requests
const commonConfig = {
    headers: {
        'Authorization': localStorage.getItem('token'),
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*"
    }
}

// const getAuthToken = () => {
//     // get auth loken from locale storage to pass in with each http request
//     return localStorage.getItem('token');
// }

const makeHttpCall = (httpType, path, params, optionalConfig) => {
    const config = Object.assign(commonConfig, optionalConfig);
    switch(httpType) {
        default :
        case 'GET':
            return axios.get(path, config);
        case 'POST':
            return axios.post(path, params, config);
        case 'PATCH':
            return axios.patch(path, params, config);    
        case 'DELETE':
            return axios.delete(path, config);
        
    }    
}

// make http GET requests    
const get = (path, optionalConfig) => {
    return makeHttpCall('GET', path, {}, optionalConfig);
}

// make http POST requests    
const post = (path, postParams, optionalConfig) => {
    return makeHttpCall('POST', path, postParams, optionalConfig);
}

// make http PATCH requests    
const patch = (path, patchParams, optionalConfig) => {
    return makeHttpCall('PATCH', path, patchParams, optionalConfig);
}

// make http DELETE requests    
const htttpDelete = (path, optionalConfig) => {
    return makeHttpCall('DELETE', path, {}, optionalConfig);
}

const httpService = {
    get,
    post,
    patch,
    delete: htttpDelete
}

export default httpService;