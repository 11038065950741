import httpService from './httpService';

export const userLogin = (data) => {
    return httpService.post("/Accounts/login",data)
            .then((response) => {
               return response && response.data;
            });
}

export const userLogout = (data) => {
   return httpService.post("/Accounts/logout",data)
           .then((response) => {
              return response && response.data;
           });
}

export const getUser = (data) => {
   return httpService.get("/Accounts/findOne",data)
           .then((response) => {
              return response && response.data;
           });
}

export const getUserbyToken = (token) => {

   var quoteParam =    {
      params: {
          filter:{
              "where": {"remember_token": token },
         }
      }    
   };

    return httpService.get("/Accounts",quoteParam)
            .then((response) => {
               return response && response.data;
            });
}

export const passwordReset = (data) => {
   return httpService.post("/Accounts/passwordreset",data)
           .then((response) => {
              return response && response.data;
           });
}

export const forgetPassword = (data) => {
   return httpService.post("/Accounts/forgetpassword",data)
           .then((response) => {
              return response && response.data;
           });
}