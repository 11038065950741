import React from 'react';
import DatePicker from "react-datepicker";
import MaskedInput from 'react-maskedinput';
import { getSeries, getItems } from '../../services/itemService';
import RightSideBar from './RightSideBar';
import ReplyForm from './ReplyForm';
import { addServiceRequest, getServiceRequest, updateServiceRequest, uploadPDF, addServiceRequestReply } from '../../services/servicerequestService';
import { getLogbySerialNo, addNewUpdate, addNewUpdateTable } from '../../services/updateService';
import ErrorsList from "../../components/common/ErrorsList";

import "react-datepicker/dist/react-datepicker.css";

class ServiceRequestForm extends React.Component {

    constructor(props){
        super(props);
        if((props.requestData) && Object.keys(props.requestData).length >= 1){
            this.state = {
                name: props.requestData.name || '',
                company: props.requestData.company || '',
                email: props.requestData.email || '',
                telephone: props.requestData.telephone || '',
                service_requested: props.requestData.service_requested || '',
                details: props.requestData.details || '',
                serial_number: props.requestData.serial_number || '',
                purchase_date: new Date(props.requestData.purchase_date) || '',
                series: props.requestData.series || '',
                model: props.requestData.model  || '',
                is_archived: props.requestData.is_archived || 0,
                errors: {}
            }
            props.getDatabySerialNo(props.requestData.serial_number);
        }
        else
        {
            this.state = {
                name: '',
                company: '',
                email: '',
                telephone: '',
                service_requested: '',
                details: '',
                serial_number: '',
                purchase_date: '',
                series: '',
                model: '',
                is_archived: 0,
                errors: {},
                seriesList: [],
                itemList: [],
                propSerialNumber: '',
                found: '',
                customer:[],
                order:[],
                reminder:[],
                item:[],
                requestData:{},
                replyFile: null,
                replies:[],
                history: props.history,
                hasError: '',
                hasSuccess: '',
                editId: null,
            }
        }    
        this.handleAddServiceRequest = this.handleAddServiceRequest.bind(this);
        this.submitReply = this.submitReply.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount(){
        getSeries().then(series => {
            this.setState({
                seriesList: series
            }); 
        });

        getItems({params: {filter : { "order": "item_name asc" }}}).then(items => {
            this.setState({
                itemList: items
            }); 
        });

        if(this.props.match.params.id){
            getServiceRequest({params: {filter : { "where":{"id":this.props.match.params.id},"order": "id desc", "include": "serviceRequestReplies" }}}).then(data => {
                var reps = [];
                if(data.serviceRequestReplies){
                    reps = data.serviceRequestReplies.reverse();
                }
                this.setState({
                    editId: data.id,
                    requestData: data,
                    replies: reps,
                    name: data.name || '',
                    company: data.company || '',
                    email: data.email || '',
                    telephone: data.telephone || '',
                    service_requested: data.service_requested || '',
                    details: data.details || '',
                    serial_number: data.serial_number || '',
                    purchase_date: new Date(data.purchase_date) || '',
                    series: data.series || '',
                    model: data.model  || '',
                    is_archived: data.is_archived || 0,
                }); 
                this.getDatabySerialNo(data.serial_number);
            });
        }

        
    }

    preparePayload(Data) {
        
        const { user_id, service_request_id, message, to } = Data;
        
        let payloadData = {
            "user_id": user_id,
            "service_request_id": service_request_id,
            "message": message,
            "to": to
        };
        
        if(this.state.replyFile != null) {
            payloadData['file'] = this.state.replyFile;
        }
        
        
        return payloadData;
    }

    submitReply(Data){
        
        const { file } = Data;
        
        if(file !== ''){
            
            this.handleUpload(file)
            .then(fileName => {
                
                const payload = this.preparePayload(Data);
                addServiceRequestReply(payload)
                .then((response) => {
                    if(response.id > 0){
                        this.setState({ hasSuccess: "Reply Added"});
                        setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                    }
                    this.setState(prevState => {
                        return {
                            replies: [response, ...prevState.replies],
                        }
                    });
                    
                })
                .catch((error) => {
                    var err_str = [];
                    var errors = Object.values(error.response.data.error.details.messages);
                    errors.map((err) => 
                        err_str.push(err[0])
                    );
                    this.setState({ hasError: err_str});
                    
                });
            })
        }
        else
        {
            const payload = this.preparePayload(Data);
            addServiceRequestReply(payload)
                .then((response) => {
                    if(response.id > 0){
                        this.setState({ hasSuccess: "Reply Added"});
                        setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
                    }
                    this.setState(prevState => {
                        return {
                            replies: [response, ...prevState.replies],
                        }
                    });
                })
                .catch((error) => {
                    this.handleError(error);                    
                });
        }
    }

    handleUpload(imgData){
    
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file',imgData);
            
            uploadPDF(formData)
            .then((response) => {
                if(response.result.files.file[0]){
                    
                    this.setState({
                        replyFile: response.result.files.file[0].name
                    });
                    return resolve(response.result.files.file[0].name);
                }
                else{
                    return reject("PDF Not Uploaded");
                }
            });  
        });
    }

    getDatabySerialNo(serialNumber){
        getLogbySerialNo(serialNumber).then(list => {
            if(list[0]){
                this.setState({
                    item: list[0].item,
                    equipment_id: list[0].id,
                    customer: list[0].customer,
                    reminder: list[0].reminders,
                    propSerialNumber: serialNumber,
                    order: list[0].order,
                    found: true
                }); 
            }
            else
            {
                this.setState({
                    found: false
                });
            }
        });
    }

    handlePuchaseDt = date => {
        this.setState({
            purchase_date: date
        });
    };

    onChange(e) {
        const value = e.target.value;
        this.setState({
            [e.target.name]: value
        });
        if (e.target.id === 'serial_number') {
            this.getDatabySerialNo(e.target.value);
        }

    }

    handleValidation()
    {
        
        let { name, company, email, telephone, service_requested, details, serial_number, purchase_date, series, model } = this.state;
        let errors = {};
        let formIsValid = true;
        // const { editId } = this.props;
        //Name
        if(!name){
            formIsValid = false;
            errors["name"] = "Cannot be empty";
        }

        //Company
        if(!company){
            formIsValid = false;
            errors["company"] = "Cannot be empty";
        }

        //Email
        if(!email){
            formIsValid = false;
            errors["email"] = "Cannot be empty";
        }
        if(typeof email !== "undefined"){
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');
    
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "Email is not valid";
            }
        }

        //Phone
        if(!telephone){
            formIsValid = false;
            errors["telephone"] = "Cannot be empty";
        }

        //Service Requested
        if(!service_requested){
            formIsValid = false;
            errors["service_requested"] = "Cannot be empty";
        }

        //Details
        if(!details){
            formIsValid = false;
            errors["details"] = "Cannot be empty";
        }

        // Serial Number
        if(!serial_number){
            formIsValid = false;
            errors["serial_number"] = "Cannot be empty";
        }

        // Purchase Date
        if(!purchase_date){
            formIsValid = false;
            errors["purchase_date"] = "Cannot be empty";
        }

        // Series
        if(!series){
            formIsValid = false;
            errors["series"] = "Cannot be empty";
        }

        // Model
        if(!model){
            formIsValid = false;
            errors["model"] = "Cannot be empty";
        }
        
        this.setState({errors: errors});
        return formIsValid;
    }


    handleAddServiceRequest(event){
        event.preventDefault();
        if(this.handleValidation()){
            if(this.props.match.params.id){
                this.onHandleUpdate(this.state);
            }else{
                this.onHandleSubmit(this.state);
            }
        }
    }

    onHandleUpdate(updateData){
        const {name, company, email, telephone, service_requested, details, serial_number, purchase_date, series, model} = updateData;
        const { found, editId } = this.state;
        var status = 'Not Found';
        if(found === true){
            status = 'Match Found';
        }
        
        var serviceRequestData = {
            name: name,
            company: company,
            email: email,
            telephone: telephone,
            service_requested: service_requested,
            details: details,
            serial_number: serial_number,
            purchase_date: purchase_date,
            series: series,
            model: model,
            status: status,
            id: editId
        }
        updateServiceRequest(serviceRequestData)
        .then(sRequest => {
            
            // const sreqList = [...this.state.requests];
            // sreqList.forEach(req => {
            //     if(req.id === this.state.editId){
            //         Object.assign(req, sRequest); // copy new values to user
            //     }
            // });
            
            // this.setState({
            //     requests: sreqList
            // });
            this.setState({
                action: '',
                editId: null,
                cls: ''
            }); 
            this.setState({ hasSuccess: "Service Request Update"});
            setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
            this.state.history.push('/service-requests');
        })
        .catch((error) => {
            this.handleError(error);
        });
        
    }
    
    onHandleSubmit(submitData){

        const {name, company, email, telephone, service_requested, details, serial_number, purchase_date, series, model} = submitData;
        const { found, equipment_id } = this.state;
        var status = 'Not Found';
        if(found === true){
            status = 'Match Found';
        }
        
        const fullname = name.trim().split(" ");
        const first_name = fullname[0];
        const last_name = (fullname[1]) ? fullname[1] : '';
        var serviceRequestData = {
            name: first_name+' '+last_name,
            company: company,
            email: email,
            telephone: telephone,
            service_requested: service_requested,
            details: details,
            serial_number: serial_number,
            purchase_date: purchase_date,
            series: series,
            model: model,
            status:status
        }
        addServiceRequest(serviceRequestData)
        .then(sRequest => {
            if(sRequest.id > 0){
                
                // this.setState(prevState => {
                //     return {
                //         requests: [sRequest, ...prevState.requests],
                //     }
                // });
                this.setState({
                    action: '',
                    editId: null,
                    cls: ''
                }); 
                this.setState({ hasSuccess: "Service Request Added"});
                setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);

                if(found === true){
                    let payloadData = {
                        "equipment_id": equipment_id,
                        "update_type_id": 2,
                        "user_id": localStorage.getItem('user_id')
                    };

                    addNewUpdate(payloadData)
                    .then((response) => {
                        if(response.id > 0){
                            let payloadData = {
                                "update_id": response.id,
                                "staff_note": details,
                                "date": new Date()
                            };
                            let table = '/UnitRequested';
                            addNewUpdateTable(table,payloadData)
                            .then((res)=>{
                                if(res.id > 0){
                                    this.state.history.push('/service-requests');
                                }
                            })
                            .catch((error) => {
                                this.handleError(error);
                            });
                        }
                    })
                    .catch((error) => {
                        this.handleError(error);
                    }); 
                }
                else{
                    this.state.history.push('/service-requests');
                }
            }    
        })
        .catch((error) => {
            this.handleError(error);
        });
        
        
    }

    doArchived(id){
        var serviceRequestData = {
            is_archived: 1,
            id: id
        }
        updateServiceRequest(serviceRequestData)
        .then(sRequest => {
            
            // this.setState(prevState => {
            //     let requests = prevState.requests.filter((post) => {
            //         return id !== post.id;
            //     });
            //     return {
            //         requests: requests,
            //     }
            // });
            
            // this.getArchivedRequest();
            this.setState({
                action: '',
                editId: null,
                cls: ''
            }); 
            this.setState({ hasSuccess: "Service Request Archived"});
            setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
            this.state.history.push('/service-requests');
        })
        .catch((error) => {
            this.handleError(error);
        });
    }

    doUnArchived(id){
        var serviceRequestData = {
            is_archived: 0,
            id: id
        }
        updateServiceRequest(serviceRequestData)
        .then(sRequest => {
            
            // this.setState(prevState => {
            //     let archivedRequests = prevState.archivedRequests.filter((post) => {
            //         return id !== post.id;
            //     });
            //     return {
            //         archivedRequests: archivedRequests,
            //     }
            // });
            // this.getUnArchivedRequest();
            this.setState({
                action: '',
                editId: null,
                cls: ''
            }); 
            this.setState({ hasSuccess: "Service Request Unarchived"});
            setTimeout(() => { this.setState({ hasSuccess: ""}); }, 3000);
            this.state.history.push('/service-requests');
        })
        .catch((error) => {
            this.handleError(error);
        });
    }

    handleError(error){
        var err_str = [];
        console.log('error = ',error.response);
        if(typeof error.response.data.error.details !== 'undefined'){
            var errors = Object.values(error.response.data.error.details.messages);
            errors.map((err) => 
                err_str.push(err[0])
            );
        }
        else{
            err_str.push(error.response.data.error.message);
        }
        
        this.setState({ hasError: err_str});
        setTimeout(() => { this.setState({ hasError: ""}); }, 3000);
    }
    
    render() {
        // const { doArchived, doUnArchived} = this.props;
        const {editId, seriesList, itemList, is_archived, propSerialNumber, found, reminder, customer, order, item, replies, requestData} = this.state;
        return(
            <React.Fragment> 
            <div className={'opti_wrapper log_update_page'}>
                <div className="container-fluid service_request_page">
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    {this.props.match.params.id ?
                                    <li className="breadcrumb-item active" aria-current="page"><button className="text-red">Edit Service Request</button></li>
                                    :
                                    <li className="breadcrumb-item active" aria-current="page"><button className="text-red">Add New Service Request</button></li>}
                                </ol>
                            </nav>
                            {this.state.hasError !== '' &&
                                <ErrorsList errors={this.state.hasError}></ErrorsList>}
                            {this.state.hasSuccess !== '' &&
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    <strong>Success!</strong> {this.state.hasSuccess}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>}
                            <div className="client_info accordion">
                                <h1 className="page_title d-flex mb-4">
                                    Information
                                    <button type="button" className="btn-main ml-auto d-none">Edit</button>
                                </h1>
                                <form onSubmit={this.handleAddServiceRequest} encType="multipart/form-data">
                                    <div className="card">
                                        <div className="card-header mt-3">
                                            <p className="mb-3">User Information</p>
                                        </div>                                    
                                        <div className="card-body">                                        
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <label>Name</label>
                                                        <input type="text" className="form-control" placeholder="John Ralfio" name="name" id="name" value={this.state.name} onChange={this.onChange}/>
                                                        {this.state.errors && <span className="error">{this.state.errors["name"]}</span>}
                                                    </div>
                                                    <div className="col-6">
                                                        <label>Company</label>
                                                        <input type="text" className="form-control" placeholder="Ralfio Air" name="company" id="company" value={this.state.company} onChange={this.onChange}/>
                                                        {this.state.errors && <span className="error">{this.state.errors["company"]}</span>}
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <label>Email</label>
                                                        <input type="email" className="form-control" placeholder="John@ralfioair.com" name="email" id="email" value={this.state.email} onChange={this.onChange}/>
                                                        {this.state.errors && <span className="error">{this.state.errors["email"]}</span>}
                                                    </div>
                                                    <div className="col-6">
                                                        <label>Telephone</label>
                                                        <MaskedInput mask="(111) 111-1111" type="text" onChange={this.onChange} className="form-control" placeholder="(123) 456-7890" name="telephone" id="telephone" value={this.state.telephone}/>
                                                        {this.state.errors && <span className="error">{this.state.errors["telephone"]}</span>}
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <label>Service Requested</label>
                                                        <select className="form-control" name="service_requested" id="service_requested" value={this.state.service_requested} onChange={this.onChange}>
                                                            <option value="">Choose Request</option>
                                                            <option value="Repair">Repair</option>
                                                            <option value="Calibration">Calibration</option>
                                                            <option value="Sales Consultation">Sales Consultation</option>
                                                            <option value="Technical Assistance">Technical Assistance</option>
                                                        </select>
                                                        {this.state.errors && <span className="error">{this.state.errors["service_requested"]}</span>}
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <label>Details</label>
                                                        <textarea className="form-control" name="details" id="details" value={this.state.details} onChange={this.onChange}></textarea>
                                                        {this.state.errors && <span className="error">{this.state.errors["details"]}</span>}
                                                    </div>
                                                </div>
                                            </div>                         
                                        </div>
                                        <div className="card-header mt-3">
                                            <p className="mb-3">Tension meter Information</p>
                                        </div>  
                                        <div className="card-body">                                        
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <label>Serial Number</label>
                                                        <input type="text" className="form-control text-red" placeholder="635445656" name="serial_number" id="serial_number" value={this.state.serial_number} onChange={this.onChange}/>
                                                        {this.state.errors && <span className="error">{this.state.errors["serial_number"]}</span>}
                                                    </div>
                                                    <div className="col-6">
                                                        <label>Purchase Date</label>
                                                        <DatePicker id="purchase_date" selected={this.state.purchase_date} dateFormat="yyyy/MM/dd" onChange={this.handlePuchaseDt}/>
                                                        {this.state.errors && <span className="error">{this.state.errors["purchase_date"]}</span>}
                                                    </div>
                                                </div>
                                            </div>                                          
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <label>Series</label>
                                                        <select className="form-control" name="series" id="series" value={this.state.series} onChange={this.onChange}>
                                                            <option value="">Choose Series</option>
                                                            {seriesList.map((ser,ind)=>
                                                                <option key={ind} value={ser.series}>{ser.series}</option>)}
                                                        </select>
                                                        {this.state.errors && <span className="error">{this.state.errors["series"]}</span>}
                                                    </div>
                                                    <div className="col-6">
                                                        <label>Model</label>
                                                        <select className="form-control" name="model" id="model" value={this.state.model} onChange={this.onChange}>
                                                            <option value="hidden">Choose Model</option>
                                                            {itemList.map((item,ind)=>
                                                                <option key={ind} value={item.item_name}>{item.item_name}</option>)}
                                                        </select>
                                                        {this.state.errors && <span className="error">{this.state.errors["model"]}</span>}
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="form_button">
                                                {editId ? <button type="submit" className="btn-main btn-sm mr-3">Update</button> :
                                                <button type="submit" className="btn-main btn-sm mr-3">Request Unit</button>}
                                                {editId && is_archived === 0 &&
                                                <button type="button" onClick={() => this.doArchived(editId)} className="btn-main btn-sm btn-black ml-auto">Archive</button>}
                                                {editId && is_archived === 1 &&
                                                    <button type="button" onClick={() => this.doUnArchived(editId)} className="btn-main btn-sm btn-black ml-auto">Unarchive</button>}
                                            </div>
                                                                        
                                        </div>
                                    </div> 
                                </form>
                                {this.props.match.params.id &&
                                <ReplyForm
                                onHandleSubmit={this.submitReply}
                                editId={editId}
                                replies={replies}
                                requestData={requestData}>
                                </ReplyForm>}
                            </div>
                        </div>
                    </div>                
                </div>
            </div>
            <RightSideBar
                serialNumber={propSerialNumber}
                purchaseDate={order && order.created_at}
                itemName={item.item_name}
                clientName={customer && customer.first_name+' '+customer.last_name}
                company={customer && customer.company}
                email={customer && customer.email}
                phone={customer && customer.phone}
                reminder={reminder}
                found={found}>
            </RightSideBar>
            </React.Fragment> 
        )
    }
}    

export default ServiceRequestForm;