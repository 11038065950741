import React from 'react';
import DatePicker from "react-datepicker";
import { getSeries } from '../../services/itemService';
import ClientList from '../lists/ClientList';
import CompanyList from '../lists/CompanyList';
import SeriesList from '../lists/SeriesList';
import ModelList from '../lists/ModelList';
import "react-datepicker/dist/react-datepicker.css";

class SidebarForm extends React.Component {
    constructor(props){
        super(props);
        var cali_date = '';
        var cali_id = 0;
        if(props.reminder){
            var cData = this.getCalibrationDate(props.reminder);
            cali_date = cData.CDT;
            cali_id = cData.calibration_id;
        }
        var purDate = ''; 
        
        if(typeof props.purchaseDate !== 'undefined' && props.purchaseDate !== '')
            purDate = new Date(props.purchaseDate);
        
        this.state = {
            series:[],
            serial_number: props.serialNumber || '',
            item_name: props.itemName || '',
            purchase_date: purDate || '',
            customer_name: props.clientName || '',
            company: props.company || '',
            item_series: props.itemSeries || '',
            calibration_date: cali_date,
            calibration_id: cali_id,
            selectedCompany: {label:props.company,value:props.company} || null,
            selectedClient: {label:props.clientName,value:props.client_id} || null,
            selectedSeries: {label:props.itemSeries,value:props.itemSeries} || null,
            selectedModel: {label:props.itemName,value:props.item_id} || null,
            customer_id: props.client_id || null,
            item_id: props.item_id || null
        }
        this.onChange = this.onChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    componentDidMount() {
        getSeries().then(series => {
            this.setState({
                series: series
            }); 
        });
    }

    handleCompany = selectedCompany => {
        this.setState({ selectedCompany });
    };

    handleClient = selectedClient => {
        this.setState({ selectedClient });
        if(selectedClient !== null)
            this.setState({customer_id: selectedClient.value});
    };

    handleSeries = selectedSeries => {
        this.setState({ selectedSeries, selectedModel: null });
    };
    
    handleModel = selectedModel => {
        this.setState({ selectedModel });
        if(selectedModel !== null)
            this.setState({item_id: selectedModel.value});
    };

    handlePuchaseDt = date => {
        this.setState({
            purchase_date: date
        });
    };

    handleCalibrationDt = date => {
        this.setState({
            calibration_date: date
        });
    };

    onChange(e) {
        if (e.target.id === 'serial_number') {
            this.setState({ serial_number: e.target.value });
        }
        else if (e.target.id === 'item_name') {
            this.setState({ item_name: e.target.value });
        }
        else if(e.target.id === 'item_series'){
            this.setState({item_series: e.target.value });
        }
        else if(e.target.id === 'company'){
            this.setState({company: e.target.value });
        }
        else if(e.target.id === 'customer_name'){
            this.setState({customer_name: e.target.value });
        }
        
    }

    handleUpdate(event){
        event.preventDefault();
        const { onUpdate } = this.props;
        const {customer_id, serial_number, item_id, item_series, company, customer_name, calibration_date, purchase_date, calibration_id} = this.state;
        
        var payloadData = [];
        payloadData = {
            "serial_number": serial_number,
            "item_id": item_id,
            "item_series": item_series,
            "company": company,
            "customer_name": customer_name,
            "purchase_date": purchase_date,
            "calibration_date": calibration_date,
            "calibration_id": calibration_id,
            "customer_id": customer_id
        }
        
        onUpdate(payloadData);
    }
    
    getCalibrationDate(reminder){
        var CData = {"CDT":'',"calibration_id":0};
        for(var i = 0; i < reminder.length; i++){
            if(reminder[i].reminderType.before_days === 0){ 
                var CDT = new Date(reminder[i].sending_date);
                CData = {"CDT":CDT,"calibration_id":reminder[i].id};
            }    
        }
        return CData;
    }

    render() {
        const {selectedModel, selectedSeries, selectedClient, selectedCompany, serial_number, calibration_date, purchase_date} = this.state;
        
        return (        
            <div className="tensionmeter_edit_for">                   
                <form onSubmit={this.handleUpdate} encType="multipart/form-data">   
                    <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Serial Number</label>
                            <input type="text" id="serial_number" value={serial_number} className="form-control" onChange={this.onChange}/>
                        </div>
                        <div className="form-group">
                            <SeriesList
                            onChangeFunc={this.handleSeries}
                            selectedValue={selectedSeries}>
                            </SeriesList>
                        </div>
                        <div className="form-group">
                            {selectedSeries === null ?
                                <ModelList
                                series={selectedSeries}
                                onChangeFunc={this.handleModel}>
                                </ModelList>
                                    :
                                <ModelList
                                series={selectedSeries.value}
                                onChangeFunc={this.handleModel}
                                selectedValue={selectedModel}>
                                </ModelList>}
                        </div>
                        <div className="form-group">
                            <label>Purchase Date</label>
                            <DatePicker id="purchase_date" dateFormat="yyyy/MM/dd" selected={purchase_date} onChange={this.handlePuchaseDt} className="form-control"/>
                        </div>
                        <div className="form-group">
                            <label>Calibration Due</label>
                            <DatePicker id="calibration_date" dateFormat="yyyy/MM/dd" selected={calibration_date} onChange={this.handleCalibrationDt} className="form-control"/>
                        </div>
                        <CompanyList
                        onChangeFunc={this.handleCompany}
                        selectedValue={selectedCompany}>
                        </CompanyList>
                        {selectedCompany === null ?
                        <ClientList
                        company={selectedCompany}
                        onChangeFunc={this.handleClient}>
                        </ClientList>
                        :
                        <ClientList
                        company={selectedCompany.value}
                        onChangeFunc={this.handleClient}
                        selectedValue={selectedClient}>
                        </ClientList>
                        }
                        <div className="form-group mt-5">
                            <button className="btn-main btn-sm">Save</button>
                        </div>
                    </div>  
                    </div>                      
                </form>
            </div>    
        );
    }
  }

  export default SidebarForm;